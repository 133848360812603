import React, { useState } from 'react';
import { messageNotification } from 'helpers';
import { Row, Col, Button } from 'react-bootstrap';
import { IconTrash } from '@tabler/icons-react';

export const FileUploader = (props) => {
  const [file, setFile] = useState(null);
  const [inputKey, setInputKey] = useState(0);

  function handleUpload(event) {
    const uploadedFile = event.target.files[0];

    if (uploadedFile && uploadedFile.type === 'image/png') {
      const fileUrl = URL.createObjectURL(event.target.files[0]);
      setFile(fileUrl);
      props.onUpdate(event.target.files[0]);
    } else {
      messageNotification('danger', 'Error', 'Please upload a PNG file.');
    }
  }

  function handleDelete() {
    setFile(null);
    setInputKey(inputKey + 1);
    props.onUpdate(null);
  }

  return (
    <div>
      <Row>
        <Col className="mb-3 mb-lg-0">
          <div className="upload-assets">
            <div className="upload-preview">
              <div className="upload-box">{file ? <img src={file} alt="File Preview" /> : 'File Preview'}</div>
              {file && (
                <div className="file-delete-hold">
                  <span onClick={handleDelete}>
                    <IconTrash stroke={1.5} />
                  </span>
                </div>
              )}
            </div>
            <div className="upload-helptext">
              <div className="custom-file-field">
                <h6>{props.uploadFilename}</h6>
                <small className="d-block">{props.helpText}</small>
                <input
                  key={inputKey}
                  id={`fileInput-${props.uploadFilename}`}
                  type="file"
                  value={null}
                  onChange={handleUpload}
                />
                <label htmlFor={`fileInput-${props.uploadFilename}`}>Browse</label>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};
