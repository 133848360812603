import BadgrIntegration from 'pages/integrations/containers/BadgrContainer/badgrIndex';
import DiscussionIntegration from 'pages/integrations/containers/DiscussionContainer/discussionIndex';
import LtiConfiguration from 'pages/integrations/containers/LtiIntegrationContainer/ltiConfigurationIndex';
import React, { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import 'pages/integrations/index.scss';

const Integrations = () => {
  return (
    <div className="block form-container">
      <h2 className="mb-4">Integrations</h2>
      <div className="tabs" id="accountSettings">
        <Tabs defaultActiveKey="badgr" id="integration" className="mb-4">
          <Tab eventKey="badgr" title="BADGR">
            <DiscussionIntegration />
            <BadgrIntegration />
          </Tab>
          <Tab eventKey="lti_integration" title="LTI INTEGRATION">
            <LtiConfiguration />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default Integrations;
