import React from 'react';
import { connect } from 'react-redux';
import mixpanel from 'mixpanel-browser';
import { NavLink } from 'react-router-dom';

import { getOrganization } from 'selectors';

const NavItem = (props) => {
  const {
    className,
    fontIcon,
    insightsOpen,
    label,
    link,
    setInsightsOpen,
    settingsOpen,
    setSettingsOpen,
    setSideBarOpen
  } = props;

  const handleClick = () => {
    mixpanel.track(label, {
      service: 'edly_panel',
      screen_name: 'panel_navigation',
      organization: props.organization.name
    });

    setInsightsOpen(insightsOpen);
    setSettingsOpen(settingsOpen);

    if (window.innerWidth < 992 && setSideBarOpen) {
      setSideBarOpen(true);
    }
  };

  return (
    <li>
      <NavLink to={link} className={className ? className : ''} activeClassName="active" onClick={handleClick}>
        {fontIcon}
        <span>{label}</span>
      </NavLink>
    </li>
  );
};

function mapStateToProps(state) {
  const organization = getOrganization(state);
  return { organization };
}

export default connect(mapStateToProps)(NavItem);
