import SiteDetailsForm from './siteDetailsForm';
import SiteDetailsValues from './siteDetailsValues';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { PANEL_BASE_URL } from 'config';
import Cookies from 'js-cookie';
import { Row, Col, Button, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IconInfoCircle, IconInfoTriangle } from '@tabler/icons-react';

const CustomSiteDetails = ({ siteUrl, email, showSave, loading, isTrialUser, showError, dnsUpdated, redirect }) => {
  const [showSiteValues, setShowSiteValues] = useState(false);
  const error = Cookies.get('show_dns_error') || showError;
  useEffect(() => {
    if (!isTrialUser && siteUrl && email && dnsUpdated) {
      setShowSiteValues(true);
    } else {
      setShowSiteValues(false);
    }
  }, [isTrialUser, siteUrl, email, loading, showSave]);

  useEffect(() => {
    if (redirect) {
      window.location.replace(PANEL_BASE_URL);
    }
  }, [redirect]);

  const edit = () => {
    setShowSiteValues(false);
  };

  const setSiteValues = () => {
    if (dnsUpdated) {
      setShowSiteValues(true);
    }
  };

  const renderTooltip = (props) => (
    <Tooltip id="site-name" {...props}>
      This feature is not availabe right now.
    </Tooltip>
  );

  return (
    <div className="profile-form">
      <div className="integration-holder">
        <h2 className="mb-4">Site Details </h2>
        <div className="subheader-holder mb-4">
          <h4>Site name and address </h4>
          <p>
            Site name is used in the email that are sent to the users, meta tags, login and registration pages. Your
            site address is used to access your learning content.
          </p>
        </div>
        <Row>
          <Col xs="12">
            <Form.Group className="mb-3">
              <Form.Label>
                Site name
                <OverlayTrigger placement="right" overlay={renderTooltip}>
                  <span id="site-name" className="ms-2">
                    <IconInfoCircle stroke={1.5} />
                  </span>
                </OverlayTrigger>
              </Form.Label>
              <Form.Control type="text" placeholder={'ab courses'} disabled={true} />
            </Form.Group>
          </Col>

          <Col xs="12" className="d-flex justify-content-end">
            <Button disabled={true}>Save</Button>
          </Col>
        </Row>
        <div className="integration-holder">
          <div className="subheader-holder mb-4">
            <h4>Custom site URL </h4>
            <p>This is the address to access your Edly site.</p>
            <div className="site-btns-holder mb-4">
              <Button variant="link" className="p-0 mb-2 me-4 site-detail-btn d-none">
                Check how to do DNS setup
              </Button>
              <Button
                variant="link"
                className="p-0 mb-2 site-detail-btn"
                onClick={() => window.HubSpotConversations.widget.open()}
              >
                Contact us if you need assistance
              </Button>
            </div>
            <p className="footer-note">
              <IconInfoTriangle stroke={1.5} />
              <span>Please do not use edx, open edx words in your domain names to avoid copyright issues</span>
            </p>
          </div>
          {error && (
            <p className="footer-note">
              <IconInfoTriangle stroke={1.5} />
              <span>
                There is an issue with your DNS entries, please recheck them with your DNS provider and try again after
                30 minutes.
              </span>
            </p>
          )}

          {!showSiteValues && <SiteDetailsForm showSiteValues={setSiteValues} />}
          {showSiteValues && <SiteDetailsValues siteUrl={siteUrl} email={email} edit={edit} />}
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { siteUrl, email, showSave, loading, showError, dnsUpdated, redirect } = state.SITE_DETAILS;
  const { isTrialUser } = state.FREE_TRIAL;
  return {
    siteUrl,
    email,
    showSave,
    loading,
    isTrialUser,
    showError,
    dnsUpdated,
    redirect
  };
}
export default connect(mapStateToProps, null)(CustomSiteDetails);
