import React from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';

export const BusinessDetails = (props) => {
  return (
    <Modal onHide={props.onHide} show={props.show} size={props.size}>
      <Modal.Header closeButton>
        <Modal.Title>Organization Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-3">
          <Form.Label>
            Organization name<span className="required">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            id="organizationName"
            name="business_name"
            onChange={props.handleInputChange}
            value={props.inputs.business_name}
            required
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>
            Address<span className="required">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            id="address"
            name="address"
            onChange={props.handleInputChange}
            value={props.inputs.address}
            required
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Apartment, suite etc.</Form.Label>
          <Form.Control
            type="text"
            id="apartment"
            name="apartment"
            className="form-control mb-0"
            onChange={props.handleInputChange}
            value={props.inputs.apartment}
          />
        </Form.Group>
        <Row>
          <Col xs="12" lg="6">
            <Form.Group className="mb-3">
              <Form.Label>
                City<span className="required">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                id="city"
                name="city"
                className="form-control mb-0"
                onChange={props.handleInputChange}
                value={props.inputs.city}
                required
              />
            </Form.Group>
          </Col>
          <Col xs="12" lg="6">
            <Form.Group className="mb-3">
              <Form.Label>
                Country<span className="required">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                id="country"
                name="country"
                className="form-control mb-0"
                onChange={props.handleInputChange}
                value={props.inputs.country}
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs="12" lg="6">
            <Form.Group className="mb-3">
              <Form.Label>
                State<span className="required">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                id="state"
                name="state"
                className="form-control mb-0"
                onChange={props.handleInputChange}
                value={props.inputs.state}
                required
              />
            </Form.Group>
          </Col>
          <Col xs="12" lg="6">
            <Form.Group className="mb-3">
              <Form.Label>
                Zip<span className="required">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                id="zip"
                name="zip"
                className="form-control mb-0"
                onChange={props.handleInputChange}
                value={props.inputs.zip}
                required
              />
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" disabled={props.buttonDisabled} onClick={props.handleSubmit}>
          Continue
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default BusinessDetails;
