import React, { useState } from 'react';
import { Slider } from 'rsuite';
import 'rsuite/Slider/styles/index.css';

import { Card, CardBody } from 'react-bootstrap';

const CustomTooltip = ({ value }) => {
  return (
    <div>
      {value}&nbsp;
      <small className="d-block" style={{ fontSize: '10px', whiteSpace: 'nowrap' }}>
        Monthly Active Learners
      </small>
    </div>
  );
};

const PriceSlider = (props) => {
  const [value, setValue] = useState(0);
  const [price, setPrice] = useState(300);

  const scaleValue = (value) => {
    if (value <= 15) {
      return Math.round(value * (20 / 15));
    } else if (value <= 30) {
      return Math.round((value - 15) * (80 / 15) + 20);
    } else if (value <= 60) {
      return Math.round((value - 30) * (1400 / 30) + 100);
    } else {
      return Math.round((value - 60) * (10500 / 40) + 1500);
    }
  };

  const inverseScaleValue = (scaledValue) => {
    if (scaledValue <= 20) {
      return Math.round(scaledValue * (15 / 20));
    } else if (scaledValue <= 100) {
      return Math.round((scaledValue - 20) * (15 / 80) + 15);
    } else if (scaledValue <= 1500) {
      return Math.round((scaledValue - 100) * (30 / 1400) + 30);
    } else {
      return Math.round((scaledValue - 1500) * (40 / 10500) + 60);
    }
  };

  const getPrice = (scaledValue) => {
    let price = 0;
    if (scaledValue >= 0 && scaledValue <= 100) {
      price = 300;
    } else if (scaledValue > 100 && scaledValue <= 250) {
      price = 500;
      setPrice(500);
    } else if (scaledValue > 250 && scaledValue <= 500) {
      price = 750;
      setPrice(750);
    } else if (scaledValue > 500 && scaledValue <= 1000) {
      price = 1125;
    } else if (scaledValue > 1000 && scaledValue <= 2500) {
      price = 1850;
    } else if (scaledValue > 2500 && scaledValue <= 5000) {
      price = 2250;
    } else if (scaledValue > 5000 && scaledValue <= 10000) {
      price = 3500;
    }
    return price;
  };

  const handleChange = (value) => {
    const scaledValue = scaleValue(value);
    setValue(scaledValue);
    setPrice(getPrice(scaledValue));
  };

  const handleCustomTip = (value) => {
    return value >= 10000 ? '+10000' : value;
  };

  return (
    <Card className="price-box">
      <CardBody>
        <h4>Calculate Your Cost</h4>
        <p>You will be charged based on the number of monthly active learners you have each month</p>
        <div
          style={{
            '--rs-tooltip-bg': 'var(--rs-slider-progress)',
            '--rs-tooltip-text': 'white',
            '--rs-slider-progress': 'rgb(2, 88, 237)',
            '--rs-slider-thumb-border': 'rgb(2, 88, 237)'
          }}
        >
          <Slider
            progress
            style={{
              marginTop: 26,
              marginBottom: 0,
              width: '70%',
              marginLeft: 'auto',
              marginRight: 'auto'
            }}
            step={1}
            value={inverseScaleValue(value)}
            onChange={handleChange}
            renderTooltip={(value) => <CustomTooltip value={handleCustomTip(scaleValue(value))} />}
            min={0}
            max={100}
          />
          <div className="bar-divider">
            <span>20</span>
            <span>10,000</span>
          </div>
        </div>
        {value <= 10000 ? <h2>${price}</h2> : <h6>Please contact sales department for further information</h6>}
      </CardBody>
    </Card>
  );
};

export default PriceSlider;
