import React from 'react';

import Form from 'react-bootstrap/Form';
import { getWordpressBaseUrl } from 'helpers';

const UsersListingHeader = (props) => {
  const { checkAll, handleSelectAllUsers, userFilters, onSort } = props;

  return (
    <thead>
      <tr>
        <th className="col-check">
          <Form.Check>
            <Form.Check.Input
              type="checkbox"
              id="checkAll"
              checked={checkAll === true}
              onChange={handleSelectAllUsers}
            />
            <Form.Check.Label htmlFor="checkAll" />
          </Form.Check>
        </th>
        <th>
          <span
            className={userFilters.column === 'username' ? `sorter ${userFilters.direction}` : 'sorter asc desc'}
            onClick={() => onSort('username')}
          >
            Username
          </span>
        </th>
        <th>
          <span
            className={userFilters.column === 'profile__name' ? `sorter ${userFilters.direction}` : 'sorter asc desc'}
            onClick={() => onSort('profile__name')}
          >
            Name/Email
          </span>
        </th>
        <th className="text-center">
          <span>LMS Role</span>
        </th>
        {getWordpressBaseUrl() && (
          <th className="text-center">
            <span className="">Discovery Role</span>
          </th>
        )}
        <th className="text-center">
          <span>Panel Role</span>
        </th>
        <th className="status text-center">
          <span>Status</span>
        </th>
        <th className="action text-center">
          <span>Action</span>
        </th>
      </tr>
    </thead>
  );
};

export default UsersListingHeader;
