import React from 'react';
import PriceSlider from './priceSlider';

const PricingCalculator = (props) => {
  return (
    <>
      <PriceSlider handleSubscribe={props.handleSubscribe} />
      <div className="text-center mb-4">
        <h6 className="mb-0">
          If you need help to setup your account{' '}
          <a className="link" href="https://edly.io/request-a-demo/">
            Contact Sales
          </a>
        </h6>
        <small>Services charges will be applied</small>
      </div>
    </>
  );
};

export default PricingCalculator;
