import React, { useState, useEffect } from 'react';
import { Row, Col, Modal, Button, Form } from 'react-bootstrap';
import { IconInfoCircle } from '@tabler/icons-react';

import { messageNotification, getWordpressBaseUrl } from 'helpers';
import { usersActions } from 'pages/users/containers/usersContainer/actions';
import PanelRolesButton from 'pages/users/containers/usersContainer/components/panelRolesButton';
import { EDIT_USER_FIELD } from 'pages/users/containers/usersContainer/constants';

const EditUser = (props) => {
  const { validation_messages, page, searchText, roles, password_reset, pageSize } = props;
  const {
    id,
    username,
    name,
    email,
    is_staff,
    is_active,
    is_blocked,
    is_superuser,
    lms_role,
    wordpress_role,
    panel_role,
    is_social_user
  } = props.user;
  const [inputs, setInputs] = useState({
    username: '',
    name: '',
    email: '',
    is_staff: true,
    is_active: true,
    is_blocked: false,
    is_superuser: false,
    lms_role: 'learner',
    wordpress_role: 'subscriber',
    panel_role: 'panel_restricted'
  });
  const [isRequestInProgress, setIsRequestInProgress] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    setInputs({
      id: id,
      username: username,
      name: name,
      email: email,
      is_staff: is_staff,
      is_active: is_active,
      is_blocked: is_blocked,
      is_superuser: is_superuser,
      lms_role: lms_role || 'learner',
      wordpress_role: wordpress_role || 'subscriber',
      panel_role: panel_role || 'panel_restricted'
    });
  }, [id, username, name, email, is_staff, is_active, is_blocked, is_superuser, lms_role, wordpress_role, panel_role]);

  const handleValidation = (event) => {
    event.persist();
    props.dispatch(usersActions.validateUser({ name: inputs.name }));
    if (inputs.name) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  };

  const handleInputValueChange = (event) => {
    event.persist();
    if (event.target.name === 'is_active_user') {
      setInputs((inputs) => ({ ...inputs, is_active: !inputs.is_active }));
    } else if (event.target.name === 'is_blocked_user') {
      setInputs((inputs) => ({ ...inputs, is_blocked: event.target.checked }));
    } else {
      setInputs((inputs) => ({ ...inputs, [event.target.name]: event.target.value }));
    }
    if (inputs.name) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  };

  const handleSubmit = (event) => {
    setIsRequestInProgress(true);
    event.preventDefault();
    let payload = inputs;
    payload['page_number'] = page;
    payload['searchText'] = searchText;
    payload['roles'] = roles;
    payload['page_size'] = pageSize;
    props.dispatch(
      usersActions.validateUser(
        { name: inputs.name },
        usersActions.updateUser(payload, props.onHide, setIsRequestInProgress, props.admin_email)
      )
    );
    if (inputs.name) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  };

  const shouldButtonBeDisabled = () => {
    const isUserUpdated = EDIT_USER_FIELD.find((item) => props.user[item] !== inputs[item]);
    if (isRequestInProgress) {
      return true;
    } else {
      return Boolean(!isUserUpdated) || buttonDisabled;
    }
  };

  const handlePasswordReset = (email) => {
    if (email) {
      props.dispatch(usersActions.resetUserPassword(email));
    } else {
      messageNotification(
        'danger',
        'There was an error resetting user password',
        'Please enter email address to reset password'
      );
    }
  };

  return (
    <div>
      <Modal onHide={props.onHide} show={props.show} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            <strong className="title">Editing user: </strong> {inputs.username}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs="12" lg="6">
              {/* <label htmlFor="username">
              Username
              <span className="required">*</span>
            </label>
            <input
              type="text"
              id="username"
              name="username"
              className="form-control"
              onBlur={handleValidation}
              onChange={handleInputValueChange}
              value={inputs.username || ''}
              readOnly
            />
            <span className="text-error">{validation_messages['username']}</span> */}
              <Form.Group className="mb-3">
                <Form.Label>
                  Username <span className="required">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  id="username"
                  name="username"
                  onBlur={handleValidation}
                  onChange={handleInputValueChange}
                  value={inputs.username || ''}
                  disabled
                />
                <span className="text-error">{validation_messages['username']}</span>
              </Form.Group>
            </Col>
            <Col xs="12" lg="6">
              {/* <label htmlFor="name">
              Full Name<span className="required">*</span>
            </label>
            <input
              type="text"
              id="name"
              name="name"
              className="form-control"
              onBlur={handleValidation}
              onChange={handleInputValueChange}
              value={inputs.name || ''}
              readOnly
            />
            <span className="text-error">{validation_messages['name']}</span> */}
              <Form.Group className="mb-3">
                <Form.Label>
                  Full Name <span className="required">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  id="name"
                  name="name"
                  onBlur={handleValidation}
                  onChange={handleInputValueChange}
                  value={inputs.name || ''}
                  disabled
                />
                <span className="text-error">{validation_messages['name']}</span>
              </Form.Group>
            </Col>
            <Col xs="12">
              {/* <label htmlFor="email">
              Email<span className="required">*</span>
            </label>
            <input
              type="text"
              id="email"
              name="email"
              className="form-control"
              onBlur={handleValidation}
              onChange={handleInputValueChange}
              value={inputs.email || ''}
              readOnly
            />
            <span className="text-error">{validation_messages['email']}</span> */}
              <Form.Group className="mb-3">
                <Form.Label>
                  Email <span className="required">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  id="email"
                  name="email"
                  onBlur={handleValidation}
                  onChange={handleInputValueChange}
                  value={inputs.email || ''}
                  disabled
                />
                <span className="text-error">{validation_messages['email']}</span>
              </Form.Group>
            </Col>
            <Col xs="12">
              <Row>
                <Col auto>
                  {/* <input
                  type="checkbox"
                  className="custom-control-input"
                  name="is_active_user"
                  id="is_active_user"
                  value={inputs.is_active}
                  onChange={handleInputValueChange}
                  checked={inputs.is_active ? inputs.is_active : false}
                />
                <label className="custom-control-label" htmlFor="is_active_user">
                  Active
                </label> */}
                  <Form.Check className="mb-4">
                    <Form.Check.Input
                      type="checkbox"
                      name="is_active_user"
                      id="is_active_user"
                      value={inputs.is_active}
                      onChange={handleInputValueChange}
                      checked={inputs.is_active ? inputs.is_active : false}
                    />
                    <Form.Check.Label htmlFor="is_active_user">Active</Form.Check.Label>
                  </Form.Check>
                </Col>
                <Col auto>
                  {/* <input
                  type="checkbox"
                  className="custom-control-input"
                  name="is_blocked_user"
                  id="is_blocked_user"
                  value={inputs.is_blocked}
                  onChange={handleInputValueChange}
                  checked={inputs.is_blocked ? inputs.is_blocked : false}
                />
                <label className="custom-control-label" htmlFor="is_blocked_user">
                  Blocked
                </label> */}
                  <Form.Check className="mb-4">
                    <Form.Check.Input
                      type="checkbox"
                      name="is_blocked_user"
                      id="is_blocked_user"
                      value={inputs.is_blocked}
                      onChange={handleInputValueChange}
                      checked={inputs.is_blocked ? inputs.is_blocked : false}
                    />
                    <Form.Check.Label htmlFor="is_blocked_user">Blocked</Form.Check.Label>
                  </Form.Check>
                </Col>
              </Row>
            </Col>
            {!is_social_user && (
              <Col xs="12" className="mb-4">
                <Button
                  variant="link"
                  className={password_reset ? 'p-0 mb-3' : 'password-reset-disabled'}
                  onClick={() => handlePasswordReset(inputs.email)}
                >
                  Change Password
                </Button>
              </Col>
            )}
          </Row>
          <PanelRolesButton inputs={inputs} handleInputChange={handleInputValueChange} />
          {getWordpressBaseUrl() && (
            <Row className="mb-4">
              <Col xs="12" lg="4">
                <h6>Discovery Role</h6>
              </Col>
              <Col xs="12" lg="8">
                <Row>
                  <Col xs="12" md="6" className="has-tip-info mb-3">
                    {/* <input
                    type="radio"
                    name="wordpress_role"
                    className="custom-control-input"
                    id="subscriber_filter"
                    onChange={handleInputValueChange}
                    checked={inputs.wordpress_role.toString() === 'subscriber'}
                    value="subscriber"
                  /> */}
                    <Form.Check
                      type="radio"
                      label="Subscriber"
                      name="wordpress_role"
                      className="custom-control-input"
                      id="subscriber_filter"
                      onChange={handleInputValueChange}
                      checked={inputs.wordpress_role.toString() === 'subscriber'}
                      value="subscriber"
                    />
                    <span className="tool-tip">
                      <IconInfoCircle stroke={1.5} />
                      <span className="tip-content">
                        <span className="tip-content-item">
                          User is registered on the Discovery website but does not have WordPress Admin access
                        </span>
                        <span className="tip-content-item">User cannot make any changes to the WordPress website</span>
                      </span>
                    </span>
                  </Col>
                  <Col xs="12" md="6" className="has-tip-info mb-3">
                    {/* <input
                      type="radio"
                      name="wordpress_role"
                      className="custom-control-input"
                      id="edly_admin_filter"
                      onChange={handleInputValueChange}
                      checked={inputs.wordpress_role.toString() === 'edly_admin'}
                      value="edly_admin"
                    /> */}
                    <Form.Check
                      type="radio"
                      name="wordpress_role"
                      className="d-inline"
                      id="edly_admin_filter"
                      onChange={handleInputValueChange}
                      checked={inputs.wordpress_role.toString() === 'edly_admin'}
                      value="edly_admin"
                    />
                    <Form.Check.Label
                      className={`custom-control-label d-inline ms-2 ${inputs.is_active ? '' : 'disabled'}`}
                      htmlFor="edly_admin_filter"
                    >
                      Edly Admin
                      <span className="tool-tip">
                        <IconInfoCircle stroke={1.5} />
                        <span className="tip-content">
                          <span className="tip-content-item">User has full admin access to the Discovery website</span>
                          <span className="tip-content-item">
                            User can customize the WordPress website, add/delete pages and has editing access to the
                            WordPress Administration panel
                          </span>
                        </span>
                      </span>
                    </Form.Check.Label>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
          <Row className="mb-4">
            <Col xs="12" lg="4">
              <h6>LMS Role</h6>
            </Col>
            <Col xs="12" lg="8">
              <Row>
                <Col xs="12" md="6" className="has-tip-info mb-3">
                  {/* <input
                    type="radio"
                    name="lms_role"
                    className="custom-control-input"
                    id="learner_filter"
                    onChange={handleInputValueChange}
                    checked={inputs.lms_role.toString() === 'learner'}
                    value="learner"
                  /> */}
                  <Form.Check
                    type="radio"
                    label="Learner"
                    name="lms_role"
                    className="custom-control-input"
                    id="learner_filter"
                    onChange={handleInputValueChange}
                    checked={inputs.lms_role.toString() === 'learner'}
                    value="learner"
                  />
                  <span className="tool-tip">
                    <IconInfoCircle stroke={1.5} />
                    <span className="tip-content">
                      <span className="tip-content-item">User cannot create a course or library</span>
                      <span className="tip-content-item">User cannot see/edit any course</span>
                      <span className="tip-content-item">User can request to become a site creator</span>
                      <span className="tip-content-item">User does not have access to Studio</span>
                    </span>
                  </span>
                </Col>
                <Col xs="12" md="6" className="has-tip-info mb-3">
                  {/* <input
                    type="radio"
                    name="lms_role"
                    className="custom-control-input"
                    id="staff_filter"
                    onChange={handleInputValueChange}
                    checked={inputs.lms_role.toString() === 'staff'}
                    value="staff"
                  /> */}
                  <Form.Check
                    type="radio"
                    name="lms_role"
                    className="d-inline"
                    id="staff_filter"
                    onChange={handleInputValueChange}
                    checked={inputs.lms_role.toString() === 'staff'}
                    value="staff"
                  />
                  <Form.Check.Label
                    className={`custom-control-label d-inline ms-2 ${inputs.is_active ? '' : 'disabled'}`}
                    htmlFor="staff_filter"
                  >
                    Staff
                    <span className="tool-tip">
                      <IconInfoCircle stroke={1.5} />
                      <span className="tip-content">
                        <span className="tip-content-item">User can create a course or library</span>
                        <span className="tip-content-item">
                          User can view/edit/archive/publish all courses on the current site even if he/she is not a
                          part of the course team
                        </span>
                        <span className="tip-content-item">User can add/remove team members to a course team</span>
                      </span>
                    </span>
                  </Form.Check.Label>
                </Col>
                <Col xs="12" md="6" className="has-tip-info mb-3">
                  {/* <input
                    type="radio"
                    name="lms_role"
                    className="custom-control-input"
                    id="course_creator_filter"
                    onChange={handleInputValueChange}
                    checked={inputs.lms_role.toString() === 'course_creator'}
                    value="course_creator"
                  /> */}
                  <Form.Check
                    type="radio"
                    name="lms_role"
                    className="d-inline"
                    id="course_creator_filter"
                    onChange={handleInputValueChange}
                    checked={inputs.lms_role.toString() === 'course_creator'}
                    value="course_creator"
                  />

                  <Form.Check.Label
                    className={`custom-control-label  d-inline ms-2 ${inputs.is_active ? '' : 'disabled'}`}
                    htmlFor="course_creator_filter"
                  >
                    Course Creator
                    <span className="tool-tip">
                      <IconInfoCircle stroke={1.5} />
                      <span className="tip-content">
                        <span className="tip-content-item">User can create a course or library</span>
                        <span className="tip-content-item">User can view/edit all courses created by them</span>
                        <span className="tip-content-item">
                          User can view/edit courses created by other users if they are a part of the course team
                        </span>
                      </span>
                    </span>
                  </Form.Check.Label>
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={props.onHide}>
            Cancel
          </Button>
          <Button disabled={shouldButtonBeDisabled()} variant="primary" onClick={handleSubmit}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EditUser;
