import React, { useEffect, useState } from 'react';

import { Button, Modal } from 'react-bootstrap';
import { featureOverviewService } from 'services/featureOverview';
import { getOrganization } from 'selectors';
import { connect } from 'react-redux';
import 'pages/dashboard/containers/featureOverview/index.scss';

const FeatureOverview = (props) => {
  const { show_user_guide } = props;
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!show_user_guide) {
      setTimeout(() => {
        featureOverviewService
          .getFeatureOverviewDetails()
          .then((response) => {
            setIsOpen(response.data.show_overview);
          })
          .catch((err) => {});
      }, [3000]);
    }
  }, [show_user_guide]);

  const handleOverViewClose = (e) => {
    featureOverviewService
      .postFeatureOverviewDetails(false)
      .catch((err) => {})
      .finally(() => {
        setIsOpen(false);
      });
  };

  return (
    isOpen && (
      <Modal className="user-guide-model feature-overview" show={isOpen} size="lg" onHide={() => {}}>
        <Modal.Header>
          <Modal.Title>Feature Overview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <section className="user-guide">
            <div className="learner-demo">
              <h5>What's New?</h5>
              <div
                className="sl-embed"
                style={{
                  position: 'relative',
                  paddingBottom: 'calc(55.34% + 27px)',
                  width: '100%',
                  height: '0',
                  transform: 'scale(1)'
                }}
              >
                <iframe
                  title="feature-overview"
                  className="sl-demo"
                  src="https://app.storylane.io/demo/kpenoayepevm"
                  name="sl-embed"
                  allow="fullscreen;"
                  style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    border: '1px solid rgba(0,0,0,.1)',
                    height: '100%',
                    boxSizing: 'border-box',
                    borderRadius: '12px'
                  }}
                ></iframe>
              </div>
            </div>
          </section>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleOverViewClose}>
            Finish
          </Button>
        </Modal.Footer>
      </Modal>
    )
  );
};

function mapStateToProps(state) {
  let show_user_guide = getOrganization(state).show_user_guide;
  return { show_user_guide };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FeatureOverview);
