import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { bindActionCreators } from 'redux';

import CreateSite from 'components/createSite';
import PricingCalculator from 'pages/pricing/containers/pricingContainer/components/pricingCalculator';
import BillingDetails from 'pages/pricing/containers/pricingContainer/components/BillingDetails';
import PaymentMethod from 'pages/pricing/containers/pricingContainer/components/PaymentMethod';
import { TermsAndConditions } from 'pages/pricing/containers/pricingContainer/components/termsAndConditions';
import { pricingActions } from 'pages/pricing/containers/pricingContainer/actions/pricing';

const FlexiblePricing = (props) => {
  const [businessModalShow, setBusinessModalShow] = useState(false);
  const [inputs, setInputs] = useState({
    business_name: '',
    address: '',
    apartment: '',
    country: '',
    city: '',
    zip: '',
    state: ''
  });

  function handleSubscribe() {
    setBusinessModalShow(true);
  }

  function showCreateSiteView() {
    setCreatingSite(true);
  }

  const [creatingSite, setCreatingSite] = useState(false);

  return creatingSite ? (
    <CreateSite setCreatingSite={setCreatingSite} />
  ) : (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <h2 className="mb-4">Billing Details</h2>
      </div>
      <TermsAndConditions
        show={businessModalShow}
        setBusinessModalShow={setBusinessModalShow}
        onHide={() => {
          setBusinessModalShow(false);
        }}
        inputs={inputs}
        setInputs={setInputs}
        updateOrgTermsConditions={props.updateOrgTermsConditions}
      />
      <Row>
        <Col xs={12} md={{ span: 12 }}>
          <div className="current-billing">
            <BillingDetails creatingSite={creatingSite} setCreatingSite={setCreatingSite} />
            <PaymentMethod handleSubscribe={handleSubscribe} showCreateSiteView={showCreateSiteView} />
          </div>
          <PricingCalculator handleSubscribe={handleSubscribe} />
        </Col>
      </Row>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators({ ...pricingActions }, dispatch)
  };
}

export default connect(null, mapDispatchToProps)(FlexiblePricing);
