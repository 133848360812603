import React from 'react';

import { getLearnerCompletedCourses } from 'helpers';
import { Row, Col, Card, CardBody } from 'react-bootstrap';

const moment = require('moment');
const lodash = require('lodash');

const LearnerInfo = (props) => {
  const { learner_info } = props;
  const registration_fields = learner_info.registration_fields;

  return (
    <div className="learner-info-container">
      <Card>
        <CardBody>
          <Row className="pt-1 pb-1">
            <Col xs="12" md="6">
              <strong>Username:</strong>
            </Col>
            <Col xs="12" md="6" className="font-weight-medium">
              <span>{learner_info.username}</span>
            </Col>
          </Row>
          <Row className="pt-1 pb-1">
            <Col xs="12" md="6">
              <strong>Email:</strong>
            </Col>
            <Col xs="12" md="6" className="font-weight-medium">
              {learner_info.email}
            </Col>
          </Row>
          {Object.keys(registration_fields).map((key) => {
            return (
              <Row key={key} className="pt-1 pb-1">
                <Col xs="12" md="6">
                  <strong>{lodash.startCase(key)}:</strong>
                </Col>
                <Col xs="12" md="6" className="font-weight-medium">
                  {registration_fields[key] ? registration_fields[key] : '-'}
                </Col>
              </Row>
            );
          })}
          <hr />
          <Row className="pt-1 pb-1">
            <Col xs="12" md="6">
              <strong>Courses Enrolled:</strong>
            </Col>
            <Col xs="12" md="6" className="font-weight-medium">
              {learner_info.courses.length}
            </Col>
          </Row>
          <Row className="pt-1 pb-1">
            <Col xs="12" md="6">
              <strong>Courses Completed:</strong>
            </Col>
            <Col xs="12" md="6" className="font-weight-medium">
              {getLearnerCompletedCourses(learner_info.courses).length}
            </Col>
          </Row>
          <hr />
          <Row className="pt-1 pb-1">
            <Col xs="12" md="6">
              <strong>Status:</strong>
            </Col>
            <Col xs="12" md="6" className="font-weight-medium">
              {learner_info.is_active === true ? 'Active' : 'In-active'}
            </Col>
          </Row>
          <Row className="pt-1 pb-1">
            <Col xs="12" md="6">
              <strong>Account Created:</strong>
            </Col>
            <Col xs="12" md="6" className="font-weight-medium">
              {' '}
              {learner_info.date_joined ? moment(learner_info.date_joined).format('YYYY-M-DD') : 'N/A'}
            </Col>
          </Row>
          <Row className="pt-1 pb-1">
            <Col xs="12" md="6">
              <strong>Last Login:</strong>
            </Col>
            <Col xs="12" md="6" className="font-weight-medium">
              {' '}
              {learner_info.last_login ? moment(learner_info.last_login).format('YYYY-M-DD HH:mm:ss') : 'N/A'}
            </Col>
          </Row>
          <Row className="pt-1 pb-1">
            <Col xs="12" md="6">
              <strong>Last Course Activity:</strong>
            </Col>
            <Col xs="12" md="6" className="font-weight-medium">
              {' '}
              {learner_info.course_activity_date
                ? moment(learner_info.course_activity_date).format('YYYY-M-DD HH:mm:ss')
                : 'N/A'}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

export default LearnerInfo;
