import React from 'react';
import { connect } from 'react-redux';
import { IconInfoCircle } from '@tabler/icons-react';

import BarChart from 'components/charts/barChart';
import BarChartTrend from 'components/charts/barChartTrend';
import PercentChangeBox from 'components/analytics/percentChangeBox';
import { getLearnerMAUsChange, getLearnerMAUsLabels, getLearnerMAUsValues } from 'selectors';
import { Card, CardBody } from 'react-bootstrap';

const LearnersMAUs = (props) => {
  const { data_labels, data_values, percent_change, show_chart, trending } = props;

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <span className="sub-title pt-0">
          Monthly Active Learners
          <span className="tool-tip ms-2">
            <IconInfoCircle stroke={1.5} />
            <span className="tip-content">Number of monthly active learners over time.</span>
          </span>
        </span>
        <PercentChangeBox percent_change={percent_change} />
      </div>
      <Card className="stats-box mb-4">
        <CardBody>
          {show_chart &&
            (trending === 'true' ? (
              <BarChartTrend title="MALs Count" labels={data_labels} values={data_values} />
            ) : (
              <BarChart title="MALs Count" labels={data_labels} values={data_values} />
            ))}
        </CardBody>
      </Card>
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  const data_labels = getLearnerMAUsLabels(state);
  const data_values = getLearnerMAUsValues(state);
  const percent_change = getLearnerMAUsChange(state);
  const show_chart = ownProps.show_chart;
  const trending = ownProps.trending;

  return {
    data_labels,
    data_values,
    percent_change,
    show_chart,
    trending
  };
}

export default connect(mapStateToProps)(LearnersMAUs);
