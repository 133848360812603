import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import Header from 'components/header';
import { getUserPanelRole } from 'selectors';

const Page401 = (props) => {
  if (props.panel_role !== 'panel_restricted') {
    return <Redirect to={{ pathname: '/panel' }} />;
  }

  return (
    <div>
      <Header />
      <div className="error-page p-4 text-center">
        <div className="error-block">
          <img
            src="https://edly-edx-theme-files.s3.amazonaws.com/error-page-assets/error-401.svg"
            width="150"
            className="mb-4"
            alt="Error 401"
          />
          <h5>Access Denied!</h5>
          <p>
            Sorry, you do not have permission to access this page.
            <br />
            Please contact your system administrator.
          </p>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const panel_role = getUserPanelRole(state);
  return { panel_role };
}

export default connect(mapStateToProps, null)(Page401);
