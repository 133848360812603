import React from 'react';

import { AccountSettings } from 'pages/settings/containers/settingsContainer/components/accountSettings';
import UserPreferences from 'pages/settings/containers/settingsContainer/components/userPreferences';
import 'pages/settings/index.scss';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

const GeneralSettings = () => {
  return (
    <div className="block">
      <h2 className="mb-3">General</h2>
      <div className="tabs" id="accountSettings">
        <Tabs defaultActiveKey="account_settings" id="tab" className="mb-4">
          <Tab eventKey="account_settings" title="Account Settings">
            <AccountSettings />
          </Tab>
          <Tab eventKey="user_preferences" title="User Preferences">
            <UserPreferences />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default GeneralSettings;
