import React, { useState } from 'react';
import './lmsKeyInput.css';
import { updateEdlySiteConfig } from 'services/siteConfiguration';
import { messageNotification } from 'helpers';
import { LMS_GTM_ID_PLACEHOLDER, LMS_GA_ID_PLACEHOLDER } from '../constants';
import { Row, Col, Form, Button } from 'react-bootstrap';

const LmsKeyInput = () => {
  const [lmsGTM, setLmsGTM] = useState('');
  const [lmsGA, setLmsGA] = useState('');

  const updateKey = () => {
    if (lmsGTM === '' && lmsGA === '') {
      messageNotification('danger', 'Invalid Input', 'You must fill atleast one field for LMS keys');
      return;
    }

    const lms_data = {
      lms: {
        ...(lmsGTM && { GTM_ID: lmsGTM }),
        ...(lmsGA && { GA_ID: lmsGA })
      },
      cms: {
        ...(lmsGTM && { GTM_ID: lmsGTM }),
        ...(lmsGA && { GA_ID: lmsGA })
      }
    };

    updateEdlySiteConfig(lms_data)
      .then((res) =>
        messageNotification(
          'success',
          'Key updated',
          `The following keys have been updated:
         ${lmsGTM && 'GTM_ID: ' + lmsGTM}
          ${lmsGA && 'GA_ID: ' + lmsGA}`
        )
      )
      .catch((error) => {
        messageNotification('danger', 'Error', `${error}`);
      });
  };

  return (
    <div className="integration-holder mb-4">
      <div className="subheader-holder mb-4">
        <h4>Google analytics for LMS</h4>
        <p>Add Google Analytics for Property (GA4) and Google Tag Manger (GTM) keys for LMS</p>
      </div>
      <Row>
        <Col xs="12" lg="6">
          <Form.Group className="mb-3">
            <Form.Label>GTM ID</Form.Label>
            <Form.Control
              value={lmsGTM}
              placeholder={LMS_GTM_ID_PLACEHOLDER}
              onChange={(event) => setLmsGTM(event.target.value)}
            />
          </Form.Group>
        </Col>
        <Col xs="12" lg="6">
          <Form.Group className="mb-3">
            <Form.Label>GA4 ID</Form.Label>
            <Form.Control
              value={lmsGA}
              placeholder={LMS_GA_ID_PLACEHOLDER}
              onChange={(event) => setLmsGA(event.target.value)}
            />
          </Form.Group>
        </Col>
        <Col className="btn-holder justify-content-end d-flex">
          <Button className="btn btn-primary" onClick={updateKey}>
            Save
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default LmsKeyInput;
