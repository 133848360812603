import React, { Fragment } from 'react';
import { IconInfoCircle } from '@tabler/icons-react';
import LineChart from 'components/charts/lineChart';
import { Card, CardBody, Col } from 'react-bootstrap';

import InsightsLoader from 'components/loaders/InsightsLoader';

const EnrollmentsOverTime = (props) => {
  const { course_info } = props;

  const labels =
    course_info &&
    course_info.learners_enrolled &&
    course_info.learners_enrolled.history.map((data) => {
      return data.period;
    });
  const values =
    course_info &&
    course_info.learners_enrolled &&
    course_info.learners_enrolled.history.map((data) => {
      return data.value;
    });

  return (
    <Col xs="12" md="6" className="mb-4">
      <Card>
        <CardBody>
          {course_info && course_info.learners_enrolled && course_info.learners_enrolled.history ? (
            <Fragment>
              <span className="sub-title">
                Enrollments Over Time
                <span className="tool-tip ms-2">
                  <IconInfoCircle stroke={1.5} />
                  <span className="tip-content">Number of enrollments over time, for the last six months.</span>
                </span>
              </span>
              {labels && values && <LineChart title="Course Enrollments" labels={labels} values={values} />}
            </Fragment>
          ) : (
            <InsightsLoader />
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

export default EnrollmentsOverTime;
