import BenefitsSlideContent from './benefitsSlideContent';
import { benefitsContent } from '../constants/benefitsContent';
import { Button, Col, Row } from 'react-bootstrap';

const BenefitList = ({ displayedData, visibleItemCount, handleLoadMore, searchText }) => {
  return displayedData.length ? (
    <div>
      <Row>
        {displayedData.map((item, index) => (
          <BenefitsSlideContent key={index} item={item} />
        ))}
      </Row>
      {!searchText && (
        <div className="pt-2 d-flex justify-content-center">
          <Button
            onClick={handleLoadMore}
            variant="primary"
            className={`${visibleItemCount >= benefitsContent.length ? 'disabled' : ''}`}
          >
            Load More
          </Button>
        </div>
      )}
    </div>
  ) : (
    <Row className="gap-3">
      <p>No record found</p>
    </Row>
  );
};

export default BenefitList;
