import React, { useState, useEffect, useRef } from 'react';
import Badgr from './bagr';
import { messageNotification } from 'helpers';
import { badgrActions } from 'pages/integrations/containers/BadgrContainer/actions';
import { IconInfoTriangle } from '@tabler/icons-react';
import { Alert, Button, Row, Col } from 'react-bootstrap';

import { addBadgeClass } from 'services/siteConfiguration';

const BadgrContainer = ({ badges, dispatch, enableBadges }) => {
  const [badgesList, setBadgesList] = useState([]);
  const [oldBadges, setOldBadges] = useState(badges);
  const [badgrData, setBadgrData] = useState({ add: [], update: [], delete: [] });
  const [call, setCall] = useState(false);
  const [checkBadgrs, setCheckBagrs] = useState(false);
  const childRefs = useRef([]);

  const handleDataFromChild = (data) => {
    if (data.hasOwnProperty('slug')) {
      setBadgrData((prevData) => ({
        ...prevData,
        update: [...prevData.update, data]
      }));
    } else {
      setBadgrData((prevData) => ({
        ...prevData,
        add: [...prevData.add, data]
      }));
    }
  };

  useEffect(() => {
    if (call) {
      addBadgeClass(badgrData)
        .then(() => messageNotification('success', 'Badgr(s) Updated', 'Badgr(s) updated successfully'))
        .catch((error) => {
          messageNotification('danger', 'Badge Not Added', error.response.data);
        })
        .finally(() => {
          setBadgrData({ add: [], update: [], delete: [] });
          dispatch(badgrActions.fetchBadgesData());
          setCall(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [call]);

  useEffect(() => {
    setOldBadges(badges);
    setBadgesList([]);
    setCheckBagrs(true);
  }, [badges]);

  const setNewBadgr = () => {
    setBadgesList((prevBadgesList) => [
      ...prevBadgesList,
      <Badgr
        key={prevBadgesList.length}
        id={prevBadgesList.length}
        setNewBadgr={setNewBadgr}
        removeBadgr={removeBadgr}
        sendData={handleDataFromChild}
        ref={(ref) => (childRefs.current[prevBadgesList.length] = ref)}
      />
    ]);
  };

  useEffect(() => {
    if (checkBadgrs && oldBadges.length === 0 && badgesList.length === 0) {
      setNewBadgr();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oldBadges, badgesList]);

  const saveBadges = () => {
    Object.keys(childRefs.current).forEach((key) => {
      if (childRefs.current[key]) {
        childRefs.current[key].sendDataToParent();
      }
    });
    setCall(true);
  };

  const removeBadgr = (indexToRemove, isOldBadge = false) => {
    if (isOldBadge) {
      setBadgrData((prevData) => ({
        ...prevData,
        delete: [...prevData.delete, indexToRemove]
      }));
      setOldBadges((prevOldBadges) => prevOldBadges.filter((badge) => badge.slug !== indexToRemove));
    } else {
      setBadgesList((prevBadgesList) => prevBadgesList.filter((badge) => badge.props.id !== indexToRemove));
    }
    setCheckBagrs(true);
  };

  return (
    <>
      <div className="subheader-holder">
        <h5> 3. Add Badges</h5>
        <p>These badges will appear on Badgr backpack</p>
      </div>
      {!enableBadges ? (
        <Alert variant="secondary">
          <IconInfoTriangle className="me-3" stroke={1.5} />
          <span>Please add badgr credientials to add badges</span>
        </Alert>
      ) : (
        <>
          {oldBadges.map((badge, index) => (
            <Badgr
              dispatch={dispatch}
              badge={badge}
              key={badge.slug}
              setNewBadgr={setNewBadgr}
              removeBadgr={removeBadgr}
              sendData={handleDataFromChild}
              ref={(ref) => (childRefs.current[badge.slug] = ref)}
            />
          ))}

          {badgesList && badgesList.map((badger, index) => <React.Fragment key={index}>{badger}</React.Fragment>)}
          <Row className="pt-4">
            <Col xs="12" className="justify-content-end d-flex">
              <Button onClick={saveBadges}>Save</Button>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default BadgrContainer;
