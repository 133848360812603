import { pricingConstants, userSliderConstants } from 'pages/pricing/containers/pricingContainer/constants';

const initialStatePaymentMethod = {
  customerId: '',
  paymentCardDigits: '',
  paymentCardType: '',
  userEmail: '',
  userName: ''
};

const userSliderCount = {
  count: 0
};

export const fetchPaymentMethod = (state = initialStatePaymentMethod, action) => {
  switch (action.type) {
    case pricingConstants.FETCH_PAYMENT_METHOD_SUCCESS:
      const data = action.payload.data;
      return data?.id
        ? {
            customerId: data.id,
            paymentCardDigits: data.last_four_card_digits,
            paymentCardType: data.card_type,
            userEmail: data.user_email,
            userName: data.user_name
          }
        : initialStatePaymentMethod;
    case pricingConstants.FETCH_PAYMENT_METHOD_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
};

export const userSliderReducer = (state = userSliderCount, action) => {
  switch (action.type) {
    case userSliderConstants.SET_COUNT:
      return {
        ...state,
        count: action.payload
      };
    default:
      return state;
  }
};
