import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { IconX } from '@tabler/icons-react';

const Badgr = forwardRef(({ badge, setNewBadgr, id, removeBadgr, sendData }, ref) => {
  const [courseKey, setCourseKey] = useState(badge ? badge.course_id : '');
  const [courseMode, setCourseMode] = useState(badge ? badge.mode : 'honor');
  const [serverSlug, setServerSlug] = useState(badge ? badge.badgr_server_slug : '');
  const [isBlur, setIsBlur] = useState(false);

  const sendDataToParent = () => {
    if (isBlur) {
      if (id !== null && id !== undefined) {
        sendData({ course_key: courseKey, course_mode: courseMode, badgr_server_slug: serverSlug });
      } else {
        sendData({
          slug: badge.slug,
          course_id: courseKey,
          mode: courseMode,
          badgr_server_slug: serverSlug
        });
      }
    }
  };

  useImperativeHandle(ref, () => ({
    sendDataToParent
  }));

  const deleteBadgr = () => {
    if (id !== null && id !== undefined) {
      removeBadgr(id);
    } else {
      removeBadgr(badge.slug, true);
    }
  };

  const handleBlur = () => {
    setIsBlur(true);
  };

  return (
    <Row className="badge-record">
      <Col xs="12">
        <hr />
      </Col>
      <Col xs="12" lg="8">
        <Row>
          <Col xs="12" lg="6">
            <Form.Group className="mb-3">
              <Form.Label>Course Key</Form.Label>
              <Form.Control
                value={courseKey}
                placeholder={'course-v1:badgr+bg+101'}
                onBlur={handleBlur}
                onChange={(event) => setCourseKey(event.target.value)}
              />
            </Form.Group>
          </Col>
          <Col xs="12" lg="6">
            <Form.Label>Course Mode</Form.Label>
            <Form.Select
              id="course_mode"
              name="course_mode"
              value={courseMode}
              onBlur={handleBlur}
              onChange={(event) => setCourseMode(event.target.value)}
            >
              <option>Select a course mode</option>
              <option value="honor">honor</option>
              <option value="verified">verified</option>
              <option value="professional">professional</option>
            </Form.Select>
          </Col>

          <Col xs="12">
            <Form.Group className="mb-3">
              <Form.Label>Badgr Server Slug</Form.Label>
              <Form.Control
                value={serverSlug}
                placeholder={'Enter your Badgr Server Slug'}
                onBlur={handleBlur}
                onChange={(event) => setServerSlug(event.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
      </Col>
      <Col xs="12" lg="4" className="justify-content-end d-flex align-items-center">
        <Button
          size="sm"
          variant="link"
          onClick={() => {
            setNewBadgr();
          }}
        >
          Add Badge
        </Button>
        <Button className="ms-2" variant="default">
          <IconX className="color-secondary" stroke={1.5} onClick={deleteBadgr} />
        </Button>
      </Col>
    </Row>
  );
});
export default Badgr;
