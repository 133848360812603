import React from 'react';
import { Modal } from 'react-bootstrap';
import Slider from 'react-slick';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';

const ImagePreviewSlider = (props) => {
  const { currentIndex, onHide, showSlider, themePreviews } = props;

  const previews = themePreviews.map((themePreview, index) => {
    return (
      <div key={index} className="p-4">
        <h6>{themePreview.service_name}</h6>
        <img src={themePreview.preview_image} alt={themePreview.service_name} />
      </div>
    );
  });

  const settings = {
    infinite: false,
    slidesToShow: 1,
    centerPadding: '50px',
    adaptiveHeight: true,
    slidesToScroll: 1,
    speed: 500,
    initialSlide: currentIndex,
    prevArrow: <IconChevronLeft stroke={1.5} />,
    nextArrow: <IconChevronRight stroke={1.5} />,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div>
      <Modal className="theme-preview-modal" onHide={onHide} show={showSlider} size="lg">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="imagePreview">
          <Slider {...settings}>{previews}</Slider>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ImagePreviewSlider;
