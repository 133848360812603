import React from 'react';

import { escapeHtml } from 'helpers';
const moment = require('moment');

const LearnerCourseListItem = (props) => {
  const breakCourseID = (slug) => {
    if (slug.length > 30) {
      const [slug1, slug2, slug3] = slug.split('+');
      const lineBreak = slug2.length + slug3.length < 30 ? '' : '\n';
      return `${slug1}+\n${slug2}+${lineBreak}${slug3}`;
    }
    return slug;
  };
  const { course } = props;
  return (
    <tr>
      <td>
        <b className="mb-1 d-block">{escapeHtml(course.course_name)}</b>
        <small className="d-block">
          <b className="me-1">Course Key: </b> {breakCourseID(course.course_id)}
        </small>
      </td>
      <td className="text-center">{course.date_enrolled}</td>
      <td className="text-center">{course.is_active === true ? 'Active' : 'In-active'}</td>
      <td className="text-center">
        {course.progress_data.passed_timestamp
          ? moment(course.progress_data.passed_timestamp).format('YYYY-M-DD')
          : 'N/A'}
      </td>
      <td className="text-center">{course.progress_data.letter_grade ? course.progress_data.letter_grade : 'N/A'}</td>
      <td className="text-center">{course.progress_data.course_progress}%</td>
      <td className="text-center">{course.progress_data.total_progress_percent}%</td>
    </tr>
  );
};

export default LearnerCourseListItem;
