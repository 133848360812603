import React from 'react';
import { connect } from 'react-redux';

import { getOrganization } from 'selectors';
import CTABannerContent from './components/ctaBannerContent';

const moment = require('moment');

const CTABanner = (props) => {
  const currentDate = new moment();
  const expiryDate = new moment(props.renewal_expiry);
  const daysRemaining = expiryDate.diff(currentDate, 'days');

  return (
    <>
      <CTABannerContent daysRemaining={daysRemaining} />
    </>
  );
};

function mapStateToProps(state) {
  const { expected_billing, renewal_expiry, current_site_plan } = getOrganization(state);

  return {
    current_site_plan,
    expected_billing,
    renewal_expiry
  };
}

export default connect(mapStateToProps)(CTABanner);
