import React, { useState } from 'react';
import './marketingKeyInput.css';
import { Row, Col, Form, Button } from 'react-bootstrap';

import { WP_GA_ID_PLACEHOLDER, WP_GTM_ID_PLACEHOLDER } from '../constants';
import { updateWpSiteConfig } from 'services/siteConfiguration';
import { messageNotification } from 'helpers';

const MarketingKeyInput = () => {
  const [wpGa, setWpGa] = useState('');
  const [wpGtm, setWpGtm] = useState('');

  const updateKey = () => {
    if (wpGa === '' && wpGtm === '') {
      messageNotification('danger', 'Invalid Input', 'You must fill atleast one field for Marketing keys');
      return;
    }

    const wp_data = {
      ...(wpGa && { site_ga_id: wpGa }),
      ...(wpGtm && { site_gtm_id: wpGtm })
    };
    updateWpSiteConfig(wp_data)
      .then((res) =>
        messageNotification(
          'success',
          'Key updated',
          `The following keys have been updated:
        ${wpGtm && 'GTM_ID: ' + wpGtm}
        ${wpGa && 'GA_ID: ' + wpGa}`
        )
      )
      .catch((error) => {
        messageNotification('danger', 'Error', `${error}`);
      });
  };

  return (
    <div className="integration-holder mb-4">
      <div className="subheader-holder mb-4">
        <h4>Google analytics for marketing site</h4>
        <p>Add Google Analytics for Property (GA4) and Google Tag Manger (GTM) keys for marketing site</p>
      </div>
      <Row>
        <Col xs="12" lg="6">
          <Form.Group className="mb-3">
            <Form.Label>GTM ID</Form.Label>
            <Form.Control
              placeholder={WP_GTM_ID_PLACEHOLDER}
              value={wpGtm}
              onChange={(event) => setWpGtm(event.target.value)}
            />
          </Form.Group>
        </Col>
        <Col xs="12" lg="6">
          <Form.Group className="mb-3">
            <Form.Label>GA4 ID</Form.Label>
            <Form.Control
              placeholder={WP_GA_ID_PLACEHOLDER}
              value={wpGa}
              onChange={(event) => setWpGa(event.target.value)}
            />
          </Form.Group>
        </Col>
        <Col className="btn-holder justify-content-end d-flex">
          <Button onClick={updateKey}>Save</Button>
        </Col>
      </Row>
    </div>
  );
};

export default MarketingKeyInput;
