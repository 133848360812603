import { useEffect, useState } from 'react';

import { getParamFromURL, getWordpressBaseUrl, messageNotification } from 'helpers';
import { UpdateCookie } from 'services/cookie';
import { useInterval } from 'helpers/hooks';
import { pollingService } from 'services/polling';

const POLLING_DELAY = 10000;
const MAX_DELAY_COUNT = 1 * 60;

const Polling = () => {
  let [delay, setDelay] = useState(null);

  useInterval(async () => {
    try {
      const response = await pollingService.checkSiteCreation();
      if (response.status === 200) {
        window.location.replace('/panel/dashboard');
        localStorage.removeItem('domain');
        localStorage.removeItem('newSiteUrl');
        await UpdateCookie();
        setDelay(null);
      }
    } catch (err) {
      const currentDelay = Number.parseInt(localStorage.getItem('CURRENT_DELAY')) || 0;
      localStorage.setItem('CURRENT_DELAY', Number.parseInt(localStorage.getItem('CURRENT_DELAY') || 0) + 10);
      if (currentDelay >= MAX_DELAY_COUNT) {
        messageNotification(
          'danger',
          'Site failure',
          'There has been problem while creating the site. contact edly support'
        );
        setTimeout(async () => {
          localStorage.removeItem('domain');
          localStorage.removeItem('newSiteUrl');
          await UpdateCookie();
          window.location.replace('/panel/dashboard');
          setDelay(null);
        }, 5000);
      }
    }
  }, delay);

  useEffect(() => {
    const stripeRedirect = getParamFromURL('stripe_redirect');
    if (stripeRedirect && stripeRedirect.length) {
      localStorage.setItem('domain', stripeRedirect);
      let parts = `${getWordpressBaseUrl()}`.split('.');
      parts[0] = stripeRedirect;
      localStorage.setItem('newSiteUrl', `https://${parts.join('.')}`);
      localStorage.setItem('CURRENT_DELAY', 0);
      window.location.replace('/panel/dashboard');
      setDelay(POLLING_DELAY);
      return;
    }
    let domain = localStorage.getItem('domain');
    if (domain) {
      setDelay(POLLING_DELAY);
    }
  }, []);

  return <></>;
};

export default Polling;
