import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { Col, Form, Row, Button } from 'react-bootstrap';

import { settingsActions } from 'pages/settings/containers/settingsContainer/actions';
import { getUserSettings } from 'selectors';
import { messageNotification, yearsList } from 'helpers';
import 'pages/settings/index.scss';

const current_year = new Date().getFullYear();

const AccountSettings = (props) => {
  const { name, username, email, profile_image, old_email, year_of_birth, password_reset, is_social_user } = props;
  const [inputs, setInputs] = useState({
    name: '',
    username: '',
    email: '',
    profile_image: '',
    old_email: '',
    year_of_birth: ''
  });
  const [profileImageFile, setProfileImageFile] = useState('');
  const [image_disabled, setImageDisabled] = useState(false);

  useEffect(() => {
    setInputs({
      name: name,
      username: username,
      email: email,
      profile_image: profile_image,
      old_email: old_email,
      year_of_birth: year_of_birth
    });
    if (year_of_birth === null || current_year - year_of_birth <= 13) {
      setImageDisabled(true);
    } else {
      setImageDisabled(false);
    }
  }, [name, username, email, profile_image, old_email, year_of_birth]);

  const updateAccountSettings = (event) => {
    event.preventDefault();
    if (profileImageFile) {
      props.dispatch(settingsActions.updateUserProfileImage(inputs, profileImageFile));
    }
    props.dispatch(settingsActions.updateSettings(inputs));
  };

  const handleInputChange = (event) => {
    event.persist();
    setInputs((inputs) => ({ ...inputs, [event.target.name]: event.target.value }));
  };

  const imageChange = (event) => {
    let profile_image = event.target.files[0];
    if (profile_image.size < 1024 * 1024) {
      profile_image = URL.createObjectURL(profile_image);
      setInputs((inputs) => ({ ...inputs, profile_image: profile_image }));
      setProfileImageFile(event.target.files[0]);
    } else {
      messageNotification(
        'danger',
        'Profile Image Update Failed',
        "Please select a file that isn't exceeding 1MB limit"
      );
    }
  };

  const handlePasswordReset = (event) => {
    if (inputs.email) {
      props.dispatch(settingsActions.resetUserPassword(inputs));
    } else {
      messageNotification('danger', 'Accounts Settings Update Failed', 'Email Address is required.');
    }
  };

  const years = yearsList(current_year - 119, current_year).map((year, index) => {
    return (
      <option key={index} value={year}>
        {year}
      </option>
    );
  });

  return (
    <Fragment>
      <form name="accounts_settings" onSubmit={updateAccountSettings} className="profile-form">
        <Row>
          <Col xs="12" md="6">
            <Form.Group className="mb-3">
              <Form.Label htmlFor="name">Name</Form.Label>
              <Form.Control name="name" type="text" id="name" value={inputs.name} onChange={handleInputChange} />
            </Form.Group>
          </Col>
          <Col xs="12" md="6">
            <Form.Group className="mb-3">
              <Form.Label htmlFor="year_of_birth">Year of Birth</Form.Label>
              <Form.Select
                id="year_of_birth"
                name="year_of_birth"
                value={inputs.year_of_birth}
                onChange={handleInputChange}
              >
                <option value="">--</option>
                {years}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>

        <Form.Group className="mb-3">
          <Form.Label>Profile Image</Form.Label>
          <Row className="d-flex align-items-center">
            <Col sm="auto">
              <div className="profile-image-frame mb-3 mb-lg-0">
                <img src={inputs.profile_image} alt="Profile" className="profile-image" />
              </div>
            </Col>
            <Col sm="auto">
              <small className="d-block">1. You must be over 13 years to set your profile image</small>
              <small>2. You can attach gif, jpg, png, with a maximum size of 1MB</small>
            </Col>
          </Row>
          <div className="custom-file dragable">
            <input
              type="file"
              id="profile_image"
              name="profile_image"
              onChange={imageChange}
              disabled={image_disabled}
              className="form-control"
            />
            <label htmlFor="profile_image" className="custom-file-label mt-4 mb-2">
              Drop file here or click to upload
            </label>
          </div>
        </Form.Group>
        <Row className="d-flex align-items-center">
          <Col xs="12" md="6">
            <Form.Group className="mb-3">
              <Form.Label htmlFor="username">
                Username <span className="required">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="username"
                id="username"
                disabled
                required
                value={inputs.username}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Col>
          <Col xs="12" md="6">
            <Form.Group className="mb-3">
              <Form.Label htmlFor="email">
                Email <span className="required">*</span>
              </Form.Label>
              <Form.Control
                type="email"
                name="email"
                id="email"
                value={inputs.email}
                onChange={handleInputChange}
                required
                disabled
              />
            </Form.Group>
          </Col>
        </Row>

        <Form.Group className="mb-3">
          <Form.Label>Password</Form.Label>
          <Row className="d-flex align-items-center">
            {!is_social_user && (
              <Col sm="auto">
                <Button
                  variant="primary"
                  name="password-reset"
                  value="Reset Your Password"
                  onClick={handlePasswordReset}
                  disabled={!password_reset}
                >
                  Reset Your Password
                </Button>
              </Col>
            )}
            <Col sm="auto">
              <small>(Check your email account for instructions to reset your password)</small>
            </Col>
          </Row>
        </Form.Group>
        <Row className="mt-4">
          <Col sm="12" className="d-flex justify-content-end">
            <Button type="submit" variant="primary">
              Update
            </Button>
          </Col>
        </Row>
      </form>
    </Fragment>
  );
};

function mapStateToProps(state) {
  let user_settings = getUserSettings(state);
  if (user_settings.length < 1) {
    return {};
  }
  const {
    name,
    username,
    email,
    profile_image,
    old_email,
    year_of_birth,
    password_reset,
    is_social_user
  } = user_settings;

  return {
    name,
    username,
    email,
    profile_image,
    old_email,
    year_of_birth,
    password_reset,
    is_social_user
  };
}

const accountSettings = connect(mapStateToProps)(AccountSettings);
export { accountSettings as AccountSettings };
