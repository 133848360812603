import React from 'react';
import { Card, CardBody } from 'react-bootstrap';
import { IconInfoCircle } from '@tabler/icons-react';

import 'components/footer/index.scss';

const StatsBox = (props) => {
  const { value, label, description } = props;
  return (
    <Card className="stats-box">
      <CardBody>
        <Card.Title className="mb-4">{value}</Card.Title>
        <Card.Text className="position-relative position-tooltip">
          <small>{label}</small>
          <span className="tool-tip ms-2">
            <IconInfoCircle stroke={1.5} />
            <span className="tip-content">{description}</span>
          </span>
        </Card.Text>
      </CardBody>
    </Card>
  );
};

export default StatsBox;
