import { CardNumberElement } from '@stripe/react-stripe-js';

import { PANEL_API_ENDPOINTS } from 'config';
import { getPanelBackendUrl, getPanelOrganizationId } from 'helpers';

const axios = require('axios');

const fetchProductsList = () => {
  const url = `${getPanelBackendUrl()}${PANEL_API_ENDPOINTS.STRIPE_PRODUCTS_LIST_API}`;
  const requestOptions = {
    crossDomain: true,
    withCredentials: true,
    method: 'GET',
    url
  };
  return axios(requestOptions);
};

const createCheckoutSession = (payment_id) => {
  const url = `${getPanelBackendUrl()}${PANEL_API_ENDPOINTS.STRIPE_CHECKOUT_SESSION}`;
  const requestOptions = {
    crossDomain: true,
    withCredentials: true,
    method: 'POST',
    url,
    headers: {
      'X-CSRFToken': localStorage.getItem('panel_csrf_token')
    },
    data: {
      payment_id: payment_id
    }
  };
  return axios(requestOptions);
};

const createOneTimePaymentCheckoutSession = (payload) => {
  const url = `${getPanelBackendUrl()}${PANEL_API_ENDPOINTS.STRIPE_ONETIME_PAYMENT_CHECKOUT_SESSION}`;
  const requestOptions = {
    crossDomain: true,
    withCredentials: true,
    method: 'POST',
    url,
    headers: {
      'X-CSRFToken': localStorage.getItem('panel_csrf_token')
    },
    data: payload
  };
  return axios(requestOptions);
};

const setPaymentMethod = (paymentMethodId) => {
  const url = `${getPanelBackendUrl()}${PANEL_API_ENDPOINTS.PAYMENT_METHOD_API}`;
  const requestOptions = {
    crossDomain: true,
    withCredentials: true,
    method: 'POST',
    url,
    headers: {
      'X-CSRFToken': localStorage.getItem('panel_csrf_token')
    },
    data: {
      payment_method_id: paymentMethodId
    }
  };
  return axios(requestOptions);
};

const addCardInStripe = async ({ stripe, elements }) => {
  const payload = await stripe.createPaymentMethod({
    type: 'card',
    card: elements.getElement(CardNumberElement)
  });
  return payload;
};

const fetchPaymentMethod = () => {
  const url = `${getPanelBackendUrl()}${PANEL_API_ENDPOINTS.PAYMENT_METHOD_API}`;
  const requestOptions = {
    crossDomain: true,
    withCredentials: true,
    method: 'GET',
    url
  };
  return axios(requestOptions);
};

const updateOrgTermsConditions = (accept_terms_conditions, inputs) => {
  const url = `${getPanelBackendUrl()}${PANEL_API_ENDPOINTS.ORGANIZATIONS_API}${getPanelOrganizationId()}/`;
  const requestOptions = {
    crossDomain: true,
    withCredentials: true,
    method: 'PATCH',
    url,
    headers: {
      'X-CSRFToken': localStorage.getItem('panel_csrf_token')
    },
    data: {
      accept_terms_conditions: accept_terms_conditions,
      ...inputs
    }
  };
  return axios(requestOptions);
};

function checkSubDomainAvailability(payload) {
  const url = `${getPanelBackendUrl()}${PANEL_API_ENDPOINTS.VALIDATE_CREATE_SITE}`;
  payload['csrfmiddlewaretoken'] = localStorage.getItem('panel_csrf_token');
  const requestOptions = {
    crossDomain: true,
    withCredentials: true,
    method: 'POST',
    url,
    headers: {
      'X-CSRFToken': localStorage.getItem('panel_csrf_token')
    },
    data: payload
  };
  return axios(requestOptions);
}

export const pricingService = {
  setPaymentMethod,
  addCardInStripe,
  fetchPaymentMethod,
  fetchProductsList,
  createCheckoutSession,
  updateOrgTermsConditions,
  createOneTimePaymentCheckoutSession,
  checkSubDomainAvailability
};
