import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Pagination from 'react-js-pagination';

import { PAGE_SIZE } from 'config';
import { getSiteNotifications } from 'selectors';
import NotificationsCenterListing from 'pages/notifications/containers/notifications/components/notificationsCenterListings';
import { notificationActions } from 'pages/notifications/containers/notifications/actions';

const NotificationsCenter = (props) => {
  const { count, current_page, notification_center, updateNotificationStatus, fetchNotifications } = props;

  return (
    <div>
      <h1>Notifications</h1>
      <NotificationsCenterListing
        current_page={current_page}
        notification_center={notification_center}
        updateNotificationStatus={updateNotificationStatus}
      />
      <div className={`notification-footer ${notification_center.length > 0 ? '' : 'd-none'}`}>
        <Pagination
          activePage={current_page}
          itemsCountPerPage={PAGE_SIZE}
          totalItemsCount={count}
          pageRangeDisplayed={5}
          onChange={fetchNotifications}
        />
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { notification_center, count, current_page } = getSiteNotifications(state);
  return {
    notification_center,
    count,
    current_page
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(notificationActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsCenter);
