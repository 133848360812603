import React from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { useState } from 'react';
import { paidBenefitList } from '../constants/benefitsContent';
import SelectBox from './selectBox';
import { RequestFeatureService } from 'services/requestFeature';
import { messageNotification } from 'helpers';

function AddOnModel({ show, handleClose, optionSelected, setSelectedOptions }) {
  const [brief, setBrief] = useState('');

  const handleSelectChange = (selected) => {
    setSelectedOptions(selected);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const selectedOption = optionSelected.map((item) => item.label);
    RequestFeatureService.postRequestFeature({
      featureRequest: selectedOption,
      brief: brief
    })
      .then((res) => {
        messageNotification(
          'success',
          'Request Sent',
          'your request has been recieved, our customer success team will contact with You in 24 hours.'
        );
      })
      .catch((err) => {
        messageNotification('danger', 'Error', `${err}`);
      })
      .finally(() => {
        setBrief('');
        handleClose();
      });
  };

  const isDisable = !Boolean(brief?.length && optionSelected?.length);
  return (
    <Modal
      show={show}
      onHide={() => {
        setBrief('');
        setSelectedOptions([]);
        handleClose();
      }}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Request Add Ons</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmit}>
          <Form.Group className="mb-4">
            <Form.Label>Select Add Ons</Form.Label>
            <SelectBox options={paidBenefitList} optionSelected={optionSelected} handleChange={handleSelectChange} />
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label>Brief</Form.Label>
            <Form.Control
              as="textarea"
              name="brief"
              value={brief}
              onChange={(event) => {
                setBrief(event.target.value);
              }}
              placeholder="Please explain your requirements..."
              style={{ minHeight: '150px' }}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" disabled={isDisable} onClick={onSubmit}>
          Send Request
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddOnModel;
