import React from 'react';
import { connect } from 'react-redux';
import { Tooltip, OverlayTrigger, Card, CardBody } from 'react-bootstrap';
import { IconInfoCircle } from '@tabler/icons-react';
import PercentChangeBox from 'components/analytics/percentChangeBox';
import {
  getTotalSiteLearnersDataLabels,
  getTotalSiteLearnersDataValues,
  getCurrentTotalSiteLearnersTotalCount,
  getCurrentTotalSiteLearnersPercentageChange
} from 'selectors';

const renderTooltip = (props) => (
  <Tooltip id="total-learners" {...props}>
    Number of unique learners who have registered on the platform.
  </Tooltip>
);

const TotalLearners = (props) => {
  const { percent_change, total_count } = props;

  return (
    <Card className="stats-box mb-4">
      <CardBody>
        <div className="d-flex align-items-center justify-content-between mb-4">
          <Card.Title>{total_count}</Card.Title>
          <PercentChangeBox percent_change={percent_change} />
        </div>
        <Card.Text>
          Total learners
          <OverlayTrigger placement="bottom" overlay={renderTooltip}>
            <span id="total-learners" className="ms-2">
              <IconInfoCircle stroke={1.5} />
            </span>
          </OverlayTrigger>
        </Card.Text>
      </CardBody>
    </Card>
  );
};

function mapStateToProps(state) {
  const data_labels = getTotalSiteLearnersDataLabels(state);
  const data_values = getTotalSiteLearnersDataValues(state);
  const percent_change = getCurrentTotalSiteLearnersPercentageChange(state);
  const total_count = getCurrentTotalSiteLearnersTotalCount(state);
  return {
    data_labels,
    data_values,
    percent_change,
    total_count
  };
}

export default connect(mapStateToProps)(TotalLearners);
