import React from 'react';
import { connect } from 'react-redux';
import { Tooltip, OverlayTrigger, Card, CardBody } from 'react-bootstrap';
import { IconInfoCircle } from '@tabler/icons-react';
import PercentChangeBox from 'components/analytics/percentChangeBox';
import { getCurrentTotalSiteStaffUsersTotalCount, getCurrentTotalSiteStaffUsersPercentageChange } from 'selectors';

const renderTooltip = (props) => (
  <Tooltip id="staff-users" {...props}>
    Total number of unique staff users registered on your platform.
  </Tooltip>
);

const TotalStaffUsers = (props) => {
  const { percent_change, total_count } = props;

  return (
    <Card className="stats-box">
      <CardBody>
        <div className="d-flex align-items-center justify-content-between mb-4">
          <Card.Title>{total_count}</Card.Title>
          <PercentChangeBox percent_change={percent_change} />
        </div>
        <Card.Text>
          Staff Users
          <OverlayTrigger placement="bottom" overlay={renderTooltip}>
            <span id="staff-users" className="ms-2">
              <IconInfoCircle stroke={1.5} />
            </span>
          </OverlayTrigger>
        </Card.Text>
      </CardBody>
    </Card>
  );
};

function mapStateToProps(state) {
  const percent_change = getCurrentTotalSiteStaffUsersPercentageChange(state);
  const total_count = getCurrentTotalSiteStaffUsersTotalCount(state);

  return {
    percent_change,
    total_count
  };
}

export default connect(mapStateToProps)(TotalStaffUsers);
