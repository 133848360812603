import React, { Fragment, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { Accordion, Card, ListGroup, Form } from 'react-bootstrap';
import { IconCheck } from '@tabler/icons-react';
import { usersActions } from 'pages/users/containers/usersContainer/actions';

const PaginationDropdown = (props) => {
  const { setPageSize, current_page, search, dispatch, roles, orderBy } = props;
  const [paginationSize, setPaginationSize] = useState(10);

  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const handlePageSizeChange = (e) => {
    setPaginationSize(e);
    setIsFilterOpen(false);
    setPageSize(page_sizes[e]);
    dispatch(
      usersActions.fetchUsers({
        page_size: page_sizes[e],
        page_number: current_page,
        searchText: search,
        roles: roles,
        order_by: orderBy
      })
    );
  };

  const page_sizes = {
    page_size_10: 10,
    page_size_25: 25,
    page_size_50: 50,
    page_size_100: 100
  };

  const filterListItems = Object.keys(page_sizes).map((key, index) => {
    return (
      <ListGroup.Item
        action
        className={paginationSize === key ? 'active' : ''}
        onClick={() => handlePageSizeChange(key)}
        key={key}
        style={{ fontSize: '0.75rem' }}
      >
        {page_sizes[key]}
        {paginationSize === key && <IconCheck stroke={1.5} />}
      </ListGroup.Item>
    );
  });

  return (
    <Fragment>
      <div className="pagination-container">
        <Form.Label className="mb-0" style={{ fontSize: '0.875rem' }}>
          Show
        </Form.Label>
        <Accordion className="pagination-dropdown">
          <Card>
            <Card.Header onClick={() => setIsFilterOpen(!isFilterOpen)} className={isFilterOpen ? 'open' : ''}>
              {page_sizes[paginationSize] ? page_sizes[paginationSize] : 10}{' '}
              <FontAwesomeIcon icon={isFilterOpen ? faChevronUp : faChevronDown} />
            </Card.Header>
            <Accordion.Collapse className={isFilterOpen ? 'show' : 'd-none'} style={{ minWidth: '100px' }}>
              <Card.Body>
                <ListGroup>{filterListItems}</ListGroup>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>
    </Fragment>
  );
};

export default PaginationDropdown;
