import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { userSiteActions } from 'pages/dashboard/containers/sites/actions/userSites';
import { userSettingsService } from 'services/settings';
import { setupOrganizationLocalStorage } from 'helpers';
import { settingsDispatches } from 'pages/settings/containers/settingsContainer/constants';
import { checkUserPanelAccess, setupCsrfToken } from 'helpers/dashboard';
import { Button } from 'react-bootstrap';
import { getOrganization } from 'selectors';
import { BILLING_PAGE } from 'components/ctaBanner/constants';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const UserSite = (props) => {
  const { site, userSelectedSite, handleSetLoading, dispatch, currentPlan } = props;
  const history = useHistory();
  const selected = userSelectedSite.includes(site.slug);

  const handleNavigate = async (event) => {
    event.stopPropagation();
    handleSetLoading(true);
    localStorage.setItem('organization_url', site.panel_base_url);
    let organization = await userSettingsService.fetchOrganizations();
    const { show_user_guide, ...rest } = organization.data.results[0];
    organization.data.results[0] = rest;
    setupOrganizationLocalStorage(dispatch, organization, false);
    setupCsrfToken();
    await Promise.resolve(dispatch(settingsDispatches.fetchOrganizationSuccess(organization))).then(() => {
      dispatch(settingsDispatches.fetchUsernameSuccess(organization));
    });
    dispatch(userSiteActions.setUserCurrentSites({ data: site }));
    handleSetLoading(false);
    if (site.user_panel_role === 'panel_user') {
      history.push('settings/profile');
    } else {
      history.push('insights/courses');
    }
  };

  const checkDeactivatedSite = site.slug !== 'all' && ['trial', 'deactivated'].includes(currentPlan);

  const handleSelectedSites = (event) => {
    if (checkUserPanelAccess(site.user_panel_role)) {
      dispatch(
        userSiteActions.UpdateSelectedSites({
          removedSelectedSite: userSelectedSite.includes(site.slug),
          data: site.slug
        })
      );
    }
  };

  return (
    <div className={`slide-card ${selected ? 'card-selected' : ''}`} onClick={handleSelectedSites}>
      <div className="card-text">
        <div className="site-name">
          <div>
            <h6 style={{ margin: `${checkDeactivatedSite ? '0px' : ''}` }}>
              {site.slug}
              {site.slug !== 'all' && ['deactivated'].includes(currentPlan) && (
                <small className="d-block text-error pt-2" style={{ fontSize: '0.75rem' }}>
                  limited access
                </small>
              )}
            </h6>
          </div>
        </div>
        {site.slug !== 'all' &&
          (!['deactivated'].includes(currentPlan) ? (
            <Button className="p-0" variant="link" onClick={handleNavigate}>
              view site panel
            </Button>
          ) : (
            <small className="pt-2 text-error d-block">
              You site with complete data will be removed in 10 days,{' '}
              <Link className="alert-link mt-2 mt-lg-0" to={BILLING_PAGE}>
                Activate billing{' '}
              </Link>
              to save site
            </small>
          ))}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { current_site_plan } = getOrganization(state);

  return {
    userSelectedSite: state.USER_SITES.selected_sites,
    currentPlan: current_site_plan
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

const connectedUserSite = connect(mapStateToProps, mapDispatchToProps)(UserSite);
export default connectedUserSite;
