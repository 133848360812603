import React from 'react';
import { IconFileExport } from '@tabler/icons-react';

import { coursesAnalyticsActions } from 'pages/insights/containers/coursesAnalyticsContainer/actions';
import { Button } from 'react-bootstrap';

const DownloadCoursesAnalytics = (props) => {
  const handleFilterChange = () => {
    props.dispatch(coursesAnalyticsActions.fetchInsightsCoursesCSV());
  };

  return (
    <div id="export_button">
      <Button variant="outline-primary" onClick={handleFilterChange}>
        Export <span className="d-none-md">CSV</span>
        <IconFileExport className="ms-2" stroke={1.5} />
      </Button>
    </div>
  );
};

export default DownloadCoursesAnalytics;
