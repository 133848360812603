import React from 'react';
import { connect } from 'react-redux';

import { Line } from 'react-chartjs-2';
import { graphProperties } from 'helpers/constants';
import { getTheme } from 'selectors';

const LineChart = (props) => {
  const { title, labels, values, height, width, theme } = props;

  const data = {
    labels: labels,
    datasets: [
      {
        ...graphProperties[theme],
        label: title,
        data: values
      }
    ]
  };

  const options = {
    layout: {
      padding: {
        top: 5,
        right: 5
      }
    },
    legend: {
      display: false
    },
    scales: {
      yAxes: [
        {
          ticks: {
            min: 0,
            beginAtZero: true,
            fontColor: theme === 'light' ? '#252525' : '#fff',
            callback: function(value, index, values) {
              if (Math.floor(value) === value) {
                return value;
              }
            }
          }
        }
      ],
      xAxes: [
        {
          ticks: {
            fontColor: theme === 'light' ? '#252525' : '#fff'
          }
        }
      ]
    }
  };

  return <Line data={data} height={height} width={width} options={options} />;
};

function mapStateToProps(state) {
  const { theme } = getTheme(state);
  return {
    theme
  };
}

export default connect(mapStateToProps, null)(LineChart);
