import { usersConstants } from 'pages/users/containers/usersContainer/constants';

const moment = require('moment');

const initialStateFetchUsers = {
  num_pages: 1,
  current_page: 1,
  count: 0,
  roles: {
    edly_admin: false,
    learner: false,
    panel_admin: false,
    panel_restricted: false,
    panel_user: false,
    staff: false,
    course_creator: false,
    subscriber: false,
    super_admin: false
  },
  search: '',
  users_info: [],
  is_loading: false
};

const initialStateExportUsers = {
  users_data: []
};

const initialStateValidateUser = {
  validation_messages: {}
};

const initialStateBulkUsers = {
  response: ''
};

const initialStateUserProfile = {
  username: '',
  name: '',
  email: '',
  is_staff: false,
  is_active: true,
  is_blocked: false,
  is_superuser: false,
  lms_role: 'learner',
  wordpress_role: 'subscriber',
  panel_role: 'panel_restricted'
};

export function fetchUsers(state = initialStateFetchUsers, action) {
  switch (action.type) {
    case usersConstants.SET_FETCH_USERS_LOADING:
      return {
        ...state,
        is_loading: true
      };

    case usersConstants.FETCH_USERS_SUCCESS:
      let users_info = [];
      action.payload.results.forEach((user) => {
        users_info.push({
          id: user.id,
          username: user.username,
          name: user.profile ? user.profile.name : '',
          email: user.email,
          is_staff: user.is_staff,
          is_active: user.is_active,
          is_blocked: user.edly_profile ? user.edly_profile.is_blocked : false,
          is_superuser: user.is_superuser,
          lms_role: user.roles.lms_role,
          wordpress_role: user.roles.wordpress_role,
          panel_role: user.roles.panel_role,
          is_social_user: user?.is_social_user,
          is_retired: user.is_retired || false
        });
      });

      return {
        ...state,
        is_loading: false,
        num_pages: action.payload.num_pages,
        current_page: action.payload.current_page,
        count: action.payload.count,
        roles: action.roles || initialStateFetchUsers.roles,
        search: action.search || initialStateFetchUsers.search,
        users_info: users_info
      };
    case usersConstants.FETCH_USERS_FAILURE:
      return {
        ...state,
        is_loading: false,
        error: action.payload
      };
    default:
      return state;
  }
}

export function userProfile(state = initialStateUserProfile, action) {
  switch (action.type) {
    case usersConstants.ADD_USER_SUCCESS:
      return {
        ...state,
        username: action.payload.username,
        name: action.payload.profile ? action.payload.profile.name : '',
        email: action.payload.email,
        is_staff: action.payload.is_staff,
        is_active: action.payload.is_active,
        is_superuser: action.payload.is_superuser,
        lms_role: action.payload.roles.lms_role,
        wordpress_role: action.payload.roles.wordpress_role,
        panel_role: action.payload.roles.panel_role
      };
    case usersConstants.ADD_USER_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case usersConstants.UPDATE_USER_SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    case usersConstants.UPDATE_USER_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case usersConstants.ADD_WP_USER_SUCCESS:
      return {
        ...state
      };
    case usersConstants.ADD_WP_USER_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case usersConstants.UPDATE_WP_USER_SUCCESS:
      return {
        ...state
      };
    case usersConstants.UPDATE_WP_USER_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case usersConstants.UPDATE_WP_USER_STATUS_SUCCESS:
      return {
        ...state
      };
    case usersConstants.UPDATE_WP_USER_STATUS_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case usersConstants.DEACTIVATE_WP_USERS_SUCCESS:
      return {
        ...state
      };
    case usersConstants.DEACTIVATE_WP_USERS_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
}

export function exportUsersData(state = initialStateExportUsers, action) {
  switch (action.type) {
    case usersConstants.EXPORT_USERS_SUCCESS:
      let users_data = [
        [
          'Username',
          'Name',
          'Email',
          'Status',
          'Is Blocked',
          'Account Created',
          'Last Login',
          'Last Course Activity',
          'LMS Role',
          'Discovery Role',
          'Panel Role'
        ]
      ];
      action.payload.data.forEach((user) => {
        users_data.push([
          user.username,
          user.profile ? user.profile.name : '',
          user.email,
          user.is_active ? 'Active' : 'InActive',
          user.edly_profile.is_blocked ? 'Yes' : 'No',
          user.date_joined ? moment(user.date_joined).format('YYYY-M-DD') : 'N/A',
          user.last_login ? moment(user.last_login).format('YYYY-M-DD') : 'N/A',
          user.edly_profile.course_activity_date
            ? moment(user.edly_profile.course_activity_date).format('YYYY-M-DD')
            : 'N/A',
          user.roles.lms_role === 'super_admin' ? 'site admin' : user.roles.lms_role.replace('_', ' '),
          user.roles.wordpress_role.replace('_', ' '),
          user.roles.panel_role === 'insights_admin' ? 'insights viewer' : user.roles.panel_role.replace('_', ' ')
        ]);
      });

      return {
        ...state,
        users_data: users_data
      };
    case usersConstants.EXPORT_USERS_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
}

export function userValidation(state = initialStateValidateUser, action) {
  switch (action.type) {
    case usersConstants.VALIDATE_USER_SUCCESS:
      return {
        ...state,
        validation_messages: action.payload
      };
    case usersConstants.VALIDATE_USER_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
}

export function bulkUsersUpload(state = initialStateBulkUsers, action) {
  switch (action.type) {
    case usersConstants.BULK_USERS_STATUS_SUCCESS:
      return {
        ...state,
        response: action.payload
      };
    case usersConstants.BULK_USERS_STATUS_FAILURE:
      return {
        ...state,
        response: action.payload
      };
    default:
      return state;
  }
}
