import { useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { connect } from 'react-redux';

import { graphProperties } from 'helpers/constants';
import { getTheme } from 'selectors';

const BarChart = (props) => {
  const { title, labels, theme, values } = props;
  const data = {
    labels: labels,
    datasets: [
      {
        ...graphProperties[theme],
        label: title,
        data: values
      }
    ]
  };

  const options = {
    layout: {
      padding: {
        top: 5
      }
    },
    legend: {
      display: false
    },
    scales: {
      yAxes: [
        {
          ticks: {
            min: 0,
            beginAtZero: true,
            fontColor: theme === 'light' ? '#252525' : '#fff',
            callback: function(value, index, values) {
              if (Math.floor(value) === value) {
                return value;
              }
            }
          }
        }
      ],
      xAxes: [
        {
          ticks: {
            fontColor: theme === 'light' ? '#252525' : '#fff'
          }
        }
      ]
    }
  };

  return <Bar data={data} options={options} />;
};

function mapStateToProps(state) {
  const { theme } = getTheme(state);
  return {
    theme
  };
}

export default connect(mapStateToProps, null)(BarChart);
