import { useState, useEffect } from 'react';
import SearchInput from 'components/analytics/searchInput';
import LtiConfigurationForm from './ltiConfigurationForm';
import LtiIntegrationList from './ltiConfigurationList';
import InsightsLoader from 'components/loaders/InsightsLoader';
import { connect } from 'react-redux';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { IconSearch } from '@tabler/icons-react';

const LtiIntegration = ({ ltiConfigurations, isLoading }) => {
  const [isFormOpen, setFormIsOpen] = useState(false);
  const [searchText, setSearchText] = useState(null);
  const [configurations, setConfigurations] = useState(ltiConfigurations);

  useEffect(() => {
    if (searchText && searchText.length > 0) {
      setConfigurations(
        ltiConfigurations.filter((item) => {
          if (item.course_key) {
            return item.course_key.toLowerCase().includes(searchText.toLowerCase());
          }
          return false;
        })
      );
    } else {
      setConfigurations(ltiConfigurations);
    }
  }, [searchText, ltiConfigurations]);

  const hideModal = () => setFormIsOpen(false);

  return (
    <>
      <Row className="mb-4">
        <Col xs="12" lg="5">
          <h4>LTI Integration</h4>
        </Col>
        <Col
          xs="12"
          lg="7"
          className="lti-head d-flex flex-column-reverse flex-md-row align-items-center justify-content-end"
        >
          <div className="sites-search-holder mt-3 mt-md-0">
            <IconSearch id="search_course_id" stroke={1.5} className="ico-search" />
            <Form.Group>
              <Form.Control
                type="text"
                placeholder="Search Course ID"
                id="search_course_id"
                value={searchText}
                onChange={(event) => setSearchText(event.target.value)}
              />
            </Form.Group>
          </div>

          <Button className="ms-2" onClick={() => setFormIsOpen(true)}>
            ADD NEW
          </Button>
        </Col>
      </Row>
      {isLoading ? <InsightsLoader /> : <LtiIntegrationList ltiConfigurations={configurations} />}
      <LtiConfigurationForm isFormOpen={isFormOpen} hideModal={hideModal} />
    </>
  );
};

function mapStateToProps(state) {
  const { ltiConfigurations, isLoading } = state.LTI_CONFIGURATIONS;
  return {
    ltiConfigurations,
    isLoading
  };
}
export default connect(mapStateToProps)(LtiIntegration);
