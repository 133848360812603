import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getOrganization } from 'selectors';
import { mausActions } from 'pages/dashboard/containers/monthlyActiveUsers/actions/maus';
import { pricingActions } from 'pages/pricing/containers/pricingContainer/actions/pricing';
import 'pages/pricing/index.scss';
import FlexiblePricing from './components/flexiblePricing';

const PricingCenter = (props) => {
  const { fetchPaymentMethod, organization } = props;
  useEffect(() => {
    if (organization.current_site_plan !== 'trial') {
      fetchPaymentMethod();
    }
  }, [fetchPaymentMethod, organization]);

  return <FlexiblePricing />;
};

function mapStateToProps(state) {
  const organization = getOrganization(state);
  return { organization };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators({ ...pricingActions, ...mausActions }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PricingCenter);
