import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { IconInfoTriangle } from '@tabler/icons-react';

const DataBackupTable = ({ data_backup }) => {
  const backup_exists = data_backup.length !== 0;
  return (
    <div>
      {!backup_exists ? (
        <p className="footer-note">
          <IconInfoTriangle stroke={1.5} />
          <span>No backups available</span>
        </p>
      ) : (
        <Row className="d-flex align-items-center ">
          <Col xs="12">
            <h4>Backup File</h4>
          </Col>
          <Col xs="12" className="pt-2 pb-2 d-flex justify-content-between align-items-center has-border-bottom">
            Users CSV
            <a
              className="btn btn-link ms-2"
              href={`${data_backup[0].user_data_csv_url}`}
              target="_blank"
              rel="noreferrer"
            >
              Download
            </a>
          </Col>
          <Col xs="12" className="pt-2 pb-2 d-flex justify-content-between align-items-center has-border-bottom">
            Courses CSV
            <a
              className="btn btn-link ms-2"
              href={`${data_backup[0].course_data_csv_url}`}
              target="_blank"
              rel="noreferrer"
            >
              Download
            </a>
          </Col>
          <Col xs="12" className="pt-2 pb-2 d-flex justify-content-between align-items-center has-border-bottom">
            Enrollments CSV
            <a
              className="btn btn-link ms-2"
              href={`${data_backup[0].enrollment_data_csv_url}`}
              target="_blank"
              rel="noreferrer"
            >
              Download
            </a>
          </Col>
          <Col xs="12" className="pt-2 pb-2 d-flex justify-content-between align-items-center has-border-bottom">
            Course Progress CSV
            <a
              className="btn btn-link ms-2"
              href={`${data_backup[0].progress_data_csv_url}`}
              target="_blank"
              rel="noreferrer"
            >
              Download
            </a>
          </Col>
          <Col xs="12" className="pt-2 pb-2 d-flex justify-content-between align-items-center has-border-bottom">
            Certificates CSV
            <a
              className="btn btn-link ms-2"
              href={`${data_backup[0].certificate_data_csv_url}`}
              target="_blank"
              rel="noreferrer"
            >
              Download
            </a>
          </Col>
        </Row>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  const data_backup = state.DATA_BACKUP.dataBackup;

  return {
    data_backup
  };
}

export default connect(mapStateToProps)(DataBackupTable);
