import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';

import ThemePreview from 'pages/appearance/containers/appearanceContainer/components/themePreview';
import { getOrganization } from 'selectors';
import 'pages/appearance/index.scss';

const ThemesCenter = (props) => {
  const { themes, organization, dispatch } = props;
  const [currentSiteTheme, setCurrentSiteTheme] = useState(organization.current_site_theme);
  const [activeTheme, setActiveTheme] = useState(organization.current_site_theme);

  const handleThemeChange = (e) => {
    setCurrentSiteTheme(e.target.value);
    props.onUpdate(e.target.value);
  };

  useEffect(() => {
    setActiveTheme(currentSiteTheme);
  }, [currentSiteTheme]);

  const themeItems = themes.map((theme) => {
    return (
      <div className="theme-frame" key={theme.id}>
        <div className="theme-select">
          {theme.theme_name === organization.current_site_theme ? <span className="active-status"> Active </span> : ''}
          <div className="custom_radio">
            <Form.Check
              className="d-inline"
              type="radio"
              name={`theme-radio-${theme.theme_name}`}
              id={`theme-radio-${theme.theme_name}`}
              value={theme.theme_name}
              checked={theme.theme_name === currentSiteTheme}
              onChange={handleThemeChange}
            />

            <Form.Check.Label className="d-inline ms-2" htmlFor={`theme-radio-${theme.theme_name}`}>
              {theme.theme_name}
            </Form.Check.Label>
          </div>
        </div>
        <div className="theme-demos">
          <ThemePreview
            theme={theme}
            organization={organization}
            dispatch={dispatch}
            currentSiteTheme={currentSiteTheme}
            activeTheme={activeTheme}
            setActiveTheme={setActiveTheme}
          />
        </div>
      </div>
    );
  });

  return (
    <div className="appearance-holder">
      <div className="themes-table">{themeItems}</div>
    </div>
  );
};

function mapStateToProps(state) {
  const themes = state.THEMES.themes;
  const organization = getOrganization(state);

  return {
    themes,
    organization
  };
}

export default connect(mapStateToProps)(ThemesCenter);
