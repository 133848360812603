import React from 'react';

import Select from 'react-select';

const SelectBox = ({ options, optionSelected, handleChange }) => {
  return (
    <Select
      isMulti
      options={options}
      isClearable={false}
      isLoading={!options}
      closeMenuOnSelect={false}
      onChange={handleChange}
      value={optionSelected}
      name={'your_select_name'}
      classNamePrefix="react-select"
    />
  );
};

export default SelectBox;
