import {
  messageNotification,
  validateLoginSession,
  validatePanelAccess,
  getEcommerceBaseUrl,
  getPanelBackendUrl,
  filterErrorMessages,
  setupOrganizationLocalStorage
} from 'helpers';
import { userSettingsService } from 'services';
import { settingsDispatches } from 'pages/settings/containers/settingsContainer/constants';
import { PASSWORD_RESET_EMAIL_TIME_LIMIT } from 'config';

const updateSettings = (payload) => {
  return (dispatch) => {
    userSettingsService
      .updateSettings(payload)
      .then((userData) => {
        Promise.resolve(dispatch(settingsDispatches.updateUserSettingsSuccess(userData))).then(() => {
          messageNotification('success', 'Accounts Settings', 'Account Settings Updated Successfully');
        });
      })
      .catch((error) => {
        validateLoginSession(dispatch, error);
        if (error.response.data.field_errors) {
          messageNotification('danger', 'Accounts Settings Update Failed', filterErrorMessages(error).join());
        } else if (error.response.data.detail) {
          messageNotification('danger', 'Accounts Settings Update Failed', error.response.data.detail);
        }
      });
  };
};

const getAccountsSettings = () => {
  return (dispatch) => {
    userSettingsService
      .getAccountsSettings()
      .then((userData) => {
        Promise.resolve(dispatch(settingsDispatches.fetchUserSettingsSuccess(userData)));
      })
      .catch((error) => {
        validateLoginSession(dispatch, error);
      });
  };
};

const getUserPreferences = () => {
  return (dispatch) => {
    userSettingsService
      .getUserPreferences()
      .then((userData) => {
        Promise.resolve(dispatch(settingsDispatches.fetchUserPreferencesSuccess(userData))).then(() =>
          dispatch(getCountryTimezones())
        );
      })
      .catch((error) => {
        validateLoginSession(dispatch, error);
      });
  };
};

const updateUserPreferences = (payload) => {
  return (dispatch) => {
    userSettingsService
      .updateUserPreferences(payload)
      .then((userData) => {
        Promise.resolve(dispatch(settingsDispatches.updateUserPreferencesSuccess(userData))).then(() => {
          messageNotification('success', 'User Preferences', 'User Preferences Updated Successfully');
        });
        dispatch(getUserPreferences());
      })
      .catch((error) => {
        validateLoginSession(dispatch, error);
        if (error.response.data.field_errors) {
          messageNotification('danger', 'User Preferences Update Failed', filterErrorMessages(error).join());
        } else if (error.response.data.detail) {
          messageNotification('danger', 'User Preferences Update Failed', error.response.data.detail);
        }
      });
  };
};

const resetUserPassword = (payload) => {
  return (dispatch) => {
    userSettingsService
      .resetUserPassword(payload)
      .then((userData) => {
        Promise.resolve(dispatch(settingsDispatches.userPasswordResetSuccess(userData))).then(() => {
          messageNotification(
            'success',
            'Reset Password',
            'We have e-mailed you instructions for setting your password to the e-mail address you submitted.You should be receiving it shortly.'
          );
          dispatch(settingsDispatches.setPasswordResetSuccess({ password_reset: false }));
          setTimeout(() => {
            dispatch(settingsDispatches.setPasswordResetSuccess({ password_reset: true }));
          }, PASSWORD_RESET_EMAIL_TIME_LIMIT * 1000);
        });
      })
      .catch((error) => {
        if (error?.response?.status === 403 || error?.response?.status === 401) {
          messageNotification(
            'danger',
            'Reset Password',
            `You have used password reset too many times. Please retry after ${PASSWORD_RESET_EMAIL_TIME_LIMIT} seconds.`
          );
        }
      });
  };
};

const updateUserProfileImage = (payload, profile_image) => {
  return (dispatch) => {
    userSettingsService
      .updateUserProfileImage(payload, profile_image)
      .then((userData) => {
        Promise.resolve(dispatch(settingsDispatches.updateUserProfileImageSuccess(userData))).then(() => {
          messageNotification('success', 'Account Settings', 'Profile Image Updated Successfully.');
        });
        dispatch(settingsActions.getAccountsSettings());
      })
      .catch((error) => {
        validateLoginSession(dispatch, error);
        messageNotification('danger', 'Profile Image Updated Failed', error.response.data.user_message);
      });
  };
};

const getCountryTimezones = (country_code) => {
  return (dispatch) => {
    userSettingsService
      .getCountryTimezones(country_code)
      .then((userData) => {
        Promise.resolve(dispatch(settingsDispatches.fetchCountryTimezoneSuccess(userData)));
      })
      .catch((error) => {
        validateLoginSession(dispatch, error);
      });
  };
};

const getPanelUserRoles = () => {
  return (dispatch) => {
    userSettingsService
      .getPanelUserRoles()
      .then((userData) => {
        Promise.resolve(dispatch(settingsDispatches.fetchUserRolesSuccess(userData))).then((resp) => {
          let panel_role = 'panel_restricted';
          if (userData.data.length > 0) {
            panel_role = userData.data[0]['roles']['panel_role'];
          }
          validatePanelAccess(panel_role);
          dispatch(settingsDispatches.applicationLoadedSuccess());
        });
        if (getEcommerceBaseUrl()) {
          dispatch(getEcommerceLoginSession());
        }
      })
      .catch((error) => {
        dispatch(SessionLogOut());
        dispatch(settingsDispatches.fetchUserRolesFailure(error));
        validateLoginSession(dispatch, error);
      });
  };
};

const getUserRoles = () => {
  return (dispatch) => {
    userSettingsService
      .getPanelUserRoles()
      .then((userData) => {
        Promise.resolve(dispatch(settingsDispatches.fetchUserRolesSuccess(userData))).then((resp) => {
          let panel_role = 'panel_restricted';
          if (userData.data.length > 0) {
            panel_role = userData.data[0]['roles']['panel_role'];
          }
          validatePanelAccess(panel_role);
          dispatch(settingsDispatches.applicationLoadedSuccess());
        });
      })
      .catch((error) => {
        dispatch(SessionLogOut());
        dispatch(settingsDispatches.fetchUserRolesFailure(error));
        validateLoginSession(dispatch, error);
      });
  };
};

const getLmsCsrfToken = () => {
  return (dispatch) => {
    userSettingsService
      .getLmsCsrfToken()
      .then((sessionData) => {
        localStorage.setItem('lms_csrf_token', sessionData.data.csrf_token);
      })
      .catch((error) => {
        localStorage.removeItem('lms_csrf_token');
      });
  };
};

const getStudioCsrfToken = () => {
  return (dispatch) => {
    userSettingsService
      .getStudioCsrfToken()
      .then((sessionData) => {
        localStorage.setItem('studio_csrf_token', sessionData.data.csrf_token);
      })
      .catch((error) => {
        localStorage.removeItem('studio_csrf_token');
      });
  };
};

const getEcommerceCsrfToken = () => {
  return (dispatch) => {
    userSettingsService
      .getEcommerceCsrfToken()
      .then((sessionData) => {
        localStorage.setItem('ecommerce_csrf_token', sessionData.data.csrf_token);
      })
      .catch((error) => {
        localStorage.removeItem('ecommerce_csrf_token');
      });
  };
};

const getPanelCsrfToken = () => {
  return (dispatch) => {
    userSettingsService
      .getPanelCsrfToken()
      .then((sessionData) => {
        localStorage.setItem('panel_csrf_token', sessionData.data.csrf_token);
      })
      .catch((error) => {
        localStorage.removeItem('panel_csrf_token');
      });
  };
};

const getEcommerceLoginSession = () => {
  return (dispatch) => {
    userSettingsService
      .getEcommerceLoginSession()
      .then((response) => {
        dispatch(getEcommerceCsrfToken());
        Promise.resolve(dispatch(settingsDispatches.ecommerceLoginSuccess(response)));
      })
      .catch((error) => {
        validateLoginSession(dispatch, error);
      });
  };
};

const SessionLogOut = () => {
  return (dispatch) => {
    userSettingsService.SessionLogOut().then((response) => {
      dispatch(settingsDispatches.applicationLoadedSuccess());
      localStorage.removeItem('edx_username');
      localStorage.removeItem('lms_csrf_token');
      localStorage.removeItem('panel_csrf_token');
      localStorage.removeItem('wordpress_base_url');
      localStorage.removeItem('ecommerce_base_url');
      localStorage.removeItem('organization_slug');
    });
  };
};

const fetchOrganization = (param) => {
  return (dispatch) => {
    if (getPanelBackendUrl()) {
      userSettingsService
        .fetchOrganizations(param)
        .then((organizations) => {
          setupOrganizationLocalStorage(dispatch, organizations);
          Promise.resolve(dispatch(settingsDispatches.fetchOrganizationSuccess(organizations))).then(() => {
            dispatch(settingsDispatches.fetchUsernameSuccess(organizations));
          });
        })
        .catch((error) => {
          if (error.response) {
            messageNotification('danger', 'There was an error fetching organization information', error.response);
          }
          localStorage.removeItem('edx_username');
          localStorage.removeItem('organization_slug');
          dispatch(settingsDispatches.applicationLoadedSuccess());
        });
    } else {
      localStorage.removeItem('edx_username');
      dispatch(settingsDispatches.applicationLoadedSuccess());
    }
  };
};

export const settingsActions = {
  getUserPreferences,
  resetUserPassword,
  updateUserProfileImage,
  updateUserPreferences,
  updateSettings,
  getAccountsSettings,
  SessionLogOut,
  getCountryTimezones,
  getLmsCsrfToken,
  getStudioCsrfToken,
  getEcommerceCsrfToken,
  getPanelCsrfToken,
  getPanelUserRoles,
  getEcommerceLoginSession,
  fetchOrganization,
  getUserRoles
};
