import { Modal, Button } from 'react-bootstrap';
import { IconInfoTriangle } from '@tabler/icons-react';

const Confirmation = ({ isOpen, hideModal, isEdit, callBack }) => {
  return (
    <Modal className="confirmation-model" onHide={hideModal} show={isOpen} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Confirmation</Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ minHeight: '10px' }}>
        <IconInfoTriangle stroke={1.5} /> &nbsp;
        {isEdit ? (
          <span className="body-font-color ml-10">Edit will rerun the process</span>
        ) : (
          <>
            <span className="body-font-color ml-10">This action cannot be undone.</span>

            <div className="confirmation-body-text body-font-color mb-20">
              <span>Are you sure you want to reset to default settings?</span>
            </div>
            <div className="mt-10 confirmation-body-text">
              <span>After deleting custom domain your site url will be Edly subdomain, based on the site name.</span>
            </div>
          </>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button className="mr-5" variant="outline-primary" onClick={callBack}>
          yes i am sure
        </Button>
      </Modal.Footer>
      <div className="justify-content-end d-flex"></div>
    </Modal>
  );
};

export default Confirmation;
