import Confirmation from './confirmation';
import { useState } from 'react';
import { siteDetailsActions } from '../actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from 'react-bootstrap';
import { IconPencil, IconWorld, IconMail } from '@tabler/icons-react';

const SiteDetailsValues = ({ siteUrl, email, edit, dispatch }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const openConfirmation = (edit = false) => {
    setIsOpen(true);
    edit ? setIsEdit(true) : setIsEdit(false);
  };

  const resetDnsRecord = () => {
    setIsOpen(false);
    dispatch(siteDetailsActions.postResetDns(null));
  };

  return (
    <>
      <h4>Site url details</h4>
      <div className="dns-card">
        <div className="url-details">
          <IconWorld stroke={1.5} />
          <span className="ms-3">{siteUrl}</span>
        </div>
        <div className="url-details">
          <IconMail stroke={1.5} />
          <span className="ms-3">{email}</span>
        </div>
        <div className="btn-holder">
          <Button variant="default" className="pen-button" onClick={() => openConfirmation(true)}>
            <IconPencil stroke={1.5} />
          </Button>
        </div>
      </div>

      <div className="reset-holder pt-4">
        <small className="reset-text">
          You can disable these settings anytime, By disable these settings your site url will be edly subdomain, based
          on the site name.
        </small>

        <Button variant="link" className="reset-button site-detail-btn" onClick={() => openConfirmation(false)}>
          Reset to default settings
        </Button>
      </div>
      <Confirmation
        isOpen={isOpen}
        isEdit={isEdit}
        callBack={isEdit ? edit : resetDnsRecord}
        hideModal={() => {
          setIsOpen(false);
        }}
      />
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(siteDetailsActions, dispatch)
  };
}

export default connect(mapDispatchToProps)(SiteDetailsValues);
