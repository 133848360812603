export const graphProperties = {
  dark: {
    backgroundColor: '#F5BCBE',
    hoverBackgroundColor: '#E76266',
    borderColor: '#E76266',
    pointHoverBorderWidth: 1,
    pointRadius: 1,
    pointHitRadius: 10,
    fill: true,
    lineTension: 0.1,
    borderCapStyle: 'butt',
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: 'miter',
    pointBorderColor: '#fff',
    pointBackgroundColor: '#fff',
    pointBorderWidth: 1,
    pointHoverRadius: 5,
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: '#E76266'
  },
  light: {
    backgroundColor: '#ed686b',
    hoverBackgroundColor: '#e94246',
    borderColor: '#e94246',
    pointHoverBorderWidth: 1,
    pointRadius: 1,
    pointHitRadius: 10,
    borderCapStyle: 'butt',
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: 'miter',
    pointBorderColor: '#e94246',
    pointBackgroundColor: '#e94246',
    pointBorderWidth: 1,
    pointHoverRadius: 5,
    pointHoverBackgroundColor: '#e94246',
    pointHoverBorderColor: '#e94246'
  }
};

export const dashboardGraphColor = {
  light: ['#e27c7c', '#a86464', '#6d4b4b', '#503f3f', '#333333', '#3c4e4b', '#466964', '#599e94', '#6cd4c5'],
  dark: ['#ffdbb3', '#F5BCBE', '#CCEFE9', '#E6EEFD', '#FDEBD0', '#F4F4F4', '#f5e0ff', '#e0fdff', '#fbffe0']
};
