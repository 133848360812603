import React, { Fragment } from 'react';
import { IconInfoCircle } from '@tabler/icons-react';
import { Row, Col, Card, CardBody } from 'react-bootstrap';

import LineChart from 'components/charts/lineChart';
import InsightsLoader from 'components/loaders/InsightsLoader';

const CompletionsOverTime = (props) => {
  const { course_info } = props;

  const labels =
    course_info &&
    course_info.users_completed &&
    course_info.users_completed.history.map((data) => {
      return data.period;
    });
  const values =
    course_info &&
    course_info.users_completed &&
    course_info.users_completed.history.map((data) => {
      return data.value;
    });

  return (
    <Col xs="12" md="6" className="mb-4">
      <Card>
        <CardBody>
          {course_info && course_info.users_completed && course_info.users_completed.history ? (
            <Fragment>
              <span className="sub-title">
                Completions Over Time
                <span className="tool-tip ms-2">
                  <IconInfoCircle stroke={1.5} />
                  <span className="tip-content">Number of completions over time, for the last six months..</span>
                </span>
              </span>
              {labels && values && <LineChart title="Course Completions" labels={labels} values={values} />}
            </Fragment>
          ) : (
            <InsightsLoader />
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

export default CompletionsOverTime;
