import React, { Fragment, useState, useEffect, useRef } from 'react';

import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { Accordion, Card, ListGroup } from 'react-bootstrap';

const DownloadFilter = (props) => {
  const { handleFilterChange } = props;

  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const filterContainerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterContainerRef.current && !filterContainerRef.current.contains(event.target)) {
        setIsFilterOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const filters = {
    download_maus: 'Download MALS',
    download_user_report: 'Download User Report'
  };

  const resetButtonHandler = () => {
    setIsFilterOpen(false);
  };

  const filterListItems = Object.keys(filters).map((key, index) => {
    return (
      <ListGroup.Item
        action
        key={key}
        onClick={() => {
          setIsFilterOpen(false);
          handleFilterChange(key);
        }}
        style={{ maxWidth: '320px', fontSize: '0.75rem' }}
      >
        {filters[key]}
      </ListGroup.Item>
    );
  });

  return (
    <Fragment>
      <div ref={filterContainerRef} className="maus-filter-container">
        <Accordion className="maus-filter">
          <Card className="small-drop">
            <Card.Header onClick={() => setIsFilterOpen(!isFilterOpen)} className={isFilterOpen ? 'open' : ''}>
              Download
              <FontAwesomeIcon icon={isFilterOpen ? faChevronUp : faChevronDown} />
            </Card.Header>
            <Accordion.Collapse className={isFilterOpen ? 'show' : 'd-none'}>
              <Card.Body>
                <ListGroup>{filterListItems}</ListGroup>
                <div className="maus-filter-footer">
                  <Button size="sm" onClick={resetButtonHandler}>
                    Close
                  </Button>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>
    </Fragment>
  );
};

export default DownloadFilter;
