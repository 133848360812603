import React from 'react';
import { connect } from 'react-redux';
import { Nav } from 'react-bootstrap';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { IconChevronRight } from '@tabler/icons-react';

import { getEdlyServiceUrl, getWordpressBaseUrl, getEcommerceBaseUrl } from 'helpers';
import { getOrganization } from 'selectors';
import IconLMS from 'assets/images/icon-lms.svg';
import IconStudio from 'assets/images/icon-studio.svg';
import IconDiscovery from 'assets/images/icon-discovery.svg';
import IconWPAdmin from 'assets/images/icon-wp-admin.svg';
import IconEcommerce from 'assets/images/icon-ecommerce.svg';

const SeeAllApps = (props) => {
  const { organization } = props;
  const lmsUrl = getEdlyServiceUrl(organization, 'lms');
  const studioUrl = getEdlyServiceUrl(organization, 'studio');
  const wordPressUrl = getEdlyServiceUrl(organization, 'wordpress');
  const ecommerceUrl = getEdlyServiceUrl(organization, 'ecommerce');

  return (
    <div className="row see-all-apps">
      <div>
        <div className="dashboard-header">
          <h2>My Apps</h2>
        </div>
        <div className="app-block">
          <div className="app-box">
            <div className="image-box">
              <img src={IconLMS} alt="Icon LMS" />
            </div>
            <div className="description">
              <h2>LMS</h2>
              <p>Your LMS site, that your learners see.</p>
              <Nav.Link href={lmsUrl} target="_blank" className="btn btn-arrow">
                Visit site <IconChevronRight stroke={1.5} />
              </Nav.Link>
            </div>
          </div>
          <div className="app-box">
            <div className="image-box">
              <img src={IconStudio} alt="Icon Studio" />
            </div>
            <div className="description">
              <h2>Studio</h2>
              <p>Create and manage courses.</p>
              <Nav.Link href={studioUrl} target="_blank" className="btn btn-arrow">
                Visit site <IconChevronRight stroke={1.5} />
              </Nav.Link>
            </div>
          </div>
        </div>
        {getWordpressBaseUrl() && (
          <div className="app-block">
            <div className="app-box">
              <div className="image-box">
                <img src={IconDiscovery} alt="Icon Discovery" />
              </div>
              <div className="description">
                <h2>Discovery</h2>
                <p>Your courses discovery, that your learners see.</p>
                <Nav.Link href={wordPressUrl} target="_blank" className="btn btn-arrow">
                  Visit site <IconChevronRight stroke={1.5} />
                </Nav.Link>
              </div>
            </div>
            <div className="app-box">
              <div className="image-box">
                <img src={IconWPAdmin} alt="Icon Discovery Admin" />
              </div>
              <div className="description">
                <h2>Discovery Admin</h2>
                <p>Create and manage public site.</p>
                <Nav.Link href={`${wordPressUrl}wp-admin`} target="_blank" className="btn btn-arrow">
                  Visit site <IconChevronRight stroke={1.5} />
                </Nav.Link>
              </div>
            </div>
          </div>
        )}

        {getEcommerceBaseUrl() && (
          <div className="app-block">
            <div className="app-box">
              <div className="image-box">
                <img src={IconEcommerce} alt="Icon Ecommerce" />
              </div>
              <div className="description">
                <h2>E-Commerce</h2>
                <p>Payment gateway for secure online transactions.</p>
                <Nav.Link href={`${ecommerceUrl}courses/`} target="_blank" className="btn btn-arrow">
                  Visit site <IconChevronRight stroke={1.5} />
                </Nav.Link>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const organization = getOrganization(state);
  return { organization };
}

export default connect(mapStateToProps)(SeeAllApps);
