import LtiIntegration from 'pages/integrations/containers/LtiIntegrationContainer/components/ltiIntegration';
import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ltiActions } from 'pages/integrations/containers/LtiIntegrationContainer/actions';

const LtiConfiguration = ({ dispatch }) => {
  useEffect(() => {
    dispatch(ltiActions.fetchLtiConfigurations());
  }, [dispatch]);

  return <LtiIntegration />;
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(ltiActions, dispatch)
  };
}
export default connect(mapDispatchToProps)(LtiConfiguration);
