import React from 'react';
import { Accordion, Button, Card } from 'react-bootstrap';

import NavItem from 'components/sidebar/navItem';
import { IconSettings, IconTriangleInvertedFilled } from '@tabler/icons-react';
import { MAIN_ROUTES } from 'components/sidebar/contants';

const settingsDropDown = (props) => {
  const { location, settingsOpen, setSideBarOpen, setSettingsOpen, setInsightsOpen, insightsOpen, panelRole } = props;

  const checkLocation = MAIN_ROUTES.includes(location.pathname);
  return (
    <li className={'settings-list'}>
      <Accordion>
        <Button
          variant="link"
          className={
            location.pathname.includes('settings') && settingsOpen
              ? 'active w-100 nav-btn btn-settings'
              : !location.pathname.includes('settings') && settingsOpen
              ? 'nav-btn w-100 focused'
              : 'nav-btn w-100'
          }
          onClick={() => setSettingsOpen(!settingsOpen)}
        >
          <span>
            <IconSettings stroke={1.5} />
            Settings
          </span>
          <IconTriangleInvertedFilled className="iconTriangle" />
        </Button>
        <Accordion.Collapse className={settingsOpen ? 'show' : 'd-none'} eventKey="0">
          <Card.Body>
            <ul>
              {checkLocation ? (
                <NavItem
                  link="/panel/settings/general"
                  label="General"
                  settingsOpen={true}
                  setSideBarOpen={setSideBarOpen}
                  setSettingsOpen={setSettingsOpen}
                  insightsOpen={insightsOpen}
                  setInsightsOpen={setInsightsOpen}
                />
              ) : (
                <NavItem
                  link="/panel/settings/profile"
                  label="General"
                  settingsOpen={true}
                  setSideBarOpen={setSideBarOpen}
                  setSettingsOpen={setSettingsOpen}
                  insightsOpen={insightsOpen}
                  setInsightsOpen={setInsightsOpen}
                />
              )}
              {(panelRole === 'panel_admin' || panelRole === 'super_admin') && !checkLocation && (
                <React.Fragment>
                  <NavItem
                    link="/panel/settings/appearance"
                    label="Appearance"
                    settingsOpen={true}
                    setSideBarOpen={setSideBarOpen}
                    setSettingsOpen={setSettingsOpen}
                    insightsOpen={insightsOpen}
                    setInsightsOpen={setInsightsOpen}
                  />
                  <NavItem
                    link="/panel/settings/signin"
                    label="Sign in/up settings"
                    insightsOpen={insightsOpen}
                    setSideBarOpen={setSideBarOpen}
                    setInsightsOpen={setInsightsOpen}
                    settingsOpen={true}
                    setSettingsOpen={setSettingsOpen}
                  />
                  <NavItem
                    link="/panel/settings/analytics"
                    label="Analytics"
                    insightsOpen={insightsOpen}
                    setSideBarOpen={setSideBarOpen}
                    setInsightsOpen={setInsightsOpen}
                    settingsOpen={true}
                    setSettingsOpen={setSettingsOpen}
                  />
                  <NavItem
                    link="/panel/settings/integrations"
                    label="Integrations"
                    insightsOpen={insightsOpen}
                    setSideBarOpen={setSideBarOpen}
                    setInsightsOpen={setInsightsOpen}
                    settingsOpen={true}
                    setSettingsOpen={setSettingsOpen}
                  />
                  <NavItem
                    link="/panel/settings/sitedetails"
                    label="Site Details"
                    insightsOpen={insightsOpen}
                    setSideBarOpen={setSideBarOpen}
                    setInsightsOpen={setInsightsOpen}
                    settingsOpen={true}
                    setSettingsOpen={setSettingsOpen}
                  />
                  <NavItem
                    link="/panel/settings/mobileconfig"
                    label="Edly Go"
                    insightsOpen={insightsOpen}
                    setSideBarOpen={setSideBarOpen}
                    setInsightsOpen={setInsightsOpen}
                    settingsOpen={true}
                    setSettingsOpen={setSettingsOpen}
                  />
                  <NavItem
                    link="/panel/settings/data_backup"
                    label="Data Backup"
                    insightsOpen={insightsOpen}
                    setSideBarOpen={setSideBarOpen}
                    setInsightsOpen={setInsightsOpen}
                    settingsOpen={true}
                    setSettingsOpen={setSettingsOpen}
                  />
                </React.Fragment>
              )}
            </ul>
          </Card.Body>
        </Accordion.Collapse>
      </Accordion>
    </li>
  );
};

export default settingsDropDown;
