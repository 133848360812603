import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { sendTrackingEvent } from 'services/tracking';
import { clarity } from 'react-microsoft-clarity';
import { IconArrowRight, IconX } from '@tabler/icons-react';

const DemoModel = (props) => {
  const { isOpen, handleTermsSubmit } = props;
  const [isGuideOpen, setIsGuideOpen] = useState(false);
  const [showLearnerDemo, setLearnerDemo] = useState(false);
  const [showInstuctorDemo, setshowInstuctorDemo] = useState(false);

  const handleGuideTracking = (guideType) => {
    const form_data = { guide: guideType };
    sendTrackingEvent(form_data).catch((err) => {});
    clarity.setTag('guide', guideType);
  };

  return (
    <Modal className="accept-tos-modal user-guide-model" size="lg" show={isOpen} onHide={() => {}}>
      <Modal.Header className="d-flex justify-content-between">
        <Modal.Title>User guide</Modal.Title>
        <Button
          variant="default"
          className={`${showLearnerDemo || showInstuctorDemo ? '' : 'hide-display'}`}
          onClick={(event) => {
            handleTermsSubmit();
          }}
        >
          <IconX stroke={1.5} />
        </Button>
      </Modal.Header>
      <Modal.Body>
        <section className="user-guide">
          <div
            className={`demo-container ${showLearnerDemo ? 'hide-display' : showInstuctorDemo ? 'hide-display' : ''}`}
          >
            <div
              id="learner_guide"
              onClick={(event) => {
                setLearnerDemo(true);
                setshowInstuctorDemo(false);
                setIsGuideOpen(true);
                handleGuideTracking('learner_guide');
              }}
            >
              <h6 className="mb-0">
                User guide for <strong>learners</strong>
              </h6>
              <IconArrowRight stroke={1.5} />
            </div>
            <div
              id="Instructors_guide"
              onClick={(event) => {
                setshowInstuctorDemo(true);
                setLearnerDemo(false);
                setIsGuideOpen(true);
                handleGuideTracking('instructor_guide');
              }}
            >
              <h6 className="mb-0">
                User guide for <strong>instructors</strong>
              </h6>
              <IconArrowRight stroke={1.5} />
            </div>
          </div>
          <div className={`learner-demo ${showLearnerDemo ? '' : 'hide-display'}`}>
            <h5 className="mb-4">
              <Button
                variant="outline-primary"
                className={`me-4 ${!isGuideOpen ? 'hide-display' : ''}`}
                onClick={(event) => {
                  setIsGuideOpen(false);
                  setLearnerDemo(false);
                  setshowInstuctorDemo(false);
                }}
              >
                Back
              </Button>
              User guide for <strong>learners</strong>
            </h5>
            <div
              className="sl-embed"
              style={{
                position: 'relative',
                paddingBottom: 'calc(55.34% + 27px)',
                width: '100%',
                height: '0',
                transform: 'scale(1)'
              }}
            >
              <iframe
                title="learner_demo"
                className="sl-demo"
                src="https://app.storylane.io/demo/l0ebybe5myhz"
                name="sl-embed"
                allow="fullscreen; camera; microphone"
                style={{
                  position: 'absolute',
                  top: '0',
                  left: '0',
                  width: '100%',
                  border: 'none',
                  height: '100%',
                  boxSizing: 'border-box'
                }}
              ></iframe>
            </div>
          </div>

          <div className={`instructors-demo ${showInstuctorDemo ? '' : 'hide-display'}`}>
            <h5 className="mb-4">
              <Button
                variant="outline-primary"
                className={`me-4 ${!isGuideOpen ? 'hide-display' : ''}`}
                onClick={(event) => {
                  setIsGuideOpen(false);
                  setLearnerDemo(false);
                  setshowInstuctorDemo(false);
                }}
              >
                Back
              </Button>
              User guide for <strong>instructors</strong>
            </h5>
            <div
              className="sl-embed"
              style={{
                position: 'relative',
                paddingBottom: 'calc(55.34% + 27px)',
                width: '100%',
                height: '0',
                transform: 'scale(1)'
              }}
            >
              <iframe
                title="instructor_demo"
                className="sl-demo"
                src="https://app.storylane.io/demo/zgl1qvmufhoz"
                name="sl-embed"
                allow="fullscreen; camera; microphone"
                style={{
                  position: 'absolute',
                  top: '0',
                  left: '0',
                  width: '100%',
                  border: 'none',
                  height: '100%',
                  boxSizing: 'border-box'
                }}
              ></iframe>
            </div>
          </div>
        </section>
      </Modal.Body>
      <Modal.Footer>
        <div></div>
        <Button
          type="button"
          className={`${showLearnerDemo || showInstuctorDemo ? 'hide-display' : ''}`}
          onClick={(event) => {
            handleTermsSubmit();
            handleGuideTracking('skip');
          }}
        >
          Skip
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DemoModel;
