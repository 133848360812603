import { MonthlyActiveUsers } from 'pages/dashboard/containers/monthlyActiveUsers/monthlyActiveUsers';
import QuickStats from 'pages/dashboard/containers/Analytics/components/quickStats';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';

const UserAnalytics = (props) => {
  const { userSelectedSite } = props;
  return (
    <section className="user-analytics">
      <h3>
        Analytics{' '}
        {userSelectedSite.includes('all')
          ? ''
          : `- ${userSelectedSite.map((site) => site.charAt(0).toUpperCase() + site.slice(1)).join(', ')}`}
      </h3>
      <div className="analytic-container mb-4">
        <Row>
          <Col xs="12" lg="8">
            <MonthlyActiveUsers />
          </Col>
          <Col xs="12" lg="4">
            <QuickStats />
          </Col>
        </Row>
      </div>
    </section>
  );
};
function mapStateToProps(state) {
  return {
    userSelectedSite: state.USER_SITES.selected_sites
  };
}
export default connect(mapStateToProps)(UserAnalytics);
