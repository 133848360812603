import React from 'react';

import MAUSAnalytics from 'components/maus';
import TotalStaffUsers from 'pages/dashboard/containers/Analytics/components/totalStaffusers';
import TotalLearners from 'pages/dashboard/containers/Analytics/components/totalLearners';

const moment = require('moment');

const QuickStats = (props) => {
  return (
    <div className="quick-stats">
      <h6>Quick Stats for {moment().format('MMM, YYYY')}</h6>
      <MAUSAnalytics title="Monthly Active Learners" />
      <TotalLearners />
      <TotalStaffUsers />
    </div>
  );
};

export default QuickStats;
