import PaidBenefits from 'pages/benefits/containers/components/PaidBenefit';
import BenefitsSlider from 'pages/benefits/containers/components/benefits';

const BenefitAndAddOn = () => {
  return (
    <div className="block">
      <PaidBenefits />
      <BenefitsSlider />
    </div>
  );
};

export default BenefitAndAddOn;
