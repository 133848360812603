import { useEffect, useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  lti1p1Variables,
  lti1p3Variables,
  inputFieldsLTI1p1,
  inputFieldsLTI1p3
} from 'pages/integrations/containers/LtiIntegrationContainer/constants';
import { ltiActions } from 'pages/integrations/containers/LtiIntegrationContainer/actions';

const LtiConfigurationForm = ({ dispatch, hideModal, isFormOpen, editMode, configuration }) => {
  const initialInputState = {
    courseKey: '',
    clientKey: '',
    clientSecret: '',
    ltiUrl: '',
    toolLaunchUrl: '',
    loginUrl: '',
    publicKey: '',
    keysetUrl: '',
    enableDeepLinking: false,
    deepLinkingLaunchUrl: ''
  };
  const [selectedVersion, setSelectedVersion] = useState('');
  const [hiddenField, sethiddenField] = useState('');

  const [inputs, setInputs] = useState(initialInputState);

  useEffect(() => {
    if (editMode) {
      setSelectedVersion(configuration.version);
      updateInputs();
    }
  }, [editMode]);

  useEffect(() => {
    if (inputs.publicKey.length > 0) {
      sethiddenField('keysetUrl');
    } else if (inputs.keysetUrl.length > 0) {
      sethiddenField('publicKey');
    } else {
      sethiddenField('');
    }
  }, [inputs]);

  const hideInputField = (name) => {
    if (hiddenField === name) {
      return true;
    }
    return false;
  };

  const updateInputs = () => {
    const mappingDict = configuration.version === 'lti_1p1' ? lti1p1Variables : lti1p3Variables;
    const inputsDict = {};
    for (const key in mappingDict) {
      inputsDict[key] = configuration[mappingDict[key]];
    }
    delete inputsDict.version;

    setInputs((prevInputs) => ({
      ...prevInputs,
      ...inputsDict
    }));
  };

  const saveConfiguration = () => {
    inputs.version = selectedVersion;
    if (inputs.deepLinkingLaunchUrl.trim().length > 0) {
      inputs.enableDeepLinking = true;
    }
    dispatch(ltiActions.updateLtiConfigurations({ ...inputs, editMode: editMode }));
    setInputs(initialInputState);
    hideModal();
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value
    }));
  };

  const fieldValue = (value, name) => {
    if ((name === 'courseKey' && editMode, value === null)) {
      return 'Enabled organization-wide for ' + configuration.organization_slug;
    }
    return value;
  };

  const disabledCourseIdField = (name) => {
    if (name === 'courseKey' && editMode) return true;
    else return false;
  };

  const getInputFields = () => {
    if (selectedVersion === 'lti_1p1') return inputFieldsLTI1p1;
    else if (selectedVersion === 'lti_1p3') return inputFieldsLTI1p3;
    else return [];
  };
  return (
    <Modal onHide={hideModal} show={isFormOpen} backdrop="static" size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Add LTI Configuration</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form.Group className="mb-3">
          <Form.Select value={selectedVersion} onChange={(e) => setSelectedVersion(e.target.value)} disabled={editMode}>
            <option>Select LTI version</option>
            <option value="lti_1p1">LTI Version (1.1) </option>
            <option value="lti_1p3">LTI Version (1.3)</option>
          </Form.Select>
        </Form.Group>
        {getInputFields().map((field, index) => (
          <Form.Group className="mb-3" key={index}>
            <Form.Label hidden={hideInputField(field.name)}>{field.label}</Form.Label>
            {field.type !== 'textarea' ? (
              <Form.Control
                type={field.type}
                name={field.name}
                value={fieldValue(inputs[field.name], field.name)}
                placeholder={field.placeholder}
                onChange={handleInputChange}
                hidden={hideInputField(field.name)}
                disabled={disabledCourseIdField(field.name)}
              />
            ) : (
              <Form.Control
                as="textarea"
                rows={3}
                name={field.name}
                value={inputs[field.name]}
                placeholder={field.placeholder}
                onChange={handleInputChange}
                hidden={hideInputField(field.name)}
              />
            )}

            <Form.Text hidden={hideInputField(field.name)}>{field.helptext}</Form.Text>
          </Form.Group>
        ))}

        {selectedVersion && (
          <Modal.Footer>
            <Button style={{ paddingLeft: '30px', paddingRight: '30px' }} onClick={saveConfiguration} disabled={false}>
              save
            </Button>
          </Modal.Footer>
        )}
      </Modal.Body>
    </Modal>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(ltiActions, dispatch)
  };
}
LtiConfigurationForm.defaultProps = {
  version: '',
  editMode: false
};
export default connect(mapDispatchToProps)(LtiConfigurationForm);
