import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col, Button } from 'react-bootstrap';

import { dataBackupActions } from 'pages/dataBackup/actions';

const moment = require('moment');

const DataBackupDownload = ({ data_backup, dispatch }) => {
  const backup_exists = data_backup.length !== 0;
  const downloadBackupData = () => {
    dispatch(dataBackupActions.downloadDataBackup());
  };

  return (
    <div>
      <div className="integration-holder mb-4">
        <h2 className="mb-4">Data Backup</h2>
        <div className="block">
          <h4 className="mb-3">Download Backup</h4>
          <ul className="check-list mb-4">
            <li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-check"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#2c3e50"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M5 12l5 5l10 -10" />
              </svg>
              Users
            </li>
            <li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-check"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#2c3e50"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M5 12l5 5l10 -10" />
              </svg>
              Courses
            </li>
            <li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-check"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#2c3e50"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M5 12l5 5l10 -10" />
              </svg>
              Enrollments
            </li>
            <li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-check"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#2c3e50"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M5 12l5 5l10 -10" />
              </svg>
              Learners Progress
            </li>
            <li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-check"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#2c3e50"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M5 12l5 5l10 -10" />
              </svg>
              Learner Certificates
            </li>
          </ul>
        </div>
        <Row className="align-items-center">
          <Col xs="12" md="8">
            <div>
              <small>
                Last Download -
                <strong>
                  {backup_exists
                    ? moment(data_backup[0].modified)
                        .utc()
                        .format('YYYY-MM-DD')
                    : ''}
                </strong>
              </small>
            </div>
          </Col>
          <Col xs="12" md="4" className="justify-content-end d-flex">
            <Button className="ml-2" onClick={downloadBackupData}>
              Request Data
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const data_backup = state.DATA_BACKUP.dataBackup;

  return {
    data_backup
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(dataBackupActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DataBackupDownload);
