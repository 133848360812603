const ColorPicker = ({ text, onUpdate, color }) => {
  const handleColorChange = (e) => {
    onUpdate(e.target.value);
  };

  return (
    <div className="theme-color-block">
      <h6>{text}</h6>
      <div className="color-block">
        <div className="pick-frame">
          <div className="pick-holder">
            <h6>Select</h6>
            <span className="color-code">{color}</span>
          </div>
          <div className="input-holder">
            <input type="color" value={color} onChange={handleColorChange} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ColorPicker;
