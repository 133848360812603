import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Col, Form, Row, Button } from 'react-bootstrap';

import { getOrganization, getUserSettings } from 'selectors';
import { settingsActions } from 'pages/settings/containers/settingsContainer/actions';
import 'pages/settings/index.scss';

const countryList = require('country-list');

const UserPreferences = (props) => {
  const { username, organization, country_code, time_zone, country_timezones, dispatch } = props;
  const [inputs, setInputs] = useState({
    username: '',
    organization: '',
    country_code: '',
    time_zone: ''
  });

  const countries = countryList.getData().map((country, index) => {
    return (
      <option key={index} value={country.code}>
        {country.name}
      </option>
    );
  });

  useEffect(() => {
    setInputs({
      username: username,
      organization: organization,
      country_code: country_code,
      time_zone: time_zone
    });
  }, [username, organization, country_code, time_zone, dispatch]);

  const handleInputChange = (event) => {
    event.persist();

    if (event.target.name === 'country_code') {
      dispatch(settingsActions.getCountryTimezones(event.target.value));
      setInputs((inputs) => ({ ...inputs, time_zone: '' }));
    }
    setInputs((inputs) => ({ ...inputs, [event.target.name]: event.target.value }));
  };

  const updateUserPreferences = (e) => {
    e.preventDefault();
    dispatch(settingsActions.updateUserPreferences(inputs));
  };

  return (
    <Fragment>
      <form name="user_preferences" onSubmit={updateUserPreferences} className="profile-form">
        <Row>
          <Col xs="12" md="6">
            <Form.Group className="mb-3" controlId="country_code">
              <Form.Label>Country</Form.Label>
              <Form.Select
                id="country_code"
                name="country_code"
                value={inputs.country_code ? inputs.country_code : ''}
                onChange={handleInputChange}
              >
                <option key="" value="">
                  {' '}
                </option>
                {countries}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col xs="12" md="6">
            <Form.Group className="mb-3" controlId="time_zone">
              <Form.Label>Timezone</Form.Label>
              <Form.Select
                className="form-control"
                id="time_zone"
                name="time_zone"
                value={inputs.time_zone ? inputs.time_zone : ''}
                onChange={handleInputChange}
              >
                <option value=""> </option>
                {country_timezones.map((country_timezone, index) => {
                  return (
                    <option key={`year${index}`} value={country_timezone.time_zone}>
                      {country_timezone.description}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>

        <Form.Group className="mb-3" controlId="organization">
          <Form.Label>Organization</Form.Label>
          <Form.Control
            type="text"
            name="organization"
            value={inputs.organization || ''}
            onChange={handleInputChange}
            disabled
          />
        </Form.Group>
        <Row className="mt-4">
          <Col sm="12" className="d-flex justify-content-end">
            <Button type="submit" variant="primary">
              Update
            </Button>
          </Col>
        </Row>
      </form>
    </Fragment>
  );
};

function mapStateToProps(state) {
  const { username, country_code, time_zone, country_timezones } = getUserSettings(state);
  let organization = getOrganization(state);
  if (organization) {
    organization = organization.name;
  }

  return {
    username,
    organization,
    country_code,
    time_zone,
    country_timezones
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(settingsActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserPreferences);
