import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import { IconInfoCircle } from '@tabler/icons-react';

import LineChart from 'components/charts/lineChart';
import LineChartTrend from 'components/charts/lineChartTrend';
import {
  getLearnerCourseCompletionsChange,
  getLearnerCourseCompletionsLabels,
  getLearnerCourseCompletionsValues
} from 'selectors';
import { Card, CardBody } from 'react-bootstrap';

const LearnersCourseCompletions = (props) => {
  const { data_labels, data_values, percent_change, show_chart, trending } = props;

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <span className="sub-title pt-0">
          Course Completions
          <span className="tool-tip ms-2">
            <IconInfoCircle stroke={1.5} />
            <span className="tip-content">Course completions across all courses over time.</span>
          </span>
        </span>

        <strong className={`number ${percent_change < 0 ? 'fails' : percent_change > 0 ? 'success' : 'zero'}`}>
          <FontAwesomeIcon
            icon={percent_change < 0 ? Icons.faArrowDown : percent_change > 0 ? Icons.faArrowUp : Icons.faArrowsAltV}
          />
          {Math.abs(percent_change).toFixed(2)}%
        </strong>
      </div>
      <Card className="stats-box mb-4">
        <CardBody>
          {show_chart &&
            (trending === 'true' ? (
              <LineChartTrend title="Completions Count" labels={data_labels} values={data_values} />
            ) : (
              <LineChart title="Completions Count" labels={data_labels} values={data_values} />
            ))}
        </CardBody>
      </Card>
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  const data_labels = getLearnerCourseCompletionsLabels(state);
  const data_values = getLearnerCourseCompletionsValues(state);
  const percent_change = getLearnerCourseCompletionsChange(state);
  const show_chart = ownProps.show_chart;
  const trending = ownProps.trending;

  return {
    data_labels,
    data_values,
    percent_change,
    show_chart,
    trending
  };
}

export default connect(mapStateToProps)(LearnersCourseCompletions);
