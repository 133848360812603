import { combineReducers } from 'redux';

import { fetchThemes } from 'pages/appearance/containers/appearanceContainer/reducers/themes';
import { fetchPaymentMethod, userSliderReducer } from 'pages/pricing/containers/pricingContainer/reducers/pricing';
import { mausFetch } from 'pages/dashboard/containers/monthlyActiveUsers/reducers/maus';
import { fetchQuickStats } from 'pages/dashboard/containers/quickStats/reducers/quickStats';
import { userSettings, fetchUserRoles, fetchOrganization } from 'pages/settings/containers/settingsContainer/reducer';
import {
  fetchUsers,
  exportUsersData,
  userValidation,
  bulkUsersUpload
} from 'pages/users/containers/usersContainer/reducers/users';
import { fetchLearnerDetails } from 'pages/insights/containers/learnerDetailContainer/reducers';
import { fetchCourseDetailData } from 'pages/insights/containers/courseDetailContainer/reducers';
import { fetchAllCoursesGeneralData } from 'pages/insights/containers/coursesAnalyticsContainer/reducers';
import { fetchNotifications, fetchServicesNotifications } from 'pages/notifications/containers/notifications/reducers';
import {
  fetchUsersDetailAnalytics,
  fetchAllUsersAnalytics,
  fetchLearnersQuickStats,
  downloadLearnersDetails
} from 'pages/insights/containers/learnersAnalyticsContainer/reducers';
import {
  fetchCoursesGeneralData,
  fetchGeneralSiteMetrics,
  fetchMonthlyUsersStats,
  fetchCoursesCompletionStats,
  fetchCoursesEnrollmentStats,
  fetchSiteMetrics
} from 'pages/insights/containers/analyticsSummaryContainer/reducers';
import { fetchBadgesData } from 'pages/integrations/containers/BadgrContainer/reducers';
import { siteDetailsReducer, freeTrialReducer, dnsEntriesReducer } from 'pages/siteDetails/containers/reducers';
import { UserSiteReducer } from 'pages/dashboard/containers/sites/reducers/userSite';
import { fetchLtiConfigurations } from 'pages/integrations/containers/LtiIntegrationContainer/reducers';
import { fetchDataBackup } from 'pages/dataBackup/reducers';
import { themeReducer } from 'pages/dashboard/containers/themes/reducers';

export const rootReducer = combineReducers({
  MAUS: mausFetch,
  USERS: fetchUsers,
  USER_ROLES: fetchUserRoles,
  USER_SETTINGS: userSettings,
  USER_VALIDATION: userValidation,
  THEMES: fetchThemes,
  QUICK_STATS: fetchQuickStats,
  LEARNERS_QUICK_STATS: fetchLearnersQuickStats,
  SITE_NOTIFICATIONS: fetchNotifications,
  ORGANIZATION: fetchOrganization,
  PAYMENT_METHOD: fetchPaymentMethod,
  USERS_EXPORT_DATA: exportUsersData,
  SERVICES_NOTIFICATIONS: fetchServicesNotifications,
  COURSES_GENERAL_INFO: fetchCoursesGeneralData,
  ALL_COURSES_GENERAL_DATA: fetchAllCoursesGeneralData,
  COURSES_ENROLLMENT_INFO: fetchCoursesEnrollmentStats,
  COURSES_COMPLETION_INFO: fetchCoursesCompletionStats,
  GENERAL_SITE_METRICS: fetchGeneralSiteMetrics,
  SITE_METRICS_INFO: fetchSiteMetrics,
  MONTHLY_USERS_STATS: fetchMonthlyUsersStats,
  LEARNER_USERS_INFO: fetchUsersDetailAnalytics,
  ALL_LEARNERS_ANALYTICS: fetchAllUsersAnalytics,
  LEARNER_DETAILS_PDF: downloadLearnersDetails,
  LEARNER_DETAILS: fetchLearnerDetails,
  CURRENT_COURSE_DETAILS: fetchCourseDetailData,
  BULK_USERS_UPLOAD: bulkUsersUpload,
  SITE_BADGES: fetchBadgesData,
  DNS_ENTRIES: dnsEntriesReducer,
  SITE_DETAILS: siteDetailsReducer,
  FREE_TRIAL: freeTrialReducer,
  USER_SITES: UserSiteReducer,
  LTI_CONFIGURATIONS: fetchLtiConfigurations,
  USER_SLIDER: userSliderReducer,
  DATA_BACKUP: fetchDataBackup,
  THEME: themeReducer
});
