import React from 'react';
import { connect } from 'react-redux';
import { IconInfoCircle } from '@tabler/icons-react';

import LineChart from 'components/charts/lineChart';
import PercentChangeBox from 'components/analytics/percentChangeBox';
import { getTotalSiteLearnersChange, getTotalSiteLearnersDataLabels, getTotalSiteLearnersDataValues } from 'selectors';
import { Card, CardBody } from 'react-bootstrap';

const TotalLearners = (props) => {
  const { data_labels, data_values, percent_change } = props;

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <span className="sub-title pt-0">
          Total Learners
          <span className="tool-tip ms-2">
            <IconInfoCircle stroke={1.5} />
            <span className="tip-content">Total number of learners who registered on the platform over time.</span>
          </span>
        </span>
        <PercentChangeBox percent_change={percent_change} />
      </div>
      <Card className="stats-box mb-4">
        <CardBody>
          <LineChart title="Users Count" height={80} labels={data_labels} values={data_values} />
        </CardBody>
      </Card>
    </div>
  );
};

function mapStateToProps(state) {
  const data_labels = getTotalSiteLearnersDataLabels(state);
  const data_values = getTotalSiteLearnersDataValues(state);
  const percent_change = getTotalSiteLearnersChange(state);

  return {
    data_labels,
    data_values,
    percent_change
  };
}

export default connect(mapStateToProps)(TotalLearners);
