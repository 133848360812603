import axios from 'axios';

import { PANEL_API_ENDPOINTS } from 'config';
import { getPanelBackendUrl } from 'helpers';

const postRequestFeature = (payload) => {
  const url = `${getPanelBackendUrl()}${PANEL_API_ENDPOINTS.FEATURE_REQUEST}`;

  const requestOptions = {
    method: 'POST',
    crossDomain: true,
    withCredentials: true,
    url,
    headers: {
      'X-CSRFToken': localStorage.getItem('panel_csrf_token')
    },
    data: payload
  };
  return axios(requestOptions);
};

export const RequestFeatureService = {
  postRequestFeature
};
