import { getPanelLmsBaseUrl, getPanelBackendUrl } from 'helpers';
import { PANEL_API_ENDPOINTS, FIGURES_API_ENDPOINTS } from 'config';
import { getInsights } from 'services/insights/methods';

const axios = require('axios');

export function sendTrackingEvent(form_data) {
  let url = `${getPanelBackendUrl()}${PANEL_API_ENDPOINTS.TRACKING_EVENTS}`;
  const requestOptions = {
    method: 'POST',
    withCredentials: true,
    headers: {
      'X-CSRFToken': localStorage.getItem('panel_csrf_token')
    },
    url,
    data: { event_data: form_data }
  };
  return axios(requestOptions);
}

export function getInsightsForPricing() {
  const params = {
    url: `${getPanelLmsBaseUrl()}${FIGURES_API_ENDPOINTS.GENERAL_SITE_METRICS_API}`,
    requestData: ''
  };
  return getInsights(params);
}

export function sendNewUserTrackingEvent(lms_role) {
  const eventData = lms_role === 'learner' ? { added_learner_count: 1 } : { added_additional_users_count: 1 };
  sendTrackingEvent(eventData).catch((err) => {
    // eslint-disable-next-line no-console
    console.log(err);
  });
}
