import React from 'react';

import Header from 'components/header';
import 'pages/404/index.scss';

const Page404 = () => {
  return (
    <div>
      <Header />
      <div className="error-page p-4 text-center">
        <div className="error-block">
          <img
            src="https://edly-edx-theme-files.s3.amazonaws.com/error-page-assets/error-404.svg"
            width="150"
            className="mb-4"
            alt="Error 404"
          />
          <h5>Page Not Found!</h5>
          <p>
            The page you are looking for may have been moved or renamed.
            <br />
            If you continue to have issues, please contact <a href="mailto:support@edly.io">Edly Support</a>.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Page404;
