import React from 'react';
import Switch from 'react-switch';
import { Row, Col } from 'react-bootstrap';

const ToggleItem = ({ title, description, checked, onChange }) => {
  return (
    <div>
      <Row className="d-flex align-items-center justify-content-between mb-3 switch-row">
        <Col xs="8">
          <h6>{title}</h6>
          <small>{description}</small>
        </Col>
        <Col xs="4" className="justify-content-end d-flex">
          <div className="switch-holder">
            <Switch
              width={45}
              height={24}
              id={title.toLowerCase()}
              checked={checked}
              onChange={onChange}
              uncheckedIcon={false}
              checkedIcon={false}
            />
            <label htmlFor={title.toLowerCase()}>{checked ? 'On' : 'Off'}</label>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ToggleItem;
