import axios from 'axios';

import { PANEL_API_ENDPOINTS } from 'config';
import { getPanelBackendUrl } from 'helpers';

const checkSiteCreation = () => {
  const requestUrl = localStorage.getItem('newSiteUrl');
  if (requestUrl) {
    const url = `${getPanelBackendUrl()}${PANEL_API_ENDPOINTS.IS_SITE_CREATED}`;
    const requestOptions = {
      crossDomain: true,
      withCredentials: true,
      method: 'POST',
      url,
      headers: {
        'X-CSRFToken': localStorage.getItem('panel_csrf_token')
      },
      data: {
        url: requestUrl
      }
    };
    return axios(requestOptions);
  }
  return null;
};

export const pollingService = {
  checkSiteCreation
};
