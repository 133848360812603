import React from 'react';
import './index.scss';
import { EDLY_GO, EDLY_GO_APPYSTORE, EDLY_GO_PLAYSTORE } from './containers/constants';
import ToggleSection from './containers/components/toggleSection';
import edlyGologo from 'assets/images/edly-Go-logo.svg';
import googlePlay from 'assets/images/google-play.svg';
import appStore from 'assets/images/app-store.svg';

function MobileConfig() {
  return (
    <div>
      <div>
        <h2 className="mb-4">Edly Go</h2>
        <div className="edly-go-banner mb-4">
          <div className="text">
            <p>
              Edly Go app provides access to LMS on the go. Learners can discover and enroll in course as well as access
              enrolled courses. Instructor can enable courses to be visible on mobile app from the studio.
            </p>
            <p>
              <a href={EDLY_GO}>Learn more</a> about Edly go app and its features. Download the app and lets get
              started!
            </p>
            <div className="btn-holder">
              <button>
                <a href={EDLY_GO_PLAYSTORE}>
                  <img src={googlePlay} alt="google play" />
                </a>
              </button>
              <button>
                <a href={EDLY_GO_APPYSTORE}>
                  <img src={appStore} alt="app store" />
                </a>
              </button>
            </div>
            <p className="mb-0">Mobile app configurations are provided below 👇</p>
          </div>
          <div className="img-holder"></div>
        </div>
      </div>
      <ToggleSection />
    </div>
  );
}

export default MobileConfig;
