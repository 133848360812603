import React from 'react';
import PublicRegistration from './containers/components/publicRegistration';

function SignInSettings() {
  return (
    <div className="form-container profile-form p-0">
      <h2 className="mb-4 pb-2">Sign in/up settings</h2>
      <PublicRegistration />
    </div>
  );
}

export default SignInSettings;
