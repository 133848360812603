import React, { useEffect } from 'react';
import moment from 'moment';
import { clarity } from 'react-microsoft-clarity';
import { useLocation, useHistory } from 'react-router-dom';
import 'pages/pricing/index.scss';

import PricingCenter from 'pages/pricing/containers/pricingContainer/pricingCenter';
import { getInsightsForPricing, sendTrackingEvent } from 'services/tracking';
import { messageNotification } from 'helpers';

const Pricing = (props) => {
  const params = useLocation();
  const history = useHistory();
  useEffect(() => {
    const redirectFrom = params.search.split('=')[1];
    if (redirectFrom) {
      let message = '';
      if (redirectFrom === 'studio') {
        message = 'Please upgrade your plan to create more courses!';
      } else {
        message = 'Please upgrade your plan to add more users!';
      }
      messageNotification('danger', 'Upgrade Plan', message);
      history.replace({
        search: ''
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getInsightsForPricing()
      .then((res) => {
        const milliseconds = moment
          .utc()
          .startOf('day')
          .valueOf();
        const form_data = {
          last_payment_plan_visit: milliseconds,
          payment_visit_courses: res?.data?.total_site_courses?.total_count,
          payment_visit_users: res?.data?.total_site_learners?.total_count
        };
        sendTrackingEvent(form_data).catch((err) => {});
        clarity.setTag(
          'last_payment_plan_visit',
          moment
            .utc()
            .startOf('day')
            .format('YYYY-MM-DD HH:mm:ss')
        );
        clarity.setTag('payment_visit_courses', `${res?.data?.total_site_courses?.total_count}`);
        clarity.setTag('payment_visit_users', `${res?.data?.total_site_learners?.total_count}`);
      })
      .catch((err) => {});
  }, []);

  return (
    <div className="block">
      <PricingCenter />
    </div>
  );
};

export default Pricing;
