import React, { useState, useEffect } from 'react';
import { getEdlySiteConfig, updateEdlySiteConfig } from 'services/siteConfiguration';
import { messageNotification } from 'helpers';
import ToggleItem from './toggleItem';
import { Button, Col, Row } from 'react-bootstrap';

const ToggleSection = () => {
  const [enableDisccussion, setEnableDisccussion] = useState(false);
  const [discovery, setDiscovery] = useState(false);
  const [userProfile, setUserProfile] = useState(false);
  const [profilePicture, setProfilePicture] = useState(false);
  const [videoTranscript, setVideoTranscript] = useState(false);
  const [courseVideo, setCourseVideo] = useState(false);
  const [courseDate, setCourseDate] = useState(false);
  const [courseShare, setCourseShare] = useState(false);

  useEffect(() => {
    getEdlySiteConfig().then((res) => {
      const mob_settings = res.data.lms;
      setEnableDisccussion(
        mob_settings.MOBILE_APP_CONFIG?.DISCUSSIONS_ENABLED ? mob_settings.MOBILE_APP_CONFIG.DISCUSSIONS_ENABLED : false
      );
      setDiscovery(
        mob_settings.MOBILE_APP_CONFIG?.DISCOVERY_ENABLED ? mob_settings.MOBILE_APP_CONFIG.DISCOVERY_ENABLED : false
      );
      setUserProfile(
        mob_settings.MOBILE_APP_CONFIG?.USER_PROFILES_ENABLED
          ? mob_settings.MOBILE_APP_CONFIG.USER_PROFILES_ENABLED
          : false
      );
      setProfilePicture(
        mob_settings.MOBILE_APP_CONFIG?.DISCUSSIONS_ENABLE_PROFILE_PICTURE_PARAM
          ? mob_settings.MOBILE_APP_CONFIG.DISCUSSIONS_ENABLE_PROFILE_PICTURE_PARAM
          : false
      );
      setVideoTranscript(
        mob_settings.MOBILE_APP_CONFIG?.VIDEO_TRANSCRIPT_ENABLED
          ? mob_settings.MOBILE_APP_CONFIG.VIDEO_TRANSCRIPT_ENABLED
          : false
      );
      setCourseVideo(
        mob_settings.MOBILE_APP_CONFIG?.COURSE_VIDEOS_ENABLED
          ? mob_settings.MOBILE_APP_CONFIG.COURSE_VIDEOS_ENABLED
          : false
      );
      setCourseDate(
        mob_settings.MOBILE_APP_CONFIG?.COURSE_DATES_ENABLED
          ? mob_settings.MOBILE_APP_CONFIG.COURSE_DATES_ENABLED
          : false
      );
      setCourseShare(
        mob_settings.MOBILE_APP_CONFIG?.COURSE_SHARING_ENABLED
          ? mob_settings.MOBILE_APP_CONFIG.COURSE_SHARING_ENABLED
          : false
      );
    });
  }, []);

  const updateMobileConfigValues = () => {
    const json_data = {
      lms: {
        MOBILE_APP_CONFIG: {
          DISCUSSIONS_ENABLED: enableDisccussion,
          DISCOVERY_ENABLED: discovery,
          USER_PROFILES_ENABLED: userProfile,
          DISCUSSIONS_ENABLE_PROFILE_PICTURE_PARAM: profilePicture,
          VIDEO_TRANSCRIPT_ENABLED: videoTranscript,
          COURSE_VIDEOS_ENABLED: courseVideo,
          COURSE_DATES_ENABLED: courseDate,
          COURSE_SHARING_ENABLED: courseShare
        }
      }
    };
    updateEdlySiteConfig(json_data)
      .then((res) => messageNotification('success', 'Mobile config data updated', res.data.success))
      .catch((error) => {
        messageNotification('danger', 'Error', `${error}`);
      });
  };

  return (
    <div>
      <div className="pt-4 mb-4">
        <ToggleItem
          title="Enable discussion tab"
          description="Discussions tab will be enabled and visible for all courses available for mobile app"
          checked={enableDisccussion}
          onChange={() => setEnableDisccussion((prevState) => !prevState)}
        />
        <ToggleItem
          title="Enable discovery tab"
          description="Discovery tab will be enabled and visible for all courses available for mobile. In this tab all those 
          courses which are not yet enrolled by users are visible"
          checked={discovery}
          onChange={() => setDiscovery((prevState) => !prevState)}
        />
        <ToggleItem
          title="Enable user profile"
          description="Users profile will be enabled and visible on the site. Users can set up and update their profiles "
          checked={userProfile}
          onChange={() => setUserProfile((prevState) => !prevState)}
        />
        <ToggleItem
          title="Enable profile picture in discussions"
          description="User profile image will be visible against the discussion posts"
          checked={profilePicture}
          onChange={() => setProfilePicture((prevState) => !prevState)}
        />
        <ToggleItem
          title="Enable video transcript"
          description="Video transcript will be visible for videos having transcripts"
          checked={videoTranscript}
          onChange={() => setVideoTranscript((prevState) => !prevState)}
        />
        <ToggleItem
          title="Enable course video"
          description="Video tab will be visible inside the course "
          checked={courseVideo}
          onChange={() => setCourseVideo((prevState) => !prevState)}
        />
        <ToggleItem
          title="Enable course sharing"
          description="Course sharing button will be visible with in the course"
          checked={courseShare}
          onChange={() => setCourseShare((prevState) => !prevState)}
        />
        <ToggleItem
          title="Enable important dates tab"
          description="This tab will display important course dates. For example course start and end dates"
          checked={courseDate}
          onChange={() => setCourseDate((prevState) => !prevState)}
        />
      </div>
      <Row className="d-flex justify-content-end">
        <Col xs="auto">
          <Button onClick={updateMobileConfigValues}>Save</Button>
        </Col>
      </Row>
    </div>
  );
};

export default ToggleSection;
