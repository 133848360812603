import React from 'react';
import { Form } from 'react-bootstrap';

const FeebackButtons = ({ range, updateValue }) => {
  return (
    <div className="rating-holder d-flex align-items-center justify-content-center">
      {Array(range)
        .fill(null)
        .map((_, index) => {
          const val = index + 1;
          return (
            <Form.Group className="mb-3 ms-2 me-2 ms-md-3 me-md-3" key={val}>
              <Form.Label htmlFor={`nps-val-${val}`}>{val}</Form.Label>
              <Form.Check
                type="radio"
                value={val}
                id={`nps-val-${val}`}
                name="nps-val-radio"
                onChange={() => updateValue(val)}
              />
            </Form.Group>
          );
        })}
    </div>
  );
};

export default FeebackButtons;
