import React from 'react';
import { IconInfoTriangle } from '@tabler/icons-react';

const FooterNote = () => {
  return (
    <p className="footer-note">
      <IconInfoTriangle stroke={1.5} />
      <span>
        *If a learner is converted to a staff user, they will no longer be counted as a learner. However, their learner
        history will still be included in calculation of course enrollments and completions.
      </span>
    </p>
  );
};

export default FooterNote;
