import React from 'react';
import './index.scss';
import SegmentKeyInput from './containers/components/segmentKeyInput';
import LmsKeyInput from './containers/components/lmsKeyInput';
import MarketingKeyInput from './containers/components/marketingKeyInput';

function Analytics() {
  return (
    <div className="form-container profile-form">
      <h2 className="mb-4">Analytics Integration</h2>
      <MarketingKeyInput />
      <LmsKeyInput />
      <SegmentKeyInput />
    </div>
  );
}

export default Analytics;
