import React, { useState } from 'react';
import './segmentKeyInput.css';
import { messageNotification } from 'helpers';
import { updateEdlySiteConfig } from 'services/siteConfiguration';
import { SEGMENT_KEY_PLACEHOLDER } from '../constants/index';
import { Row, Col, Form, Button } from 'react-bootstrap';

const SegmentKeyInput = () => {
  const [segmentKey, setSegmentKey] = useState('');

  const updateKey = () => {
    if (segmentKey === '') {
      messageNotification('danger', 'Invalid Input', 'You must fill segment key field');
      return;
    }

    const json_data = {
      lms: {
        DJANGO_SETTINGS_OVERRIDE: {
          LMS_SEGMENT_KEY: `${segmentKey}`,
          CMS_SEGMENT_KEY: `${segmentKey}`
        }
      },
      cms: {
        DJANGO_SETTINGS_OVERRIDE: {
          LMS_SEGMENT_KEY: `${segmentKey}`,
          CMS_SEGMENT_KEY: `${segmentKey}`
        }
      }
    };
    updateEdlySiteConfig(json_data)
      .then((res) => messageNotification('success', 'Key updated', res.data.success))
      .catch((error) => {
        messageNotification('danger', 'Error', `${error}`);
      });
  };
  return (
    <div className="integration-holder mb-4">
      <div className="subheader-holder mb-4">
        <h4>Segment</h4>
        <p>
          Adding segment's key will send users interaction data to the segment, which can be analyzed in a variety of
          analytics tools.
        </p>
      </div>
      <Row>
        <Col xs="12" lg="6">
          <Form.Group className="mb-3">
            <Form.Label>Segment key</Form.Label>
            <Form.Control
              value={segmentKey}
              placeholder={SEGMENT_KEY_PLACEHOLDER}
              onChange={(event) => setSegmentKey(event.target.value)}
            />
          </Form.Group>
        </Col>
        <Col xs="12" className="btn-holder justify-content-end d-flex">
          <Button onClick={updateKey}>Save</Button>
        </Col>
      </Row>
    </div>
  );
};

export default SegmentKeyInput;
