import { useState } from 'react';
import LtiConfigurationForm from './ltiConfigurationForm';
import Confirmation from './confirmation';
import { Table } from 'react-bootstrap';
import { IconPencil, IconTrash } from '@tabler/icons-react';

const LtiConfigurationsList = ({ ltiConfigurations }) => {
  const [editMode, setEditMode] = useState(false);
  const [config, setConfig] = useState({});
  const [deleteParams, setDeleteParams] = useState({});
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const ltiVersion = (version) => version.replace('lti_', ' ').replace('p', '.');

  const hideConfirmation = () => setDeleteConfirmation(false);
  const editConfiguration = (configuration) => {
    setEditMode(true);
    setConfig(configuration);
  };
  const deleteConfiguration = (configuration) => {
    const params = {};
    if (configuration['course_key']) {
      params['course_key'] = configuration['course_key'];
    }
    params['version'] = configuration.version;
    setDeleteParams(params);
    setDeleteConfirmation(true);
  };
  return (
    <>
      <div className="table-holder">
        <Table className="dns-table align-middle">
          <thead>
            <tr>
              <th>Course ID</th>
              <th>LTI Version</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {ltiConfigurations.map((row, rowIndex) => (
              <tr key={rowIndex}>
                <td data-title="Course ID">
                  {row.course_key ? row.course_key : 'Enabled organization-wide for ' + row.organization_slug}
                </td>
                <td data-title="LTI Version">{ltiVersion(row.version)}</td>
                <td data-title="Actions" onClick={() => {}}>
                  <IconPencil stroke={1.5} onClick={() => editConfiguration(row)} />
                  <IconTrash className="ms-2" stroke={1.5} onClick={() => deleteConfiguration(row)} />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <LtiConfigurationForm
        isFormOpen={editMode}
        editMode={editMode}
        configuration={config}
        hideModal={() => setEditMode(false)}
      />
      <Confirmation isOpen={deleteConfirmation} deleteParams={deleteParams} hideModal={hideConfirmation} />
    </>
  );
};

export default LtiConfigurationsList;
