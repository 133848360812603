export const themes = {
  TOGGLE_THEME_MODE: 'TOGGLE_THEME_MODE'
};

const toogleThemeMode = (payload) => {
  return {
    type: themes.TOGGLE_THEME_MODE,
    payload: payload
  };
};

export const themeDispatches = {
  toogleThemeMode
};
