import React, { useEffect, useState } from 'react';

import { getEdlySiteConfig, updateEdlySiteConfig } from 'services/siteConfiguration';
import 'pages/integrations/containers/DiscussionContainer/Components/DisableStyle.css';

import { messageNotification } from 'helpers';

const DiscussionDisable = ({ badges, dispatch }) => {
  const [checked, setCheckedBox] = useState(false);
  useEffect(() => {
    getEdlySiteConfig()
      .then((res) => {
        const settings = res.data.lms.DJANGO_SETTINGS_OVERRIDE;
        setCheckedBox(settings.FEATURES.ENABLE_DISCUSSION_SERVICE);
      })
      .catch((error) => {
        messageNotification('danger', 'Error', `${error}`);
      });
  }, []);

  const handleClick = async () => {
    const updateCheck = !checked;
    const data = {
      lms: {
        DJANGO_SETTINGS_OVERRIDE: {
          FEATURES: {
            ENABLE_DISCUSSION_HOME_PANEL: updateCheck,
            ENABLE_DISCUSSION_SERVICE: updateCheck
          }
        }
      }
    };
    updateEdlySiteConfig(data)
      .then((res) =>
        messageNotification(
          'success',
          'Key updated',
          `Discussion forum has been ${updateCheck ? 'enabled' : 'disabled'}`
        )
      )
      .catch((error) => {
        messageNotification('danger', 'Error', `${error}`);
      });
    setCheckedBox(updateCheck);
  };

  return (
    <div className="integration-holder mb-4">
      <h4 className="mb-4">Discussion Forum </h4>
      <div className="subheader-holder">
        <h5>1. Enable/Disable Discussion Forum</h5>
        <div className="checkbox_Container">
          <p>Toggle the button to {`${!checked ? 'enable' : 'disable'}`} discussion forum</p>
          <input type="checkbox" checked={checked} className="checkbox" onChange={handleClick} />
        </div>
      </div>
    </div>
  );
};

export default DiscussionDisable;
