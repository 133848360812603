import React, { useEffect, useState } from 'react';
import { IconInfoCircle } from '@tabler/icons-react';
import { connect } from 'react-redux';
import { Table } from 'react-bootstrap';

import { messageNotification } from 'helpers';
import { getOrganization } from 'selectors';
import { mausService } from 'services/maus';
import { apiDateFormat, displayFormat, nanoSecondsToSecond } from 'pages/pricing/containers/pricingContainer/constants';

const moment = require('moment');

const BillingDetails = (props) => {
  const { expected_billing, renewal_expiry, current_site_plan } = props?.org;
  const [learnerData, setLearnerData] = useState({
    sub_organization: [],
    total_learners: 0,
    organization: ''
  });

  useEffect(() => {
    const fetchAllSiteData = async () => {
      const expiryDate = current_site_plan === 'trial' ? renewal_expiry : renewal_expiry * nanoSecondsToSecond;
      const endDate = moment(expiryDate);
      const payload = {
        type: 'custom',
        roles: 'learner',
        start_date: endDate
          .clone()
          .subtract(1, 'M')
          .format(apiDateFormat),
        course_activity_filter: false,
        end_date: endDate.format(apiDateFormat)
      };
      try {
        const response = await mausService.requestAllSiteMAUs(payload);
        setLearnerData(response.data);
      } catch (err) {
        messageNotification(
          'danger',
          'There was an error fetching MALs',
          err?.response?.data?.error || 'Error Fetching the site learners'
        );
      }
    };

    fetchAllSiteData();
  }, []);

  return (
    <>
      <h4 className="mb-4">
        Billing Details for <strong style={{ textTransform: 'capitalize' }}>{learnerData.organization}</strong>
      </h4>
      <Table className="align-middle">
        <thead>
          <tr>
            <th>Sites</th>
            <th className="text-end">Monthly active learners</th>
          </tr>
        </thead>
        <tbody>
          {learnerData?.sub_organization.map((item, index) => {
            return (
              <tr key={index}>
                <td>{item.name}</td>
                <td className="text-end">{item.learner_count}</td>
              </tr>
            );
          })}

          <tr>
            <td>
              <strong className="me-2">Total MAL's</strong>
              <span className="tool-tip">
                <IconInfoCircle stroke={1.5} />
                <span className="tip-content">Monthly Active learners</span>
              </span>
            </td>
            <td className="text-end">{learnerData.total_learners}</td>
          </tr>
          <tr>
            <td colSpan="2" className="text-end">
              <div>
                <span>
                  {current_site_plan === 'trial' ? (
                    <>
                      Plan Expiry Date: <strong>{`${moment(renewal_expiry).format(displayFormat)}`}</strong>
                    </>
                  ) : (
                    <>
                      Next Billing Date: <strong>{` ${moment(renewal_expiry * 1000).format(displayFormat)}`}</strong>{' '}
                    </>
                  )}
                </span>
                <span className="ms-4">
                  Expected bill this month: <strong> ${expected_billing || 0}</strong>
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

function mapStateToProps(state) {
  const org = getOrganization(state);

  return {
    org
  };
}

export default connect(mapStateToProps, null)(BillingDetails);
