import React from 'react';

import StatsBox from 'components/analytics/statsBox';
import { Row, Col } from 'react-bootstrap';

const LearnersAnalyticsSummary = (props) => {
  const {
    site_daily_metrics,
    site_monthly_metrics,
    total_site_learners,
    current_learners_maus,
    previous_learners_maus
  } = props;

  return (
    <Row className="learner-analytics-stats">
      <Col xs="12" md="6" lg="4" className="mb-4">
        <StatsBox
          value={total_site_learners}
          label="Total Learners"
          description="Number of learners who have registered on the platform since launch."
        />
      </Col>

      <Col xs="12" md="6" lg="4" className="mb-4">
        <StatsBox
          value={
            site_monthly_metrics.current_month.new_learners ? site_monthly_metrics.current_month.new_learners : '0'
          }
          label="New Learner Registrations (Current Month)"
          description="Number of learners who have registered during the current calendar month."
        />
      </Col>

      <Col xs="12" md="6" lg="4" className="mb-4">
        <StatsBox
          value={site_monthly_metrics.last_month.new_learners ? site_monthly_metrics.last_month.new_learners : '0'}
          label="New Learner Registrations (Last Month)"
          description="Number of learners who have registered during the last calendar month."
        />
      </Col>

      <Col xs="12" md="6" lg="4" className="mb-4">
        <StatsBox
          value={current_learners_maus}
          label="Monthly Active Learners (Current Month)"
          description="Numbers of learners who were active in the current calendar month."
        />
      </Col>

      <Col xs="12" md="6" lg="4" className="mb-4">
        <StatsBox
          value={previous_learners_maus}
          label="Monthly Active Learners (Last Month)"
          description="Numbers of learners who were active in the last calendar month."
        />
      </Col>

      <Col xs="12" md="6" lg="4" className="mb-4">
        <StatsBox
          value={site_daily_metrics.todays_active_learners_count}
          label="Active Learners Today"
          description="Numbers of learners who were active today."
        />
      </Col>
    </Row>
  );
};

export default LearnersAnalyticsSummary;
