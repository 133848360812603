import React, { useState } from 'react';
import Slider from 'react-slick';
import { IconEye } from '@tabler/icons-react';

import { themesActions } from 'pages/appearance/containers/appearanceContainer/actions/themes';
import ImagePreviewSlider from 'pages/appearance/containers/appearanceContainer/components/imagePreviewSlider';
import 'pages/appearance/index.scss';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import { Button } from 'react-bootstrap';

const ThemePreview = (props) => {
  const { theme, activeTheme, setActiveTheme, organization, dispatch } = props;
  const [imageModalShow, setImageModalShow] = useState(false);
  const [indexThemeImagePreview, setIndexThemeImagePreview] = useState(0);

  const handleThemeChange = (e) => {
    const { id, value } = e.target;
    setActiveTheme(value);
    dispatch(
      themesActions.updateThemeConfiguration({
        theme_id: id,
        theme_name: value,
        organization_id: organization.id
      })
    );
  };

  const settings = {
    infinite: false,
    slidesToShow: 3,
    centerPadding: '50px',
    slidesToScroll: 1,
    speed: 500,
    initialSlide: 0,
    prevArrow: <IconChevronLeft stroke={1.5} />,
    nextArrow: <IconChevronRight stroke={1.5} />,
    responsive: [
      {
        breakpoint: 1290,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 1170,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  const previewImages = theme.theme_previews.map((themePreview, index) => {
    return (
      <div
        key={index}
        onClick={() => {
          setIndexThemeImagePreview(index);
          setImageModalShow(true);
        }}
        className="theme-slide"
      >
        <h2 className="title">
          <span className="txt">{themePreview.service_name}</span>
        </h2>
        <div className="image-post">
          <img src={themePreview.preview_image} alt={themePreview.service_name} />
          <span className="ico-view">
            <IconEye stroke={1.5} />
          </span>
        </div>
      </div>
    );
  });

  return (
    <div className={`theme-card `}>
      <Slider {...settings}>{previewImages}</Slider>
      {theme.theme_name !== activeTheme && (
        <div className="d-flex justify-content-end align-items-end">
          <Button className="mt-3 d-none" id={theme.id} value={theme.theme_name} onClick={handleThemeChange}>
            Activate
          </Button>
        </div>
      )}
      <ImagePreviewSlider
        showSlider={imageModalShow}
        themePreviews={theme.theme_previews}
        currentIndex={indexThemeImagePreview}
        onHide={() => {
          setImageModalShow(false);
        }}
      />
    </div>
  );
};

export default ThemePreview;
