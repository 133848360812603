import React from 'react';

import 'components/footer/index.scss';
import { Container } from 'react-bootstrap';

const Footer = () => {
  return (
    <footer className="footer pt-2 pb-4">
      <small>&copy; Edly {new Date().getFullYear()}. All rights reserved</small>
    </footer>
  );
};

export default Footer;
