import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button, Modal } from 'react-bootstrap';
import csvUploader from 'assets/images/upload-csv-placeholder.svg';

import { usersActions } from 'pages/users/containers/usersContainer/actions';
import ProgressBar from 'pages/users/containers/usersContainer/components/progressBar';

const BulkUser = (props) => {
  const { message, setMessage, inputFile, setInputFile, progressStart, setProgressStart } = props;
  const [progress, setProgress] = useState(0);

  const onDrop = useCallback(
    (uploadedFiles) => {
      setInputFile(uploadedFiles[0]);
      setProgress(0);
      setProgressStart(false);
      setMessage('');
    },
    [setInputFile, setProgress, setProgressStart, setMessage]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const uploadButtonHandler = (event) => {
    const data = new FormData();
    data.set('uploaded-file', inputFile);
    setProgressStart(true);
    props.dispatch(
      usersActions.uploadBulkUsersFile(data, setMessage, (event) => {
        setProgress(Math.round((100 * event.loaded) / event.total));
      })
    );
  };

  const uploadCancelHandler = (event) => {
    setProgressStart(false);
    setMessage('');
    setProgress(false);
    setInputFile(null);
  };

  const templateButtonHandler = (e) => {
    e.preventDefault();
    props.dispatch(usersActions.getRegistrationTemplate());
  };

  return (
    <div>
      <Modal onHide={props.onHide} show={props.show} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Add bulk users</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="csv-main">
            <div {...getRootProps()}>
              <div className="csv-img-container">
                <img className="csv-img" src={csvUploader} alt="csv uploader" />
              </div>
              <input {...getInputProps()} />
              <div>
                <h4 className="csv-text">
                  {inputFile && progress !== 100 ? inputFile.name : 'Drag your CSV file or browse to upload'}
                </h4>
              </div>
            </div>
            {!inputFile && (
              <div className="csv-help">
                <span className="csv-note">Note: </span>Please check out the sample CSV file
                <button className="csv-button" onClick={templateButtonHandler}>
                  here
                </button>
                to make sure you follow the correct file format.
              </div>
            )}
            <div>
              {progressStart && (
                <ProgressBar
                  bgColor="#4DAF70"
                  progress={progress}
                  setProgressStart={setProgressStart}
                  uploadCancelHandler={uploadCancelHandler}
                  height="20"
                />
              )}
              <div className="csv-alert-box">{message}</div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            disabled={!inputFile || progress === 100}
            className={
              inputFile && progress !== 100
                ? 'btn-block btn-continue btn-enabled'
                : 'btn-block btn-continue btn-disabled'
            }
            onClick={uploadButtonHandler}
          >
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default BulkUser;
