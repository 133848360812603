import React, { useEffect, useState } from 'react';
import { Row, Col, Modal, Button, Form } from 'react-bootstrap';
import { clarity } from 'react-microsoft-clarity';
import { IconInfoCircle } from '@tabler/icons-react';

import { getWordpressBaseUrl } from 'helpers';
import { usersActions } from 'pages/users/containers/usersContainer/actions';
import PanelRolesButton from 'pages/users/containers/usersContainer/components/panelRolesButton';
import { sendTrackingEvent } from 'services/tracking';

const AddUser = (props) => {
  const { validation_messages, page, searchText, roles, pageSize } = props;
  const [inputs, setInputs] = useState({
    username: '',
    name: '',
    email: '',
    is_staff: true,
    is_active: true,
    is_superuser: false,
    lms_role: 'learner',
    wordpress_role: 'subscriber',
    panel_role: 'panel_restricted'
  });
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const username_value = validation_messages?.username_and_name?.username;
  const name_value = validation_messages?.username_and_name?.name;

  const resetInputs = () => {
    setInputs({
      username: '',
      name: '',
      email: '',
      is_staff: true,
      is_active: true,
      is_superuser: false,
      lms_role: 'learner',
      wordpress_role: 'subscriber',
      panel_role: 'panel_restricted'
    });
  };

  const handleInputChange = (event) => {
    event.persist();
    if (event.target.name === 'is_active_user') {
      setInputs((inputs) => ({ ...inputs, is_active: event.target.checked }));
    } else {
      setInputs((inputs) => ({ ...inputs, [event.target.name]: event.target.value }));
      if (inputs.email && inputs.username && inputs.name) {
        setButtonDisabled(false);
      } else {
        setButtonDisabled(true);
      }
    }
  };

  const handleValidation = (event) => {
    event.persist();
    props.dispatch(usersActions.validateUser(inputs));
    if (inputs.email && inputs.username && inputs.name) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let payload = inputs;
    payload['page_number'] = page;
    payload['searchText'] = searchText;
    payload['roles'] = roles;
    payload['page_size'] = pageSize;
    setButtonDisabled(true);
    props.dispatch(usersActions.validateUser(inputs, usersActions.addNewUser(payload, props.onHide, resetInputs)));
    sendTrackingEvent({ has_created_user: 'true' }).catch((err) => {});
    clarity.setTag('has_created_user', 'true');
  };

  useEffect(() => {
    if (username_value && name_value) {
      setInputs((inputs) => ({ ...inputs, username: username_value }));
      setInputs((inputs) => ({ ...inputs, name: name_value }));
      setButtonDisabled(false);
    }
  }, [username_value, name_value]);

  return (
    <div>
      <Modal onHide={props.onHide} show={props.show} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Add new user</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs="12" lg="6">
              <Form.Group className="mb-3">
                <Form.Label>
                  Username <span className="required">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  id="username"
                  name="username"
                  onChange={handleInputChange}
                  onBlur={handleValidation}
                  value={inputs.username}
                  required
                  disabled={username_value ? true : false}
                />
                {username_value ? null : (
                  <span className="text-error form-text">{validation_messages['username']}</span>
                )}
              </Form.Group>
            </Col>
            <Col xs="12" lg="6">
              <Form.Group className="mb-3">
                <Form.Label>
                  Full Name <span className="required">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  id="name"
                  name="name"
                  onChange={handleInputChange}
                  onBlur={handleValidation}
                  value={inputs.name}
                  required
                  disabled={name_value ? true : false}
                />
                {name_value ? null : <span className="text-error form-text">{validation_messages['name']}</span>}
              </Form.Group>
            </Col>
            <Col xs="12">
              <Form.Group className="mb-3">
                <Form.Label>
                  Email <span className="required">*</span>
                </Form.Label>
                <Form.Control
                  type="email"
                  id="email"
                  name="email"
                  onChange={handleInputChange}
                  onBlur={handleValidation}
                  value={inputs.email}
                  required
                />
                <span className="text-error form-text">{validation_messages['email']}</span>
              </Form.Group>
            </Col>
            <Col xs="12">
              <Form.Check className="mb-4">
                <Form.Check.Input
                  type="checkbox"
                  id="active"
                  name="is_active_user"
                  value={inputs.is_active}
                  onChange={handleInputChange}
                  checked={inputs.is_active}
                />
                <Form.Check.Label htmlFor="active">Active</Form.Check.Label>
              </Form.Check>
            </Col>
          </Row>
          <PanelRolesButton inputs={inputs} handleInputChange={handleInputChange} is_active="true" />
          {getWordpressBaseUrl() && (
            <Row className="mb-4">
              <Col xs="12" lg="4">
                <h6>Discovery Role</h6>
              </Col>
              <Col xs="12" lg="8">
                <Row>
                  <Col xs="12" md="6" className="has-tip-info mb-3">
                    <Form.Check
                      type="radio"
                      label="Subscriber"
                      name="wordpress_role"
                      id="subscriber_filter"
                      onClick={handleInputChange}
                      check={(inputs.wordpress_role.toString() === 'subscriber').toString()}
                      value="subscriber"
                      defaultChecked
                    />
                    <span className="tool-tip">
                      <IconInfoCircle stroke={1.5} />
                      <span className="tip-content">
                        <span className="tip-content-item">
                          User is registered on the Discovery website but does not have WordPress Admin access
                        </span>
                        <span className="tip-content-item">User cannot make any changes to the WordPress website</span>
                      </span>
                    </span>
                  </Col>
                  <Col xs="12" md="6" className="has-tip-info mb-3">
                    <Form.Check
                      type="radio"
                      label="Edly Admin"
                      name="wordpress_role"
                      id="edly_admin_filter"
                      onClick={handleInputChange}
                      check={(inputs.wordpress_role.toString() === 'edly_admin').toString()}
                      value="edly_admin"
                    />
                    <span className="tool-tip">
                      <IconInfoCircle stroke={1.5} />
                      <span className="tip-content">
                        <span className="tip-content-item">User has full admin access to the Discovery website</span>
                        <span className="tip-content-item">
                          User can customize the WordPress website, add/delete pages and has editing access to the
                          WordPress Administration panel
                        </span>
                      </span>
                    </span>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
          <Row className="mb-0">
            <Col xs="12" lg="4">
              <h6>LMS Role</h6>
            </Col>
            <Col xs="12" lg="8">
              <Row>
                <Col xs="12" md="6" className="has-tip-info mb-3">
                  <Form.Check
                    type="radio"
                    label="Learner"
                    name="lms_role"
                    id="learner_filter"
                    onClick={handleInputChange}
                    check={(inputs.lms_role.toString() === 'learner').toString()}
                    value="learner"
                    defaultChecked
                  />
                  <span className="tool-tip">
                    <IconInfoCircle stroke={1.5} />
                    <span className="tip-content">
                      <span className="tip-content-item">User cannot create a course or library</span>
                      <span className="tip-content-item">User cannot see/edit any course</span>
                      <span className="tip-content-item">User can request to become a site creator</span>
                      <span className="tip-content-item">User does not have access to Studio</span>
                    </span>
                  </span>
                </Col>
                <Col xs="12" md="6" className="has-tip-info mb-3">
                  <Form.Check
                    type="radio"
                    label="Staff"
                    name="lms_role"
                    id="staff_filter"
                    onClick={handleInputChange}
                    check={(inputs.lms_role.toString() === 'learner').toString()}
                    value="staff"
                  />
                  <span className="tool-tip">
                    <IconInfoCircle stroke={1.5} />
                    <span className="tip-content">
                      <span className="tip-content-item">User can create a course or library</span>
                      <span className="tip-content-item">
                        User can view/edit/archive/publish all courses on the current site even if he/she is not a part
                        of the course team
                      </span>
                      <span className="tip-content-item">User can add/remove team members to a course team</span>
                    </span>
                  </span>
                </Col>
                <Col xs="12" md="6" className="has-tip-info mb-0">
                  <Form.Check
                    type="radio"
                    label="Course Creator"
                    name="lms_role"
                    id="course_creator_filter"
                    onClick={handleInputChange}
                    check={(inputs.lms_role.toString() === 'course_creator').toString()}
                    value="course_creator"
                  />
                  <span className="tool-tip">
                    <IconInfoCircle stroke={1.5} />
                    <span className="tip-content">
                      <span className="tip-content-item">User can create a course or library</span>
                      <span className="tip-content-item">User can view/edit all courses created by them</span>
                      <span className="tip-content-item">
                        User can view/edit courses created by other users if they are a part of the course team
                      </span>
                    </span>
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={props.onHide}>
            Cancel
          </Button>
          <Button variant="primary" disabled={buttonDisabled} onClick={handleSubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddUser;
