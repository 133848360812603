import React from 'react';
import { CSVLink } from 'react-csv';
import { IconDownload } from '@tabler/icons-react';
import { DropdownButton, Dropdown } from 'react-bootstrap';

import { learnersAnalyticsActions } from 'pages/insights/containers/learnersAnalyticsContainer/actions';
import { messageNotification, prepareLearnersDashboardCSVData } from 'helpers';

const moment = require('moment');

const DownloadLearnersAnalytics = (props) => {
  const { all_learners_analytics, dispatch } = props;
  const learnersAnalyticsData = prepareLearnersDashboardCSVData(props);

  const handleFilterChange = (value) => {
    let data_exportable =
      all_learners_analytics.length > 0 || Object.getOwnPropertyNames(all_learners_analytics).length > 1;

    if (value === 'pdf') {
      dispatch(learnersAnalyticsActions.downloadLearnersDetailsPDF());
    } else if (value === 'csv') {
      dispatch(
        learnersAnalyticsActions.fetchLearnersSummaryCSV({
          filter_roles: 'learner',
          filter_start_date: moment()
            .startOf('month')
            .format('DD-MM-YYYY'),
          filter_end_date: moment()
            .endOf('month')
            .format('DD-MM-YYYY')
        })
      );
    }

    if (value === 'pdf') {
      messageNotification('success', 'Learners Analytics Export', 'Learners Analytics Report will be emailed soon.');
    } else if (value !== '' && data_exportable) {
      messageNotification('success', 'Learners Analytics Export', 'Learners Analytics Data Export In Progress');
    }
  };

  return (
    <div id="learner_download_dropdowns">
      <DropdownButton variant="outline-primary" title={'Export Report'}>
        <Dropdown.Item onClick={() => handleFilterChange('pdf')}>Email PDF</Dropdown.Item>
        <Dropdown.Item onClick={() => handleFilterChange('csv')}>Email CSV</Dropdown.Item>
      </DropdownButton>

      <CSVLink
        className="btn btn-outline-primary mt-3 mt-sm-0 ms-sm-3 learners_analytics d-none"
        id="learners_analytics_csv"
        filename="learners_analytics.csv"
        data={learnersAnalyticsData}
      >
        <IconDownload stroke={1.5} />
        <span className="text ms-2">
          <span className="d-none-md">Download</span> CSV
        </span>
      </CSVLink>
    </div>
  );
};

export default DownloadLearnersAnalytics;
