import React, { useState, useEffect } from 'react';
import { messageNotification } from 'helpers';
import { Form } from 'react-bootstrap';
import { getEdlySiteConfig, updateEdlySiteConfig, updateWpSiteConfig } from 'services/siteConfiguration';

const PublicRegistration = () => {
  const [disablePublicRegistration, setDisablePublicRegistration] = useState();

  useEffect(() => {
    getEdlySiteConfig().then((res) => {
      setDisablePublicRegistration(
        res.data.lms.ALLOW_PUBLIC_ACCOUNT_CREATION !== undefined ? !res.data.lms.ALLOW_PUBLIC_ACCOUNT_CREATION : false
      );
    });
  }, []);

  const togglePublicRegistration = () => {
    const lms_json_data = {
      lms: {
        ALLOW_PUBLIC_ACCOUNT_CREATION: disablePublicRegistration
      }
    };

    const wp_json_data = {
      toggle_reg_btn: `${disablePublicRegistration}`
    };
    updateWpSiteConfig(wp_json_data)
      .then((res) => {
        !disablePublicRegistration &&
          messageNotification(
            'success',
            'Public registration toggled on',
            'Public registration toggled on for marketing site'
          );

        disablePublicRegistration &&
          messageNotification(
            'success',
            'Public registration toggled off',
            'Public registration toggled off for marketing site'
          );
      })
      .catch((error) => {
        messageNotification('danger', 'Error', `${error}`);
      });

    updateEdlySiteConfig(lms_json_data)
      .then((res) => {
        !disablePublicRegistration &&
          messageNotification(
            'success',
            'Public registration toggled on',
            'Public registration toggled on for LMS site'
          );

        disablePublicRegistration &&
          messageNotification(
            'success',
            'Public registration toggled off',
            'Public registration toggled off for LMS site'
          );
      })
      .catch((error) => {
        messageNotification('danger', 'Error', `${error}`);
      });
    setDisablePublicRegistration(!disablePublicRegistration);
  };
  return (
    <div className="subheader-holder">
      <h4>Public Registration</h4>
      <p>
        Disabling public registration will not allow users to self-register. If disabled then the standard registration
        form on '/register' URL is disabled and the register button is removed from the site header
      </p>
      <div className="custom-control d-flex align-items-start custom-checkbox course-activity-filter">
        <Form.Check
          label="Disable public registration"
          id="publicRegistration"
          type="checkbox"
          onChange={togglePublicRegistration}
          checked={disablePublicRegistration}
        />
      </div>
    </div>
  );
};

export default PublicRegistration;
