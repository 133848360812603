import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import moment from 'moment';

import { getOrganization } from 'selectors';
import FeebackButtons from 'pages/dashboard/containers/npsDialog/components/feebackButtons';
import { npsActions } from 'pages/dashboard/containers/npsDialog/actions';
import {
  NPS_AFTER_CREATED_DAYS_COUNT,
  NPS_REMIND_DAYS_COUNT,
  npsFields
} from 'pages/dashboard/containers/npsDialog/constants';
import 'pages/dashboard/index.scss';

const NpsDialog = (props) => {
  const { show_nps_dialog, updateNps } = props;
  const [isOpen, setIsOpen] = useState(show_nps_dialog);
  const [npsValue, setNpsValue] = useState(null);

  const hideModal = () => {
    setIsOpen(false);
  };

  const handleResponse = (data) => {
    const nps_data = { nps: data };
    updateNps(nps_data, hideModal);
  };

  const handleValueSubmit = () => {
    handleResponse({
      [npsFields.value]: npsValue
    });
  };

  const handleRemindLater = () => {
    const currentDate = new Date();
    handleResponse({
      [npsFields.last_seen]:
        currentDate.getFullYear() + '-' + (currentDate.getMonth() + 1) + '-' + currentDate.getDate()
    });
  };

  return (
    <div className="block">
      <Modal show={isOpen} onHide={hideModal} size="lg" backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Feedback</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <p>How likely are you to recommend Edly to a friend?</p>
            <FeebackButtons range={10} updateValue={setNpsValue} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={handleRemindLater}>
            Remind Me Later
          </Button>
          <Button onClick={handleValueSubmit} disabled={!npsValue}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

function mapStateToProps(state) {
  const { nps_data, created } = getOrganization(state);
  let show_nps_dialog = false;
  const days_ago_created = moment().diff(moment(created), 'days');
  if (nps_data) {
    const { last_seen, closed, value } = nps_data;
    const days_ago_last_seen = moment().diff(moment(last_seen, 'YYYY-MM-DD'), 'days');
    show_nps_dialog = !closed && !value && days_ago_created >= NPS_AFTER_CREATED_DAYS_COUNT;
    if (last_seen && days_ago_last_seen < NPS_REMIND_DAYS_COUNT) {
      show_nps_dialog = false;
    }
  } else if (days_ago_created >= NPS_AFTER_CREATED_DAYS_COUNT) {
    show_nps_dialog = true;
  }
  return { show_nps_dialog };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators({ ...npsActions }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NpsDialog);
