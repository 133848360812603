import React, { useEffect, useState } from 'react';
import 'pages/appearance/index.scss';
import { Row, Col, Button } from 'react-bootstrap';

import ColorPicker from './containers/appearanceContainer/components/colorPicker';
import ThemesCenter from 'pages/appearance/containers/appearanceContainer/appearanceCenter';
import { FileUploader } from './containers/appearanceContainer/components/fileUploader';
import { updateAppearanceSettings } from 'services/appearance';
import { messageNotification } from 'helpers';
import { getEdlySiteConfig } from 'services/siteConfiguration';

const Appearance = () => {
  const [primaryColor, setPrimaryColor] = useState('');
  const [secondaryColor, setSecondaryColor] = useState('');
  const [logo, setLogo] = useState(null);
  const [favicon, setFavicon] = useState(null);
  const [theme, setTheme] = useState(null);

  const handleSetPrimaryColor = (color) => {
    setPrimaryColor(color);
  };

  const handleSetSecondaryColor = (color) => {
    setSecondaryColor(color);
  };

  const handleLogoUpload = (file) => {
    setLogo(file);
  };

  const handleFaviconUpload = (file) => {
    setFavicon(file);
  };

  const handleThemeChange = (theme) => {
    setTheme(theme);
  };

  const validateColor = (color, colorName) => {
    if (!color.startsWith('#')) {
      messageNotification('danger', 'Invalid Color', `Please select a correct value for ${colorName} - ${color}.`);
      return false;
    }
    return true;
  };

  const handleSaveButton = () => {
    if (!validateColor(primaryColor, 'primary color') || !validateColor(secondaryColor, 'secondary color')) {
      return;
    }

    if (primaryColor === '' && secondaryColor === '' && !logo && !favicon && !theme) {
      messageNotification('warning', 'Nothing Updated', 'No values were changed');
      return;
    }

    const appearance_data = {
      ...(primaryColor && { primary_color: primaryColor }),
      ...(secondaryColor && { secondary_color: secondaryColor }),
      ...(logo && { logo: logo }),
      ...(favicon && { favicon: favicon }),
      ...(theme && { site_theme: theme })
    };

    updateAppearanceSettings(appearance_data)
      .then((res) =>
        messageNotification(
          'success',
          'Appearance updated',
          `The following apperance metrics have been updated:
          ${theme ? 'Site Theme: ' + theme : ''}
          ${primaryColor && 'Primary Color: ' + primaryColor}
          ${secondaryColor && 'Secondary Color: ' + secondaryColor}
          ${logo ? 'Logo' : ''}
          ${favicon ? 'Favicon' : ''}`
        )
      )
      .catch((error) => {
        messageNotification('danger', 'Error', `${error}`);
      });
  };

  useEffect(() => {
    getEdlySiteConfig().then((res) => {
      setPrimaryColor(res.data.lms.COLORS.primary);
      setSecondaryColor(res.data.lms.COLORS.secondary);
    });
  }, []);

  return (
    <div className="block">
      <h2 className="mb-4">Appearance</h2>
      <div className="themes-holder mb-4">
        <h4>Change theme</h4>
        <ThemesCenter onUpdate={handleThemeChange} />
      </div>
      <div className="mb-4">
        <div className="subheader-holder mb-4">
          <h4>Customizations</h4>
          <p>These customizations will appear on your selected theme</p>
        </div>
        <div className="customizations-holder mb-4">
          <h5> Upload Logo or Favicon</h5>
          <p>Your changes will appear on LMS, Studio and Discovery site.</p>
        </div>

        <Row>
          <Col xs="12" lg="6" className="upload-logo">
            <FileUploader
              uploadFilename="Logo"
              helpText="For best results: upload a PNG file under 250Kb with dimension 240 x 48px (5:1 ratio)"
              onUpdate={handleLogoUpload}
            />
          </Col>
          <Col xs="12" lg="6">
            <FileUploader
              uploadFilename="Favicon"
              helpText="For best results: upload a PNG file under 250Kb with dimension 16 x 16px"
              onUpdate={handleFaviconUpload}
            />
          </Col>
        </Row>
      </div>

      <div className="mb-4">
        <div className="subheader-holder mb-4">
          <h5>Theme Colors </h5>
          <p>Your changes will appear on LMS, Studio and Discovery site.</p>
        </div>
        <Row>
          <Col xs="12" lg="6" className="mb-3 mb-lg-0">
            <ColorPicker color={primaryColor} text="Primary Color" onUpdate={handleSetPrimaryColor} />
          </Col>
          <Col xs="12" lg="6">
            <ColorPicker color={secondaryColor} text="Secondary Color" onUpdate={handleSetSecondaryColor} />
          </Col>
        </Row>
      </div>

      <Row className="mt-4">
        <Col sm="12" className="d-flex justify-content-end">
          <Button onClick={handleSaveButton}>Save</Button>
        </Col>
      </Row>
    </div>
  );
};

export default Appearance;
