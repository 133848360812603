import React from 'react';
import { Link } from 'react-router-dom';

import { getLearnerCompletedCourses } from 'helpers';

const moment = require('moment');

const LearnerListItem = (props) => {
  const { learner } = props;
  return (
    <tr key={learner.id}>
      <td data-title="Name">
        <Link
          to={{ pathname: `/panel/insights/learners/learner-details/${learner.username}` }}
          className="ps-0 pe-0 text-ellipsis d-inline-block link"
          key={learner.id}
        >
          {learner.name}
        </Link>
        <small className="d-block">{learner.email}</small>
      </td>
      <td data-title="Courses Enrolled" className="text-center">
        {learner.courses.length}
      </td>
      <td data-title="Courses Completed" className="text-center">
        {getLearnerCompletedCourses(learner.courses).length}
      </td>
      <td data-title="Account Created" className="text-center">
        {learner.date_joined ? moment(learner.date_joined).format('YYYY-MM-DD') : 'N/A'}
      </td>
      <td data-title="Last Login" className="text-center">
        {learner.last_login ? moment(learner.last_login).format('YYYY-MM-DD HH:mm:ss') : 'N/A'}
      </td>
    </tr>
  );
};

export default LearnerListItem;
