import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';

const moment = require('moment');

const NotificationsCenterListing = (props) => {
  const { current_page, notification_center, updateNotificationStatus } = props;

  const messages = notification_center.map((notification, index) => {
    return (
      <div className={notification.is_read === true ? 'notification read' : 'notification unread'} key={index}>
        <h4 className="title">{notification.message}</h4>
        <ul className="inline-list">
          <li>
            <strong>{moment(notification.created).format('MMM DD, YYYY')}</strong>
          </li>
          <li>
            <Button variant="link">
              <FontAwesomeIcon
                icon={notification.is_read === false ? Icons.faEnvelope : Icons.faEnvelopeOpen}
                onClick={() => updateNotificationStatus(notification.id, notification.is_read !== true, current_page)}
                title={notification.is_read === false ? 'Read Notification' : 'Unread Notification'}
              />
            </Button>
          </li>
        </ul>
      </div>
    );
  });

  return <div className={`notification-list ${messages.length > 0 ? '' : 'd-none'}`}>{messages}</div>;
};

export default NotificationsCenterListing;
