import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { IconBulb } from '@tabler/icons-react';

import DemoModel from 'components/demo';

const UserGuideButton = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleModelClose = () => {
    setIsOpen(false);
  };
  const handleModelOpen = () => {
    setIsOpen(true);
  };
  return (
    <>
      <span onClick={handleModelOpen} className="nav-link user-guide-btn btn-icon ms-2 ms-xl-3">
        <IconBulb stroke={1.5} />
      </span>
      <DemoModel isOpen={isOpen} handleGuideTracking={() => {}} handleTermsSubmit={handleModelClose} />
    </>
  );
};

export default UserGuideButton;
