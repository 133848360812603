import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Bar } from 'react-chartjs-2';
import { getPreparedGraphDataset } from 'helpers/dashboard';
import { Card, CardBody } from 'react-bootstrap';
import { getTheme } from 'selectors';

import 'react-datepicker/dist/react-datepicker.css';

const MAUsDashboard = (props) => {
  const { maus_data_labels, maus_data, theme } = props;
  const [legendDisplay, setlegendDisplay] = useState(true);

  useEffect(() => {
    setlegendDisplay(false);
    setTimeout(() => {
      setlegendDisplay(true);
    }, 0);
  }, [maus_data_labels, maus_data, theme]);

  return (
    <Card className="chart-frame mb-4 mb-lg-0">
      <CardBody>
        <Bar
          data={{
            labels: maus_data_labels,
            datasets: getPreparedGraphDataset(maus_data, theme)
          }}
          width={600}
          height={315}
          options={{
            maintainAspectRatio: false,
            legend: {
              display: legendDisplay,
              position: 'bottom',
              labels: {
                boxWidth: 15,
                padding: 10,
                fontColor: theme === 'light' ? '#252525' : '#fff'
              }
            },
            scales: {
              xAxes: [
                {
                  gridLines: {
                    display: false
                  },
                  ticks: {
                    beginAtZero: true,
                    fontFamily: '"Lato", sans-serif',
                    fontSize: 11,
                    fontStyle: 400,
                    fontColor: theme === 'light' ? '#252525' : '#fff'
                  }
                }
              ],
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    userCallback: function(label, index, labels) {
                      if (Math.floor(label) === label) {
                        return label;
                      }
                    },
                    fontFamily: '"Lato", sans-serif',
                    fontSize: 11,
                    fontStyle: 400,
                    fontColor: theme === 'light' ? '#252525' : '#fff'
                  }
                }
              ]
            }
          }}
        />
      </CardBody>
    </Card>
  );
};

function mapStateToProps(state) {
  const { theme } = getTheme(state);
  return {
    theme
  };
}

export default connect(mapStateToProps, null)(MAUsDashboard);
