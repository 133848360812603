import { useEffect, useState } from 'react';
import { Button, Modal, Table } from 'react-bootstrap';
import { IconInfoTriangle } from '@tabler/icons-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { siteDetailsActions } from '../actions';
import Cookies from 'js-cookie';
import { FIFTEEN_MINUTES_EXPIRATION } from '../constants';

const DnsValues = ({ hideDnsTable, dnsList, verified, dispatch }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [copiedValue, setCopiedValue] = useState('');
  const [disableSave, setDisableSave] = useState(true);

  useEffect(() => {
    if (verified) {
      const disableUpdateButton = Cookies.get('disable_dns_update_button');
      if (!disableUpdateButton) {
        setDisableSave(false);
      }
    }
  }, [verified]);

  const hideModal = () => {
    setIsOpen(false);
    hideDnsTable();
  };

  const unsecuredCopyToClipboard = (text) => {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand('copy');
    } catch (err) {}
    document.body.removeChild(textArea);
  };

  const updateSiteValues = () => {
    Cookies.set('disable_dns_update_button', true, { expires: FIFTEEN_MINUTES_EXPIRATION });
    dispatch(siteDetailsActions.saveClientSetup());
    setIsOpen(false);
    hideDnsTable();
  };

  const verifyEntries = () => {
    dispatch(siteDetailsActions.fetchVerifyDnsEntries());
  };
  const handleCopy = (value) => {
    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard.writeText(value);
    } else {
      unsecuredCopyToClipboard(value);
    }
    setCopiedValue(value);
  };

  return (
    <Modal className="dns-values-modal" size="lg" onHide={hideModal} show={isOpen} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>DNS Values</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p className="footer-note mb-4">
          <IconInfoTriangle stroke={1.5} />
          <span> Click the text to copy on clipboard</span>
        </p>
        <div className="table-holder">
          <Table size="sm" className="dns-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              {dnsList.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  <td data-title="Name" onClick={() => handleCopy(row[0])}>
                    {row[0]}
                  </td>
                  <td data-title="Type" onClick={() => handleCopy(row[1])}>
                    {row[1]}
                  </td>
                  <td data-title="Value" onClick={() => handleCopy(row[2])}>
                    {row[2]}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <div className="copy-text-info">{copiedValue && <small>Copied: {copiedValue}</small>}</div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-primary" onClick={verifyEntries}>
          Verify Domain
        </Button>
        <Button className="ms-2" onClick={updateSiteValues} disabled={disableSave}>
          Update Domain
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

function mapStateToProps(state) {
  const { verified } = state.SITE_DETAILS;
  return {
    verified
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(siteDetailsActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DnsValues);
