import { themes } from 'pages/dashboard/containers/themes/constants';

const themeinitialState = {
  theme: localStorage.getItem('theme') || 'light'
};

export const themeReducer = (state = themeinitialState, action) => {
  switch (action.type) {
    case themes.TOGGLE_THEME_MODE:
      return {
        ...state,
        theme: action.payload
      };
    default:
      return state;
  }
};
