import { EDX_API_ENDPOINTS } from 'config';
import { getPanelLmsBaseUrl } from 'helpers';

const axios = require('axios');

function fetchMAUS(params) {
  const url = `${getPanelLmsBaseUrl()}${EDX_API_ENDPOINTS.MAUS_API}`;
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
    params: params,
    url
  };
  return axios(requestOptions);
}

function requestMAUSReport(params) {
  const url = `${getPanelLmsBaseUrl()}${EDX_API_ENDPOINTS.MAUS_API}`;
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
    params: params,
    url
  };
  return axios(requestOptions);
}

function requestAllSiteMAUs(params) {
  const url = `${getPanelLmsBaseUrl()}${EDX_API_ENDPOINTS.ALL_SITE_MAUS}`;
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
    params: params,
    url
  };
  return axios(requestOptions);
}

export const mausService = {
  fetchMAUS,
  requestMAUSReport,
  requestAllSiteMAUs
};
