import { themeDispatches } from 'pages/dashboard/containers/themes/constants';

const toogleThemeMode = (payload) => {
  return async (dispatch) => {
    await dispatch(themeDispatches.toogleThemeMode(payload));
  };
};

export const themeActions = {
  toogleThemeMode
};
