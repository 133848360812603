import React from 'react';

import BenefitsSlideContent from './benefitsSlideContent';
import { paidBenefitList } from '../constants/benefitsContent';
import { Row } from 'react-bootstrap';

function PaidBenefits() {
  const benefits = paidBenefitList.map((item, index) => (
    <BenefitsSlideContent key={index} item={item} button={'Request Add On'} isPaid={true} />
  ));
  return (
    <>
      <h2 className="mb-4">Benefits & Add Ons</h2>
      <div className="addons-holder p-4">
        <h4>Add Ons</h4>
        <Row>{benefits}</Row>
      </div>
    </>
  );
}

export default PaidBenefits;
