import React from 'react';
import { Form } from 'react-bootstrap';

const RoleCheckBox = (props) => {
  const { id, label, isChecked, handleChange } = props;
  return (
    <li>
      <Form.Group className="mb-1">
        <Form.Check
          className="d-inline me-2"
          id={id}
          name={id}
          value={id}
          checked={isChecked === true}
          onChange={handleChange}
        />
        <Form.Label className="d-inline" htmlFor={id}>
          {label}
        </Form.Label>
      </Form.Group>
    </li>
  );
};

export default RoleCheckBox;
