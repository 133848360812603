import React, { useState } from 'react';
import { Button, Form, Row, Col, Alert, Tooltip, OverlayTrigger } from 'react-bootstrap';

import { SITE_SETUP_PID, EDLY_SITE_PRICE } from 'config';
import { messageNotification } from 'helpers';
import { isSubdomainValid } from 'helpers/dashboard';
import { pricingService } from 'services';

const CreateSite = (props) => {
  const { setCreatingSite } = props;
  const [subDomainError, setSubDomainError] = useState('');
  const [inputs, setInputs] = useState({
    site_name: '',
    sub_domain: '',
    tos: false,
    req_support: false
  });
  const [loading, setLoading] = useState(false);
  const buttonDisabled = !inputs.site_name || !inputs.sub_domain || loading;

  const handleInputChange = (event) => {
    event.persist();
    if (event.target.name === 'tos' || event.target.name === 'req_support') {
      setInputs((inputs) => ({ ...inputs, [event.target.name]: event.target.checked }));
    } else {
      setInputs((inputs) => ({ ...inputs, [event.target.name]: event.target.value }));
    }
  };

  const handleClose = () => {
    setCreatingSite(false);
    setSubDomainError('');
    setInputs({
      site_name: '',
      sub_domain: '',
      tos: false,
      req_support: false
    });
  };

  const checkSubDomainAvailability = async (data) => {
    let isAvailable = false;
    try {
      const apiResponse = await pricingService.checkSubDomainAvailability(data);
      isAvailable = apiResponse.data?.is_available || false;
    } catch (err) {
      return false;
    }
    return isAvailable;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const subDomain = isSubdomainValid(inputs.sub_domain);
    if (!subDomain) {
      setSubDomainError('Special characters are not allowed');
      return;
    }
    if (inputs.sub_domain.length > 8) {
      setSubDomainError('site domain should be maximun of 8 characters');
      return;
    }

    const isAvailable = await checkSubDomainAvailability(inputs);
    if (!isAvailable) {
      setSubDomainError('This site name is already taken');
      return;
    }

    setLoading(true);
    setSubDomainError('');
    let payload = {
      ...inputs,
      payment_id: SITE_SETUP_PID
    };

    pricingService
      .createOneTimePaymentCheckoutSession(payload)
      .then((response) => {
        setLoading(false);
        const checkout_url = response.data;
        window.location.href = checkout_url;
      })
      .catch((error) => {
        messageNotification(
          'danger',
          'Unable to checkout',
          error?.response ? error.response?.data : 'There was an error encountered while checking out with stripe'
        );
        setLoading(false);
      });
  };

  return (
    <div className="profile-form">
      <h2>Site Details</h2>
      <Row className="mb-4">
        <Col xs="12" lg="8">
          <p>
            You are using Edly subdomains previously, If you want to use custom domains for your sites please write to
            us.
          </p>
        </Col>
        <Col xs="12" lg="4" className="d-flex justify-content-end align-items-center">
          <Button variant="outline-primary" onClick={() => window.HubSpotConversations.widget.open()}>
            {' '}
            Contact Us{' '}
          </Button>
        </Col>
      </Row>
      <h4 className="pt-4 mb-4">OR Continue by adding Edly subdomain</h4>
      <Form className="subdomain-info">
        <Form.Group className="mb-3" controlId="site_name">
          <Form.Label>Site name*</Form.Label>
          <Form.Control
            type="text"
            value={inputs.site_name}
            onChange={handleInputChange}
            name="site_name"
            placeholder="skillup (example)"
            required
          />
          <span className="form-text">
            Your organisation that will be displayed on your homepage, LMS, and Wordpress site (up to 50 characters)
          </span>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="sub_domain">
          <Form.Label>URL for Edly site*</Form.Label>
          <Col sm="6">
            <Form.Control
              type="text"
              value={inputs.sub_domain}
              onChange={handleInputChange}
              name="sub_domain"
              placeholder="skillup"
              required
              style={{
                minWidth: '230px',
                marginRight: '20px'
              }}
            />
            {subDomainError && <Form.Text className="text-error">{subDomainError}</Form.Text>}
          </Col>
          <Col sm="6">
            <p className="d-inline-block">.edly.io</p>
          </Col>
          <Col sm="12">
            <span className="form-text">
              An 8 character or less unique identifier for your platform that will appear in the URL. Note: You will not
              be able to update this later in the Edly Panel
            </span>
          </Col>
        </Form.Group>
      </Form>
      <div>
        <Alert variant="secondary">
          Unlock growth with a new site for just ${EDLY_SITE_PRICE}! Each new site is purchased separately and charged
          as a one-time fee. Monthly active learners will be billed monthly as a summation of all the sites to make it
          easy for you.
        </Alert>
      </div>
      <Row className="pt-4">
        <Col xs="12" className="d-flex justify-content-end">
          <Button variant="outline-primary" onClick={handleClose}>
            Cancel
          </Button>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="button-tooltip">Each new site is charged separately as a one-time setup fee</Tooltip>}
          >
            <Button
              onClick={handleSubmit}
              className="ms-2"
              disabled={buttonDisabled}
              style={{
                position: 'relative'
              }}
            >
              {loading && <div className="loader" />}
              <span className={loading ? 'ps-4' : ''}>Continue to Payment</span>
            </Button>
          </OverlayTrigger>
        </Col>
      </Row>
    </div>
  );
};

export default CreateSite;
