import React from 'react';
import { learnerDetailsActions } from 'pages/insights/containers/learnerDetailContainer/actions';
import { Button } from 'react-bootstrap';
import { IconFileExport } from '@tabler/icons-react';

const DownloadLearnerDetails = (props) => {
  const { learner_info, dispatch } = props;

  const handleFilterChange = (value) => {
    dispatch(learnerDetailsActions.downloadLearnerDetails({ username: learner_info.username }));
  };

  return (
    <div id="export_button">
      <Button variant="outline-primary" onClick={handleFilterChange}>
        Export <span className="d-none-md">CSV</span>
        <IconFileExport className="ms-2" stroke={1.5} />
      </Button>
    </div>
  );
};

export default DownloadLearnerDetails;
