import React from 'react';

import { Form } from 'react-bootstrap';
import { IconSearch } from '@tabler/icons-react';

const SearchInput = (props) => {
  const { label, searchText, handleInputChange } = props;
  return (
    <div className="sites-search-holder">
      <IconSearch id="button-search-learner" stroke={1.5} className="ico-search" />
      <Form.Group>
        <Form.Control
          type="text"
          placeholder={label}
          id="search"
          name="search"
          value={searchText}
          onChange={handleInputChange}
        />
      </Form.Group>
    </div>
  );
};

export default SearchInput;
