import { Line } from 'react-chartjs-2';
import { connect } from 'react-redux';

import { graphProperties } from 'helpers/constants';
import { getTheme } from 'selectors';

const LineChartTrend = (props) => {
  const { title, labels, values, theme } = props;

  const data = {
    labels: labels,
    datasets: [
      {
        ...graphProperties[theme],
        label: title,
        data: values
      }
    ]
  };

  const options = {
    layout: {
      padding: {
        top: 5,
        right: 5
      }
    },
    legend: {
      display: false
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            drawBorder: false,
            display: false
          },
          ticks: {
            display: false,
            min: 0,
            beginAtZero: true,
            callback: function(value, index, values) {
              if (Math.floor(value) === value) {
                return value;
              }
            }
          }
        }
      ],
      xAxes: [
        {
          gridLines: {
            drawBorder: false,
            display: false
          },
          ticks: {
            display: false
          }
        }
      ]
    }
  };

  return <Line data={data} options={options} />;
};

function mapStateToProps(state) {
  const { theme } = getTheme(state);
  return {
    theme
  };
}

export default connect(mapStateToProps, null)(LineChartTrend);
