import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col, Form, Button } from 'react-bootstrap';

import { getEdlySiteConfig, updateEdlySiteConfig } from 'services/siteConfiguration';
import { messageNotification } from 'helpers';
import BadgrContainer from 'pages/integrations/containers/BadgrContainer/components/badgrContainer';
import { badgrActions } from 'pages/integrations/containers/BadgrContainer/actions';
import { IconCheck } from '@tabler/icons-react';

const BadgrIntegration = ({ badges, dispatch }) => {
  const [issuer, setIssuer] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [badgr, setBadgr] = useState(false);
  const [disabled, setDisabled] = useState();

  useEffect(() => {
    getEdlySiteConfig().then((res) => {
      const settings = res.data.lms.DJANGO_SETTINGS_OVERRIDE;
      setIssuer(settings.BADGR_ISSUER_SLUG ? settings.BADGR_ISSUER_SLUG : null);
      setUsername(settings.BADGR_USERNAME ? settings.BADGR_USERNAME : null);
      setPassword(settings.BADGR_PASSWORD ? settings.BADGR_PASSWORD : null);
      if (settings.BADGR_ISSUER_SLUG && settings.BADGR_USERNAME && settings.BADGR_PASSWORD) {
        setBadgr(true);
        setDisabled(true);
      }
    });
  }, []);

  const saveBadgrConfig = () => {
    const json_data = {
      lms: {
        DJANGO_SETTINGS_OVERRIDE: {
          BADGR_ISSUER_SLUG: issuer,
          BADGR_USERNAME: username,
          BADGR_PASSWORD: password
        }
      }
    };

    updateEdlySiteConfig(json_data)
      .then(() => messageNotification('success', 'Badgr Configuration', 'Badgr Configuration Updated.'))
      .catch(() => messageNotification('error', 'Badgr Configuration', 'Badgr Configuration Not Updated.'));
  };

  return (
    <div className="integration-holder">
      <h4 className="mb-4">Badgr Integrations </h4>
      <div className="subheader-holder">
        <h5>
          2. Badges Details
          {badgr ? <IconCheck color="#62d586" className="ms-2" stroke={1.5} /> : null}
        </h5>
        <p>
          Please add the badgr account credientials.
          <a className="ms-2" href="https://badgr.com/">
            Learn more
          </a>
        </p>
      </div>
      <Row>
        <Col xs="12" lg="6">
          <Form.Group className="mb-3">
            <Form.Label>Badgr Issuer Slug</Form.Label>
            <Form.Control
              value={issuer}
              placeholder={'xxxxxx'}
              onChange={(event) => setIssuer(event.target.value)}
              disabled={disabled}
            />
          </Form.Group>
        </Col>
        <Col xs="12" lg="6">
          <Form.Group className="mb-3">
            <Form.Label>Badgr Username</Form.Label>
            <Form.Control
              value={username}
              placeholder={'xxxxxx'}
              onChange={(event) => setUsername(event.target.value)}
              disabled={disabled}
            />
          </Form.Group>
        </Col>
        <Col xs="12">
          <Form.Group className="mb-3">
            <Form.Label>Badgr Password</Form.Label>
            <Form.Control
              value={password}
              placeholder={'xxxxxx'}
              type="password"
              disabled={disabled}
              onChange={(event) => setPassword(event.target.value)}
            />
          </Form.Group>
        </Col>
        <Col className="justify-content-end d-flex">
          {badgr && (
            <Button variant="outline-primary" onClick={() => setDisabled(false)}>
              Edit
            </Button>
          )}
          <Button className="ms-2" onClick={saveBadgrConfig}>
            Save
          </Button>
        </Col>
      </Row>
      <BadgrContainer badges={badges} dispatch={dispatch} enableBadges={badgr} />
    </div>
  );
};

function mapStateToProps(state) {
  const badges = state.SITE_BADGES.badges;
  return {
    badges: badges
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(badgrActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BadgrIntegration);
