import React from 'react';
import { Link } from 'react-router-dom';

const moment = require('moment');

const CourseLearnerListItem = (props) => {
  const { enrollment } = props;
  return (
    <tr key={enrollment.id} className={enrollment.is_active ? '' : 'disabled'}>
      <td data-title="Name">
        <Link
          to={{ pathname: `/panel/insights/learners/learner-details/${enrollment.user.username}` }}
          className="mb-1 d-inline-block link"
          key={enrollment.user.id}
        >
          {enrollment.user.fullname}
        </Link>
        <small className="d-block" data-title="Email">
          {enrollment.user.email}
        </small>
      </td>
      <td className="text-center" data-title="Graded Course Progress">
        {enrollment.courses.length > 0 ? `${enrollment.courses[0].progress_data.course_progress}%` : 'N/A'}
      </td>
      <td className="text-center" data-title="Total Course Progress">
        {enrollment.courses.length > 0 ? `${enrollment.courses[0].progress_data.total_progress_percent}%` : 'N/A'}
      </td>
      <td className="text-center" data-title="Total Grade">
        {enrollment.courses.length > 0 ? `${enrollment.courses[0].progress_data.percent_grade}%` : 'N/A'}
      </td>
      <td className="text-center" data-title="Course Completion Date">
        {enrollment.courses.length > 0 && enrollment.courses[0].progress_data.passed_timestamp
          ? moment(enrollment.courses[0].progress_data.passed_timestamp).format('YYYY-M-DD')
          : 'N/A'}
      </td>
      <td className="text-center" data-title="Account Created">
        {enrollment.user.date_joined ? moment(enrollment.user.date_joined).format('YYYY-MM-DD') : 'N/A'}
      </td>
      <td className="text-center" data-title="Last Login">
        {enrollment.user.last_login ? moment(enrollment.user.last_login).format('YYYY-MM-DD HH:mm:ss') : 'N/A'}
      </td>
    </tr>
  );
};

export default CourseLearnerListItem;
