import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import Form from 'react-bootstrap/Form';
import { IconDotsVertical } from '@tabler/icons-react';

import { getWordpressBaseUrl } from 'helpers';
import { getSiteUsers } from 'selectors';
import { usersActions } from 'pages/users/containers/usersContainer/actions';
import { Dropdown } from 'react-bootstrap';

const UserListItem = (props) => {
  const {
    username,
    user,
    selectedUsers,
    handleSelectedUsers,
    setUser,
    setEditModalShow,
    handlePasswordReset,
    passwordReset,
    dispatch,
    current_page,
    roles,
    userFilters,
    search,
    pageSize
  } = props;
  const is_editable = !(user.is_superuser || user.username === username || user.is_retired);
  const [isStatusRequestInProgress, setIsStatusRequestInProgress] = useState(false);
  const [isBlockRequestInProgress, setIsBlockRequestInProgress] = useState(false);

  const handleUserStatusUpdate = (username, status, wordpress_role) => {
    setIsStatusRequestInProgress(true);
    dispatch(
      usersActions.updateUserStatus(
        username,
        status,
        search,
        wordpress_role,
        current_page,
        roles,
        setIsStatusRequestInProgress,
        pageSize
      )
    );
  };

  const handleUserBlockStatusUpdate = (username, block_status) => {
    let orderBy = `${userFilters.column},${userFilters.direction}`;
    setIsBlockRequestInProgress(true);
    dispatch(
      usersActions.updateUserBlockStatus(
        username,
        block_status,
        search,
        current_page,
        roles,
        setIsBlockRequestInProgress,
        orderBy,
        pageSize
      )
    );
  };

  // eslint-disable-next-line no-console
  console.log(user.is_retired);
  return (
    <tr className={is_editable ? '' : 'disabled'} title={user.is_retired ? 'Retired User' : ''}>
      <td className="col-check align-middle">
        <div className={`custom-control custom-checkbox ${user.is_superuser || user.is_retired ? 'd-none' : ''}`}>
          <Form.Check className="mb-0">
            <Form.Check.Input
              type="checkbox"
              id={user.id}
              name={user.username}
              value={user.username}
              checked={selectedUsers[user.username] === true}
              onChange={handleSelectedUsers}
            />
          </Form.Check>
        </div>
      </td>
      <td className="align-middle" data-title="Username" title={user.is_retired ? 'Retired User' : user.username}>
        <span className="text-ellipsis username d-block">{user.username}</span>
      </td>
      <td className="align-middle" data-title="Name" title={user.is_retired ? 'Retired User' : user.name}>
        <span className="name text-ellipsis d-block">{user.name}&nbsp;</span>
        <small data-title="Email" className="email d-block">
          {user.email}&nbsp;
        </small>
      </td>
      <td className="roles align-middle text-center" data-title="LMS Role ">
        {user.lms_role === 'super_admin' ? 'Site Admin' : user.lms_role.replace('_', ' ')}
      </td>
      {getWordpressBaseUrl() && (
        <td className="roles align-middle text-center" data-title="Discovery Role">
          {user.wordpress_role.replace('_', ' ')}
        </td>
      )}
      <td className="roles align-middle text-center" data-title="Panel Role ">
        {user.panel_role === 'insights_admin'
          ? 'Insights Viewer'
          : user.panel_role.replace('panel_', '').replace('_', ' ')}
      </td>
      <td data-title="Status " className="status align-middle text-center">
        {is_editable ? (
          <Fragment>
            <div className="d-flex justify-content-center">
              <span
                title={user.is_active ? 'Deactivate User' : 'Activate User'}
                style={{ width: '35px', height: '35px' }}
                className={
                  user.is_superuser || user.username === username
                    ? 'd-none'
                    : 'user-status btn btn-sm btn-outline-primary d-inline-flex align-items-center justify-content-center'
                }
                onClick={() =>
                  !isStatusRequestInProgress &&
                  handleUserStatusUpdate(user.username, user.is_active !== true, user.wordpress_role)
                }
              >
                <FontAwesomeIcon icon={user.is_active ? Icons.faCheckCircle : Icons.faBan} />
              </span>
              <span
                title={user.is_blocked ? 'Unblock User' : 'Block User'}
                style={{ width: '35px', height: '35px' }}
                className={
                  user.is_superuser || user.username === username
                    ? 'd-none'
                    : 'user-status btn btn-sm ms-2 btn-outline-primary d-inline-flex align-items-center justify-content-center'
                }
                onClick={() =>
                  !isBlockRequestInProgress && handleUserBlockStatusUpdate(user.username, user.is_blocked !== true)
                }
              >
                <FontAwesomeIcon icon={user.is_blocked ? Icons.faUserSlash : Icons.faUser} />
              </span>
            </div>
          </Fragment>
        ) : (
          '--'
        )}
      </td>
      <td data-title="Action" className="action align-middle text-center">
        {is_editable && !user.is_superuser && (
          <Dropdown className="user-actions">
            <Dropdown.Toggle variant="default" size="sm">
              <IconDotsVertical stroke={1.5} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  setUser(user);
                  setEditModalShow(true);
                }}
                className={user.is_superuser ? 'invisible' : ''}
              >
                Edit User
              </Dropdown.Item>
              {!user.is_social_user && (
                <Dropdown.Item
                  onClick={() => handlePasswordReset(user.email)}
                  className={passwordReset ? '' : 'password-reset-disabled'}
                >
                  Reset Password
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        )}
      </td>
    </tr>
  );
};

function mapStateToProps(state) {
  const { current_page, roles, search } = getSiteUsers(state);
  return {
    current_page,
    roles,
    search
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(usersActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserListItem);
